@mixin text_styles($color, $weight) {
  font-family: var(--font_family_Medium);
  font-size: 14px;
  font-weight: $weight;
  color: $color;
}
.auto_pay_card {
  padding: 20px;
  border-radius: 5px;
  
  .auto_pay_container {
    margin-left: 2px;
    .heading {
      @include text_styles(var(--text_color), var(--font_weight_2));
      font-size: 15px;
      margin-bottom: 10px;
    }
    .label {
      @include text_styles(var(--text_color), var(--font_weight_1));
    }
    .link_text {
      @include text_styles(var(--primary_brand_color), var(--font_weight_1));
      cursor: pointer;
      width: fit-content;
    }
  }
}
.note_container {
  margin-top: 20px;
  .heading {
    @include text_styles(var(--primary_brand_color), var(--font_weight_2));
    font-size: 13px;
    margin-bottom: 5px;
  }
  .note_err_desc {
    @include text_styles(var(--danger), var(--font_weight_1));
    font-size: 13px;
    line-height: 1.85;
  }
}
