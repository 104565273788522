.header_modal {
  z-index: 1111 !important;
  .header_container {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
  }

  :global(.MuiBackdrop-root) {
    background-color: var(--white) !important;
  }

  .dialog_container {
    padding-bottom: 20px;
    .active_link_item {
      color: var(--primary_brand_color) !important;
      font-family: var(--font_family) !important;
      cursor: pointer;
    }
    .link_item {
      font-size: 14px !important;
      font-family: var(--font_family_Semibold);
      color: var(--dark-charcoal-fade);
      width: 100%;
      cursor: pointer;
      @media (max-width: 900px) {
        display: flex;
        align-items: center;
        font-size: 14px;
        justify-content: space-between;
      }
    }
    // .primary_link_item{
    //   border-bottom: 2px solid var(--navy);
    // }
    .secondary_header {
      color: var(--primary_brand_color);
      .label {
        font-family: var(--font_family_Semibold);
        font-size: 14px;
        font-weight: var(--font_weight_2);
        color: var(--text_color) !important;
      }
    }
    .secondary_header_text_style {
      color: var(--secondary_header_link_txt_color);
    }
    .primary_header_text_style {
      font-family: var(--font_family_Medium);
      font-size: 14px;
      line-height: 28px;
      font-weight: var(--font_weight_1);
      color: var(--dark-charcoal-fade);
    }
  }
}

