.discover_card {
    width: auto;
    border: 1px solid var(--lightgrey_4);
    border-radius: 5px;
    padding: 20px 10px;
    margin: 10px;
    height: 281px;
    background-color: var(--white);
    @media (max-width: 600px) {
        height: 260px;
    }
}
.button_text {
    font-family: var(--font_family);
    font-size: 16px !important;
    border-radius: 20px;
    padding: 13px 23px !important;
    height: 45px !important;
    min-width: 100px !important;
    @media (max-width: 600px) {
        padding: 14px !important;
        height: 40px !important;
    }
}
.DiscoverWrap {
    margin-top: 10px;

    @media (max-width: 600px) {
        margin-top: 0px;
    }
}
.dicoverCardContentWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    .DiscoverCardText {
        font-size: 16px;
        font-family: var(--font_family_Semibold);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        padding: 0px 4px;
    }
}
.discoverCardImg {
    height: 50px;
}
.discoverCardImgSubs {
    height: 45px;
    @media (max-width: 600px) {
        height: 40px;
    }
}
.ShareStyle {
    font-size: 15px;
    font-family: var(--font_family_Semibold);
    padding: 5px 0px 0px 0px;
    color: var(--primary_color);
    cursor: pointer;
    .discoverShareIcon {
        margin-left: 10px;
        width: 20px;
        height: 18px;
    }
}
.noText {
    margin-top: 45px;
}
.discover_popper{
    :global(.MuiPopper-arrow::before) {
        filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 3.08)) !important;
    }
}
.discoverShareContainer {
    margin: 16px;
    min-width: 230px;
    .discoverCloseIcon {
        height: 15px;
        width: 15px;
        cursor: pointer;
        color: var(--black);
        opacity: 0.5;
    }
    .discoverShareHeaderText {
        font-family: var(--font_family);
        font-weight: var(--font_weight_4);
        font-size: 16px;
        text-align: center;
        margin: 0 auto;
        color: var(--text_color_3)
    }
    .socialIcons {
        display: flex;
        justify-content: space-evenly;
        .individualSocialIcon {
            margin-top: 10px;
            text-align: center;
            .mediaIcon {
                width: 50px;
            }
            .iconText {
                margin-top: 10px;
                font-size: 14px;
                font-family: var(--font_family_Medium);
                color: var(--text_color_4);
            }
        }
    }
    @media (max-width: 600px) {
        min-width: 270px;
        width: 100%;
    }
}