.coverage_modal {
  background: var(--white);
  .coverage_error {
    text-align: center;
    color: var(--danger);
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    margin-bottom: 10px;
  }
  .bummer_text {
    font-family: var(--font_family_Semibold);
    font-size: 18px;
    color: var(--success);
    padding: 20px 0 15px 0;
    font-weight: var(--font_weight_4);
  }
  .bummer_content {
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    line-height: 1.6;
    color: var(--text_color);
    text-align: left; 
  }
  .bummer_fail_text{
    font-family: var(--font_family_Semibold);
    font-size: 18px;
    color: var(--danger);
    padding: 20px 0 15px 0;
    font-weight: var(--font_weight_4);
  }
  .imei_fail_text {
    div {
      font-family: var(--font_family_Semibold);
      font-size: 18px;
      color: var(--text_color);
    }
  }
  .try_again {
    font-size: 16px;
    margin-left: 10px;
    font-family: var(--font_family_Semibold);
    color: var(--primary_color);
    cursor: pointer;
    border-bottom: 1px solid var(--primary_color);
  }
  .compatibility_header {
    font-family: var(--font_family_Bold);
    font-size: 22px;
    text-align: center;
    color: var(--text_color_1);
    margin-bottom: 20px;
  }
  .compatibility_plan_input {
    margin: 20px auto !important;
    width: 100%;
  }
  .limit_div {
    font-family: var(--font_family_Medium);
    font-weight: var(--font_weight_0);
    display: flex;
    font-size: 12px;
    justify-content: flex-end;
    margin: 10px 0;
  }
  .phone_trouble_div {
    display: flex;
    justify-content: space-between;
  }
  .above_email_text {
    font-size: 14px;
    font-family: var(--font_family_Medium);
    font-weight: var(--font_weight_0);
  }
  .input_div {
    margin: 20px 0;
  }
}
.having_trouble_div {
  color: var(--primary_color);
  font-family: var(--font_family_Bold);
  font-size: 14px;
  cursor: pointer;
}
.have_trouble_tool_tip {
  padding: 20px;
  .have_trouble_header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .close_icon {
      height: 15px;
      width: 15px;
      cursor: pointer;
      color: var(--black);
      opacity: 0.5;
    }
    .have_trouble_header_text {
      font-family: var(--font_family_Bold);
      font-weight: var(--font_weight_4);
      font-size: 16px;
      margin: 0 auto;
    }
  }
  .have_trouble_detail_div {
    display: flex;
    .detail_img_div {
      margin-right: 15px;
      .detail_img {
        width: 23px;
        height: 28px;
      }
    }
    .detail_content {
      .detail_head {
        color: var( --text_color_5);
        font-family: var(--font_family_Semibold);
        font-size: 14px;
      }
      .detail_subtext {
        font-family: var(--font_family_Medium);
        font-size: 14px;
        color: var(--text_color);
      }
      .add_margin_bottom {
        margin-bottom: 15px;
      }
    }
  }
}
.tick_icon_point{
  height: 9px;
  width: 12px;
  margin-right: 5px;
  margin-top: 15px;
  text-align: center;
  object-fit: contain;
  cursor: auto;
  @media (max-width: 600px) {
    top: 200px;
    right: 13px;
  }
}
.ns_link {
  color:  var(--primary_color);
  font-family: var(--font_family) !important;
  cursor: pointer;
  text-decoration: none;
}