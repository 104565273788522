.share_style {
  font-family: var(--font_family_Semibold);
  padding: 5px 0px 0px 0px;
  color: var(--primary_color);
  cursor: pointer;
  .share_icon {
    margin-left: 10px;
  }
}
.share_popper {
  min-width: 230px;
  .close_icon {
    height: 15px;
    width: 15px;
    cursor: pointer;
    color: var(--black);
    opacity: 0.5;
  }
  .invite_friends_text {
    font-family: var(--font_family);
    font-weight: var(--font_weight_4);
    font-size: 16px;
    text-align: center;
    margin: 0 auto;
    color: var(--text_color_3);
  }
  .media_icons {
    display: flex;
    justify-content: space-evenly;
    .individual_media_icon {
      margin-top: 10px;
      text-align: center;
      .icon_text {
        margin-top: 10px;
        font-size: 14px;
        font-family: var(--font_family_Medium);
        color: var(--text_color_4);
      }
    }
  }
  @media (max-width: 600px) {
    min-width: 270px;
    width: 100%;
  }
}
