.coverage_modal {
    background: var(--white);
  }
  .greenline {
    height: 5px;
    border-radius: 2.5px;
    background-color: var(--primary_brand_color);
  }
  
  .coverage_error {
    text-align: center;
    color: var(--danger);
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    margin-bottom: 10px;
  }
  .coverage_check_heading {
    color: var(--text_color_1);
    font-family: var( --font_family_Bold) !important;
  }
  .coverage_check_subheading {
    color: var(--subtext-color);
    font-family: var(--font_family_Medium);
  }

  .line_status {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    @media (max-width: 600px) {
      width: 100%;
  }
    .fourG_line {
        height: 4px;
        width: 35%;
        border-radius: 2px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
        background-color: var(--white);
    }
    .fiveG_line {
        height: 4px;
        width:  35%;
        border-radius: 2px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
        background-color: var(--white);
    }
}
.coverage_msg {
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-around;
  @media (max-width: 600px) {
      margin-top: 7px;
      width: 100%;
      font-size: 12px;
      font-weight: var(--font_weight_2);
      text-align: center;
  }
  .fourG_msg{
      margin-top: 10px;
      font-size: 14px;
      font-weight: var(--font_weight_2);
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: var(--primary_brand_color);
      @media (max-width: 600px) {
        font-size: 13px;
    }
  }
}