$brand_feature_bg_img: linear-gradient(
  180deg,
  var(--white) 20%,
  var(--brand_feature_background) 0%
);

.brand_features {
  @media (min-width: 900px) {
    background-image: $brand_feature_bg_img;
  }
  .feature_card {
    border: solid 0.3px var(--grey_color_1) !important;
    background-color: var(--white) !important;
  }
  .line {
    border-radius: 2.5px;
    background-color: var(--primary_brand_color) !important;
  }
  .high_light_cards {
    border: solid 0.3px var(--primary_brand_color) !important;
    background-color: var(--primary_brand_color) !important;
    color: var(--white);
  }
  .tab_mobile_icons {
    width: 52px;
    height: 53px;
  }
  .web_icons {
    width: 69px;
    height: 71px;
  }
  .img_name {
    font-family: var(--font_family_Semibold);
  }
}
