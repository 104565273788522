.home_coverage_block {
  background: var(--white_1);
  .home_coverage_head {
    font-weight: var(--font_weight_1);
    font-family: var(--font_family_Semibold);
    color: var(--text_color);
    @media (max-width: 600px) {
      font-size: 22px;
    }
  }
  .home_coverage_text {
    font-family: var(--font_family_Medium);
    color: var(--text_color);

    @media (max-width: 600px) {
      font-size: 18px;
    }
  }
}
.coverage_modal {
  background: var(--white);
}
.greenline {
  height: 5px;
  border-radius: 2.5px;
  background-color: var(--primary_brand_color);
}

.coverage_error {
  text-align: center;
  color: var(--danger);
  font-family: var(--font_family_Semibold);
}
.coverage_check_heading {
  color: var(--text_color_1);
  font-family: var( --font_family_Bold) !important;
}
.coverage_check_subheading {
  color: var(--subtext-color);
  font-family: var(--font_family_Medium);
}
.coverage_text1{
  font-family: var(--font_family_Semibold) !important;
}
.coverage_final_text {
  color: var(--text_color);
  font-family: var( --font_family_Bold);
}
.coverage_final_text p {
  color: var(--text_color);
  font-family: var( --font_family_Bold);
}
.detailed_coverage {
  cursor: pointer;
  color: var(--primary_color);
  font-family: var( --font_family_Bold) !important;
}
