.step2_main {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .heading_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0px 15px 0px;

    .arrow_icon {
      cursor: pointer;
      width: 24px;
      height: 24px;
      z-index: 100;
      left: 0;
      position: absolute;
    }

    .title_main {
      font-family: var(--font_family_Bold);
      font-size: 22px;
      font-weight: var(--font_weight_4);
      line-height: 1;
      text-align: center;
      color: var(--text_color);
    }
  }

  .stepper_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .stepper_txt {
      font-family: var(--font_family_Medium);
      color: var(--text_color);
      font-size: 16px;
      padding-bottom: 20px;
    }
  }

  .inner_container {
    border-radius: 6px;
    box-shadow: 0 0 1px 1px rgb(60 64 67 / 30%);
    border: 1px solid var(--chinese-silver);
    background-color: var(--lightgrey_6);

    .card_title {
      text-align: center;
      font-family: var(--font_family_Semibold);
      font-size: 14px;
      color: var(--text_color_2);
      margin-bottom: 30px;
    }

    .card_description {
      font-family: var(--font_family_Medium);
      font-size: 14px;
      color: var(--text_color);
      text-align: center;
    }

    .form_container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .form_title {
        font-family: var(--font_family_Semibold);
        font-size: 14px;
        color: var(--text_color_2);
        margin-bottom: 10px;
      }

      .form_description {
        font-family: var(--font_family_Medium);
        font-size: 14px;
        color: var(--text_color);
        padding-bottom: 10px !important;
        margin: 0 auto;
        text-align: center;
      }

      .limit_text {
        font-family: var(--font_family_Medium);
        font-weight: var(--font_weight_0);
        text-align: right;
        font-size: 12px;
        color: var(--text_color_3);
      }
    }
  }

  .btm_title {
    font-family: var(--font_family_Semibold);
    // font-weight: var(--font_weight_1);
    // font-size: 18px;
    // color: var(--text_color_2);
  }

  .btm_description {
    font-family: var(--font_family_Regular);
    font-size: 14px;
    color: var(--text_color);
    font-weight: var(--font_weight_0);
    letter-spacing: normal;

    a {
      text-decoration: none;
      color: var(--primary_color) !important;
      font-family: var(--font_family_Semibold);
    }
  }

  .chat_box_img {
    width: 24px;
    height: 25px;
  }

  .acdn_title {
    font-family: var(--font_family);
    font-weight: var(--font_weight_2);
    font-size: 14px;
    color: var(--primary_color);
  }

  .accordian_main {
    margin-bottom: 10px;
    :global(.MuiAccordionSummary-root) {
      min-height: fit-content;
      padding-top: 10px;

      :global(.MuiAccordionSummary-content) {
        margin: 0;
      }

      :global(.MuiAccordionSummary-expandIconWrapper) {
        color: var(--black);
      }
    }

    &::before {
      background-color: transparent;
    }
  }

  .pin_label {
    font-size: 12px;
    font-family: var(--font_family_Semibold);
    width: 100%;
    text-align: left;
  }
}

.form_container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .form_title {
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    color: var(--text_color_2);
    margin-bottom: 10px;
  }

  .form_description {
    font-family: var(--font_family_Medium);
    font-size: 14px;
    color: var(--text_color);
    padding-bottom: 10px !important;
    margin: 0 auto;
    text-align: center;
  }

  .limit_text {
    font-family: var(--font_family_Medium);
    font-weight: var(--font_weight_0);
    text-align: right;
    font-size: 12px;
    color: var(--text_color_3);
  }
}

.btm_title {
  font-family: var(--font_family_Semibold) !important;
  // font-weight: var(--font_weight_1);
  font-size: 18px !important;
  // color: var(--text_color_2);
}
.acdn_title {
  font-family: var(--font_family);
  font-weight: var(--font_weight_2);
  font-size: 14px;
  color: var(--primary_color);
}
