.lead_generation {
  background: var(--lead_generation_bg_color);
  .lead_generation_title {
    font-family: var(--font_family_Bold);
    color: var(--text_color);
  }
  .lead_generation_subtitle {
    font-family: var(--font_family_Semibold);
    color: var(--text_color);
  }
  .lead_generation_line {
    width: 80px;
    height: 5px;
    border-radius: 2.5px;
    background-color: var(--lead_generation_dividor);
  }
  .email_success {
    .email_success_text {
      font-family: var(--font_family_Semibold);
      font-weight: var(--font_weight_2);
    }
  }
  .keep_in_touch_text {
    font-weight: var(--font_weight_2);
    font-family: var(--font_family_Semibold);
  }
}
