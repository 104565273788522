.heading {
    font-size: 30px !important;
    font-weight: 300 !important;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: -0.67px;
    color: #121629;
    font-family:var(--font_family_Medium) !important;
}

.text {
    width: 766px;
    margin: 19px 0 20px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 1.67;
    color: var(--subheader-color_1);
    text-align: center;
}

.btn {
    width: 250px;
    border-radius: 6px;
    background-color: var(--primary_color) !important;
    color: aliceblue !important;
    font-weight: bold;
    font-family: var(--font_family_Semibold) !important;
}