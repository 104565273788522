.infocard_detail_title {
  font-family: var(--font_family_Bold);
  font-size: 24px;
  line-height: 32px;
  color: var(--dark-charcoal-fade);
  @media (max-width: 600px) {
    font-size: 20px;
    line-height: 30px;
  }
}
.infocard_border_bottom {
  width: 75px;
  height: 5px;
  background-color: var(--divider_color);
  margin-top: 20px !important;
  border-radius: 25px;
}
.infocard_detail_data {
  margin: 18px 0 18px;
  font-family: var(--font_family_Regular);
  font-size: 16px;
  font-weight: var(--font_weight_1);
  line-height: 28px;
  color: var(--dark-charcoal-fade);
  @media (max-width: 600px) {
    font-size: 16px;
  }
}
.infocard_detail_subdata {
  // margin: -10px 0 25px;
  font-family: var(--font_family_Medium);
  font-size: 16px;
  font-weight: var(--font_weight_1);
  line-height: 1.88;
  color: var(--dark-charcoal-fade);
  @media (max-width: 600px) {
    font-size: 16px;
  }
}

.img_container {
  // @media (max-width: 899px) {
  //   width: 530px;
  //   padding-top: 10px;
  // }
  // @media (max-width: 600px) {
  //   width: 85%;
  //   padding-top: 10px;
  // }
  object-fit: contain;
  height: 100%;
  width: 530px;
  height: 352px;
  padding-top: 10px;
}
