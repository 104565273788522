@mixin text_styles($color, $weight) {
  font-family: var(--font_family_Medium);
  font-size: 14px;
  font-weight: $weight;
  color: $color;
}

.try_again {
  font-size: 16px;
  font-weight: var(--h4_font_weight);
  line-height: 26px;
  color: var(--primary_color);
  cursor: pointer;
  text-align: center;
}

.container {
  background-color: var(--light_primary_color);
  border-radius: 8px;
  padding: 26px 25px;
  margin: 20px 0;
  @media (max-width: 600px) {
    padding: 20px 15px;
  }
  @include text_styles(var(--text_color), var(--font_weight_1));
  .input_container {
    display: flex;
    .button_iccid {
      height: 56px;
      margin-left: -30px;
      z-index: 1;
      object-fit: contain;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--primary_color);
      width: 100px;
      border-radius: 0px 10px 10px 0px;
      color: var(--white_1);

      @media (max-width: 600px) {
        height: 55px;
        right: 0px;
        width: auto;
        padding: 0px 4px;
        display: none;
      }
    }

    .custom_disabled_button {
      height: 56px;
      margin-left: -30px;
      z-index: 1;
      object-fit: contain;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--primary_color);
      width: 100px;
      border-radius: 0px 10px 10px 0px;
      color: var(--white_1);
      cursor: not-allowed !important;
      pointer-events: auto !important;
      opacity: 0.5;
      @media (max-width: 600px) {
        height: 55px;
        right: 0px;
        width: auto;
        padding: 0px 4px;
        display: none;
      }
    }

    .try_again {
      font-size: 16px;
      font-weight: var(--h4_font_weight);
      line-height: 26px;
      color: var(--primary_color);
    }
  }

  .simid_length {
    height: 55px;
    z-index: 1;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 10px 10px 0px;
    color: #5A5A5A;
    position: relative;
    right: 35px;
    opacity: 0.2;
    font-weight: 400;
    font-size: 10px;
    margin-top: 4px;
    @media (max-width: 600px) {
      right: 0px;
      border-right: 1px solid #313131;
      border-bottom: 1px solid #313131;
      border-top: 1px solid #313131;
      padding-right: 10px;
      height: 54px;
    }
  }

  .simid_length1 {
    height: 55px;
    z-index: 1;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 10px 10px 0px;
    color: #5A5A5A;
    position: relative;
    right: 35px;
    opacity: 0.2;
    font-weight: 400;
    font-size: 10px;
    @media (max-width: 600px) {
      right: 0px;
      border-right: 1px solid #313131;
      border-bottom: 1px solid #313131;
      border-top: 1px solid #313131;
      padding-right: 10px;
      height: 54px;
      display: none;
    }
  }

  .header {
    color: var( --dark-charcoal-fade);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    margin-bottom: 10px;
  }

  .footer {
    color: var(--dark-charcoal-fade);
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    margin-top: 9px;
  }

  .footerv2{
    color: var(--dark-charcoal-fade);
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    margin-top: 15px;
  }

  .simid_loader {
    margin-left: -64px;
    z-index: 1;
    height: 54.5px;
    width: 100px;
    object-fit: contain;
    background-color: var(--primary_color);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white_3);
    border-radius: 0px 5px 5px 0px;

    @media (max-width: 600px) {
      top: 200px;
      right: 13px;
      display: none;
    }
  }

  .check_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }

  .close_btn {
    color: #313131;
    font-size: 12px;
    position: absolute;
    right: -5px;
    top: -15px;
    cursor: pointer;
  }

  .success_msg {
    color: var(--primary_color);
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin-left: 10px;
  }

  .error_msg {
    font-family: var(--font_family_Semibold);
    color: #D40000;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    margin-left: 10px;
  }

  .sucess_loader {
    width: 40px;
    display: flex;
  }
}

.button_iccid_mob {
  height: 15px;
  z-index: 1;
  object-fit: contain;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary_color);
  width: 200px;
  border-radius: 0.375rem;
  color: var(--white_1);
  margin-top: 15px;
  padding: 10px;
  @media (min-width: 600px) {
    display: none;
  }
}

.custom_disabled_button_mob {
  height: 15px;
  z-index: 1;
  object-fit: contain;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary_color);
  width: 200px;
  border-radius: 0.375rem;
  color: var(--white_1);
  margin-top: 15px;
  cursor: not-allowed !important;
  pointer-events: auto !important;
  opacity: 0.5;
  padding: 10px;
  @media (min-width: 600px) {
    display: none;
  }
}