.plan_card_main_container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;
  .next_link_plan {
    display: flex;
    flex-direction: column;
    margin: 0 10px 40px;
    width: 260px;
    .current_plan_tag {
      padding: 5px 0px;
      width: 55%;
      border-radius: 4px 0 4px 0;
      font-size: 12px;
      font-weight: var(--font_weight_2);
      background-color: var(--primary_color);
      color: var(--white);
      text-align: center;
      margin: -12px 0 0 -21px;
      font-family: var(--font_family_Medium);
    }
    .plan_name {
      font-family: var(--font_family_Bold);
      line-height: 2;
      font-size: 22px;
      color: var(--text_color);
    }
    .plan_type {
      font-size: 15px;
      margin: 5px 0;
      font-family: var(--font_family_Semibold);
      line-height: 1.67;
      color: var(--text_color);
      text-align:center
    }
    .plan_price {
      margin: 0 4px 0 0;
      font-family: var(--font_family_Bold);
      font-size: 24px;
      line-height: 1.5;
      color: var(--light_black_1);
      .tax_text {
        font-family: var(--font_family_Semibold);
        color: var(--light_black_2);
      }
    }
    .view_details {
      color: var(--primary_background);
      font-family: var(--font_family_Bold);
      font-size: 14px;
      text-align: center;
      cursor: pointer;
    }
    .cell_phone_plan {
      font-family: var(--font_family_Bold);
      font-size: 28px;
      color: var(--text_color);
    }
    .plan_data_text {
      text-align: start;
      font-family: var(--font_family_Semibold);
      color: var(--light_black_2);
      font-size: 12px;
    }
    .plan {
      font-size: 12px;
      font-family: var(--font_family_Semibold);
      color: var(--text_color);
    }
    .plan_card_data_container {
      .plan_card_price {
        padding: 10px 0 0;
      }
      .icon_offers {
        display: flex;
        align-items: center;
        padding: 10px 0 0;
        .offer_text {
          margin-left: 10px;
          font-family: var(--font_family_Semibold);
          font-size: 14px;
          color: var(--text_color);
          line-height: 1.14;
        }
      }
      .plan_card_view_more {
        cursor: pointer;
        display: flex;
        color: var(--primary_background);
        margin: 10px 0 0;
        .view_text {
          font-family: var(--font_family_Bold);
          font-size: 14px;
          font-weight: var(--font_weight_4);
        }
      }
    }
  }
  .home_plans {
    box-shadow: 0 0 1px 1px hsla(0, 0%, 90.2%, 0.5019607843137255),
      0 0 0 0 rgba(0, 0, 0, 0.5019607843137255);
    border: 1px solid var(--card-color);
    border-radius: 4px;
  }
  .on_hover {
    box-shadow: 0 0 11px rgb(33 33 33 / 20%);
    border-radius: 4px;
  }
  .tag_border {
    border: 2px solid var(--primary_color);
  }
}
