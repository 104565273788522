.text_fields_main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .text_fields_div {
    width: 48%;
    margin-bottom: 20px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    .text_fields_div {
      width: 100%;
    }
  }
  .account_labels {
    font-family: var(--font_family_Semibold);
    font-size: 15px;
    font-weight: var(--font_weight_2);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--warm_grey);
    margin: 0 0 10px 0;
  }
  .error_message {
    color: var(--danger);
    font-size: 12px;
    font-family: var(--font_family_Medium);
    line-height: 24px;
    text-align: left;
    margin: 5px 5px 0 5px;
  }
}
