.Modal {
  display: flex;
  justify-content: center;
  border-radius: 6px;
  .ModalData {
    background-color: var(--white);
  }
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;

    @media (max-width: 600px) {
      right: 15px;
      top: 15px;
    }
  }
  .closeRight {
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
    font-size: 34px;
    @media (max-width: 600px) {
      right: 15px;
      top: 15px;
    }
  }
}
// ::-webkit-scrollbar {
//   display: none;
// }