.right_div {
  .title {
    color: var(--text_color);
    font-family: var(--font_family_Semibold) !important;
    font-weight: var(--font_weight_2) !important;
    line-height: 1 !important;
    width: 100%;
  }
  .description {
    font-family: var(--font_family_Semibold) !important;
    font-size: 14px !important;
    font-weight: var(--font_weight_2) !important;
    line-height: 1.43 !important;
    color: var(--text_color_2) !important;
    line-height: 1.43 !important;
  }
  .step_icon {
    display: flex;
    align-self: center;
  }
  .step_content {
    width: 100%;
    .step_description,
    .step_title {
      color: var(--text_color_2);
      font-size: 14px !important;
      line-height: 1.5;
    }
    .step_description {
      font-family: var(--font_family_Semibold);
    }
    .step_title {
      font-family: var(--font_family_Medium);
    }
  }
}
