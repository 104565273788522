.rnc__notification-container--top-full {
  .rnc__notification {
    width: 100%;

    .rnc__notification-item--info {
      background-color: var(--info_notification) !important;
      border-left: 0px;
    }
    .rnc__notification-item--info .rnc__notification-close-mark {
      background-color: var(--info_notification) !important;
    }
    .rnc__notification-item--danger {
      background-color: var(--danger_notification) !important;
      border-left: 0px;
    }
    .rnc__notification-item--danger .rnc__notification-close-mark {
      background-color: var(--danger_notification) !important;
    }
    .rnc__notification-item--default {
      border-left: 0px;
      background-color: var(--default_notification) !important;
    }
    .rnc__notification-item--default .rnc__notification-close-mark {
      background-color: var(--default_notification) !important;
    }
    .rnc__notification-item--success {
      border-left: 0px;
      background-color: var(--success_notification) !important;
    }
    .rnc__notification-item--success .rnc__notification-close-mark {
      background-color: var(--success_notification) !important;
    }
    .rnc__notification-item--warning {
      border-left: 0px;
      background-color: var(--warning_notification) !important;
    }
    .rnc__notification-item--warning .rnc__notification-close-mark {
      background-color: var(--warning_notification) !important;
    }

    .rnc__notification-message {
      font-size: 16px;
      font-family: var(--font_family_Semibold);
      margin-top: 3px !important;
      margin-bottom: 3px !important;
    }
    .rnc__notification-close-mark:after {
      top: 12px;
      font-size: 30px;
    }
    @media (max-width: 600px) {
      .rnc__notification-close-mark {
        top: 35% !important;
      }
    }
  }
}
.rnc__base {
  z-index: 1098 !important;
}
