.user_list_container {
  border-radius: 10px;

  background: var(--white);
  .view_history_cta {
    text-align: center;
    padding-bottom: 20px;
    font-size: 16px;
    line-height: 26px;
    font-family: var(--font_family_Semibold);
    color: var(--primary_color);
    font-weight: var(--font_weight_2);
    cursor: pointer;
    @media screen and (max-width: 600px) {
      line-height: 28px;
    }
  }
  .user_name {
    display: flex;
    align-items: center;
    font-family: var(--font_family_Medium);
    font-size: 16px;
    color: var(--text_color);

    text-transform: capitalize;
    @media (max-width: 900px) {
      flex-direction: column;
      gap: 10px;
    }
    .is_primary {
      font-family: var(--font_family_Medium);
      font-weight: var(--font_weight_1) !important;
      line-height: 26px;
      font-size: 16px;
    }
    .plan_active {
      font-weight: var(--font_weight_0);
      padding-left: 5px;
    }
  }
  .user_num {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-family: var(--font_family_Medium);
    font-weight: var(--font_weight_1);
    line-height: 28px;
    color: var(--text_color_18);
    @media (max-width: 900px) {
      justify-content: center;
    }
    span {
      font-family: var(--font_family_Medium);
    }
  }
  .sim_type {
    font-size: 14px;
    line-height: 17px;
    font-family: var(--font_family_Semibold);
    font-weight: var(--font_weight_2);
    background: var(--lite_background2);
    color: var(--primary_color);
    border-radius: 2px;
    padding: 1px 10px;
  }
}
