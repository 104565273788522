.container {
    .share_reach {
      padding: 30px 0;
      text-align: center;
      background-color: var(--lightgrey_2);
      font-size: 18px;
      font-weight: var(--font_weight_4);
      bottom: 0;
      span {
        color: var(--success);
        cursor: pointer;
      }
      .triangle {
        margin-bottom: -6px;
        margin-left: -7px;
      }
      @media (max-width: 600px) {
        padding: 30px 55px;
      }
    }
    .page_line {
      margin: 5px auto;
      width: 74px;
      height: 3px;
      border-radius: 10px;
      background-color: var(--primary_brand_color);
    }
    .order_circle {
      :global(.MuiCircularProgress-root.MuiCircularProgress-colorPrimary.MuiCircularProgress-indeterminate) {
        height: 12px !important;
        width: 12px !important;
      }
    }
  }
  .page_line1 {
    margin: 5px auto;
    width: 75px;
    height: 2px;
    border-radius: 1px;
    background-color: var(--primary_color);
  }
  