.success_modal_container {
    margin-top: 24px;
    text-align: center;
    .success_image {
        width: 126px;
        height: 101px;
    }
    .success_order_text {
        font-family: var(--font_family_Bold);
        font-size: 22px;
        text-align: center;
        color: var(--black);
        margin-top: 8px;
        margin-bottom: 48px;
    }
}