.terms_page_content {
	padding: 50px 0;
	.content {
		margin-left: 90px;
		margin-right: 90px;
	}
	@media screen and (max-width: 500px) {
		.content {
			margin-left: 20px;
			margin-right: 20px;
		}
	}
	@media screen and (min-width: 1600px) {
		.content {
			margin-left: 190px;
			margin-right: 190px;
		}
	}
	.terms_title {
		font-family: var(--font_family_Semibold);
		font-size: 28px;
		font-weight: var(--font_weight_2);
		line-height: 1.43;
		letter-spacing: normal;
		color: var(--text_color);
		text-align: center;
	}
	.terms_headings {
		font-family: var(--font_family_Semibold);
		font-size: 18px;
		font-weight: var(--font_weight_4);
		color: var(--text_color);
		text-align: left;
		line-height: normal;
	}
	.terms_para {
		font-size: 15px;
		font-weight: var(--font_weight_0);
		line-height: 2.27;
		color: var(--text_color_3);
		font-family: var(--font_family_Medium);
		margin: 20px 0;
		text-align: left;
		font-size: 15px;
	}
	a {
		text-decoration: none;
		color: var(--primary_color);
		font-weight: var(--terms_anchor_tag_font_weight);
	}
	.terms_line {
		width: 80px;
		height: 5px;
		border-radius: 2.5px;
		background-color: var(--primary_brand_color);
		margin: 10px auto 50px auto;
	}
}
