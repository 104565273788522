.account_buy_add {
  font-family: var(--font_family_Semibold);
  font-size: 17px;
  font-weight: var(--font_weight_2);
  text-align: center;
  color: var(--warm-grey);
  padding: 10px 30px;
  border: 1px solid var(--warm-grey);
  margin-bottom: 30px;
  border-radius: 5px;
}
.selected {
  background: var(--selected_data_color) !important;
  border: 1px solid var(--primary_color) !important;
  color: var(--primary_color) !important;
}
