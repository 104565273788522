.no_order_icon {
  height: 200px;
  width: 160px;
}
.line {
  width: 80px;
  height: 5px;
  border-radius: 3px;
  background: #333;
  margin: 15px auto 0;
  text-align: center;
}

.all_pack_container {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: var(--white);
  margin-bottom: 30px;
  .pack_details {
    padding: 25px 25px 0;
  }
  .dot {
    height: 6px;
    width: 6px;
    background-color: var(--lightgrey_7);
    border-radius: 50%;
    display: inline-block;
    margin: 0 10px;
  }
  .horizontal_line {
    height: 1px;
    background-color: var(--tab_color_2);
    width: 100%;
    opacity: 0.05;
    margin: 20px 0 25px;
  }
  .vertical_line {
    width: 2px;
    height: 20px;
    opacity: 0.7;
    margin: 0 15px;
    background-color: var(--tab_color_2);
  }
  .cancel_txt,
  .reschedule_txt {
    font-size: 16px;
    font-family: var(--font_family_Medium);
    font-weight: var(--font_weight_5);
    padding: 24px 0;
    border: 1px solid var(--lightgrey_8);
    flex: 1;
    text-align: center;
    cursor: pointer;
  }
  .cancel_txt {
    color: var(--tab_color_2);
  }
  .reschedule_btn {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .reschedule_txt {
    background-color: var(--primary_color);
    color: var(--white);
  }
  .country_name {
    text-transform: capitalize;
  }
}

.data_boost_wrapper {
  width: 100%;
  .data_boost_container {
    height: auto;
    border-radius: 4px;
    border: 1px solid rgba(90, 90, 90, 0.3);
    background: var(--white);
    padding: 50px;
    text-align: center;
    .data_proceed_btn {
      width: 400px;
      border-radius: 6px;
      margin: 40px 0 50px;
      cursor: pointer;
    }
  }
  .user_name_db {
    color: var(--primary_brand_color_dark);
    border: 1px solid var(--primary_brand_color_dark);
    padding: 1px 7px;
    font-size: 20px;
    border-radius: 2px;
    background: var(--toggle_button);
    font-family: var(--font_family_SemiBold) !important;
    height: fit-content;
  }
}

.ir_cancel_title {
  font-family: var(--font_family_Medium);
  font-weight: var(--font_weight_5);
  font-size: 22px;
  color: var(--tab_color_2);
  line-height: 22px;
}

.ir_cancel_text {
  font-family: var(--font_family_Semibold);
  font-weight: var(--font_weight_5);
  font-size: 14px;
  color: var(--tab_color_2);
  line-height: 24px;
  margin: 30px 0 40px;
}

.ir_cancel_suc_title {
  font-family: var(--font_family_Medium);
  font-weight: var(--font_weight_5);
  font-size: 22px;
  color: var(--tab_color_2);
  line-height: 22px;
  margin-top: 15px;
}

.ir_cancel_suc_text {
  font-family: var(--font_family_Semibold);
  font-weight: var(--font_weight_5);
  font-size: 14px;
  color: var(--tab_color_2);
  line-height: 24px;
  margin: 20px 0 30px;
}
