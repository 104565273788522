@mixin layout_view {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.step4_main {
  @include layout_view;
  font-size: 14px;

  .heading_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0px 15px 0px;
    .arrow_icon {
      cursor: pointer;
      width: 24px;
      height: 24px;
      z-index: 100;
      left: 0;
      position: absolute;
    }
    .title_main {
      font-family: var(--font_family_Bold);
      font-size: 22px;
      font-weight: var(--font_weight_4);
      line-height: 1;
      text-align: center;
      color: var(--text_color);
    }
  }

  .icon_size {
    height: 23px;
    width: 23px;
    margin-right: 1rem;
  }
  .labels_grid{
  .labels {
    font-family: var(--font_family_Medium);
    font-size: 14px;
    color: var(--text_color_2);
    padding-bottom: 0;
    margin-bottom: 2px;
    overflow-wrap: break-word;
    inline-size: 100%;
   
  }
  .details {
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    color: var(--text_color_2);
    margin-bottom: 0;
    inline-size: 100%;
    overflow-wrap: break-word;
    
  }
    @media (max-width: 600px) {
      width: calc(100% - 30px);
    }
    @media (max-width: 280px) {
      width: 100px;
      padding-right: 20px;
    }
  }

  .activation_box {
    @include layout_view;
    border-radius: 6px;
    box-shadow: 0 0 1px 1px var(--activation_box_step4);
    background-color: var(--lightgrey_6);
    border: 1px solid var(--chinese-silver);
    margin: 0 auto 40px auto;
  }
  .activation_final_heading {
    font-family: var(--font_family_Semibold);
    text-align: center;
    color: var(--text_color_2);
    margin-bottom: 10px;
  }
  .activation_final_sub_heading {
    font-family: var(--font_family_Medium);
    text-align: center;
    color: var(--text_color_2);
    margin-bottom: 30px;
  }
}
.stepper_content {
  @include layout_view;
  align-items: center;
  .stepper_txt {
    font-family: var(--font_family_Medium);
    color: var(--text_color);
    font-size: 16px;
    padding-bottom: 20px;
  }
}
