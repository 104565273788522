.fcc_container {
  padding: 20px;
  padding-right: 30px;
  min-height: 90px;
  background: var(--white);
  border-radius: 16px;
  width: 56%;
  @media screen and (max-width: 600px) {
    width: 75%;
  }
  margin: 42px auto 102px;
  .account_info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 10px;
    .account_title {
      font-family: var(--font_family_Bold);
      font-size: 24px;
      font-weight: var(--font_weight_4);
      line-height: 36px;
      padding-left: 10px;
      color: var(--black);
    }
  }
  .lock_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 5px 0px;
    .account_lock {
      font-family: var(--font_family_Semibold);
      font-size: 16px;
      line-height: 24px;
      display: flex;
      color: var(--dark-charcoal-fade);
    }
  }
}
.fcc_modal,
.fcc_otp_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
  .fcc_title {
    padding-top: 20px;
    font-family: var(--font_family_Bold);
    font-size: 24px;
    font-weight: var(--font_weight_4);
    line-height: 36px;
    color: var(--black);
  }
  .fcc_desc {
    font-size: 14px;
    font-family: var(--font_family_Regular);
    font-weight: var(--font_weight_0);
    line-height: 21px;
    color: var(--button_secondary_color);
    span {
      font-family: var(--font_family_Semibold);
      font-size: 14px;
      font-weight: var(--font_weight_2);
      line-height: 21px;
    }
  }
  .fcc_btn {
    font-weight: var(--font_weight_2) !important;
    line-height: 26px !important;
    width: 250px;
  }
  .did_not_receive_otp {
    font-family: var(--font_family_Medium);
    font-size: 14px;
    font-weight: var(--font_weight_1);
    line-height: 24px;
    color: var(--dim_gray);
  }
  label {
    border: none !important;
    // padding: 0px 6px;
    border-radius: 4px;
    font-size: 11px !important;
    top: 4px !important;
  }
  legend {
    font-size: 12.8px;
  }
  .fcc_invalid_otp {
    span {
      font-family: var(--font_family_Semibold) !important;
      font-weight: var(--font_weight_2) !important;
      background: var(--white);
      border: none;
    }
    font-size: 12px;
    line-height: 18px;
    display: flex;
  }
}
