.main_container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;

  .back_arrow {
    position: absolute;
    left: 90px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: var(--font_family_Medium) !important;
    font-weight: var(--font_weight_3);
    @media (max-width: 900px) {
      left: 5px;
      top: 14%;
    }
    @media (max-width: 600px) {
      left: 5px;
      top: 18%;
    }
  }

  .heading {
    font-size: 40px;
    font-family: var(--font_family_Bold) !important;
    font-weight: var(--font_weight_4);
    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 32px;
    }
    @media (max-width: 600px) {
      font-size: 24px;
      width: 65%;
      text-align: center;
    }
  }
  .desc {
    font-size: 18px;
    font-family: var(--font_family_Bold) !important;
    font-weight: var(--font_weight_4);
    margin: 30px 0;
    @media (max-width: 600px) {
      text-align: center;
      font-size: 16px;
    }
  }
  .full_name {
    color: var(--text_color);
    border: 1px solid var(--text_color);
    width: fit-content;
    font-size: 16px;
    padding: 20px;
    border-radius: 8px;
    font-family: var(--font_family_Bold) !important;
    font-weight: var(--font_weight_4);
    background: var(--gray_background);
  }
}

.textfield_cust {
  :global(.MuiFilledInput-input) {
    border: none;
    color: var(--text_color_12);
  }
  :global(.MuiInputBase-root) {
    border: 1px solid var(--checkout_border);
    border-radius: var(--checkout_border_radius);
    color: var(--text_color_12);
    padding: 3px 7px;
    font-size: 14px;
  }
  :global(.MuiInputLabel-filled) {
    margin-top: -5px;
    color: var(--text_color_12);
  }
}
.flexCenter {
  display: flex;
  justify-content: center;
}
