.title {
  color: var(--text_color);
  font-family: var(--font_family_Semibold) !important;
  font-weight: var(--font_weight_2) !important;
  line-height: 1 !important;
  width: 100%;
}
.description {
  font-family: var(--font_family_Medium) !important;
  font-size: 18px !important;
  font-weight: var(--font_weight_1) !important;
}
.step_icon {
  position: relative;
}
.step_icon_line {
  border-left: 1px solid rgba(0, 0, 0, 0.25);
  height: 100%;
  position: absolute;
  top: 38px;
  margin-left: 13px;
}
.step_content {
  color: var(--text_color);
  width: 100%;
  .step_description {
    font-size: 14px;
    font-family: var(--font_family_Medium);
    font-weight: var(--font_weight_1);
    line-height: 1.71;
  }
  .step_title {
    font-size: 18px;
    font-family: var(--font_family_Medium);
    font-weight: var(--font_weight_2);
    line-height: 1.33;
  }
}
