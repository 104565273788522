.plan_label {
  border-radius: 2px;
  background-color: var(--lavender);
  padding: 3px 8px;
  float: right;
  span {
    font-family: var(--font_family_Semibold);
    font-size: 12px;
    color: var(--white);
    margin-left: 4px;
  }
}
.plan_border_bottom {
  width: 75px;
  height: 5px;
  background-color: var(--primary_brand_color);
  margin-bottom: 20px;
  border-radius: 25px;
  margin: 0;
}
.data_box {
  border-radius: 2px;
  border: 1px solid #cccccc;
  padding: 5px;
  margin: 5px;
  width: 19%;
  text-align: center;
  cursor: pointer;
  font-size: 22px;
  font-family: var(--font_family_Semibold);
  @media (max-width: 600px) {
    width: 26%;
  }
}
.plan_per_month_cost {
  color: var(--text_color);
  font-family: var(--font_family_Medium);
  font-size: 18px;
  span:nth-child(1) {
    font-family: var(--font_family_Bold);
    font-size: 40px;
    color: var(--primary_brand_color);
  }
  span:nth-child(2) {
    font-family: var(--font_family_Semibold);
    font-size: 18px;
    color: var(--primary_brand_color);
  }
  span:nth-child(3) {
    font-family: var(--font_family_Semibold);
    font-size: 18px;
    color: var(--grey);
  }
  @media (max-width: 600px) {
    font-size: 14px;
    span:nth-child(1) {
      font-size: 35px;
    }
    span:nth-child(2) {
      font-size: 14px;
    }
    span:nth-child(3) {
      font-family: var(--font_family_Semibold);
      font-size: 14px;
      color: var(--grey);
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
    font-size: 16px;
    span:nth-child(1) {
      font-size: 40px;
    }
    span:nth-child(2) {
      font-size: 16px;
    }
  }
}
.plan_per_line_cost {
  font-family: var(--font_family_Semibold);
  color: var(--text_color);
  span {
    font-family: var(--font_family_Bold);
  }
}
.unlimited_plan_text {
  color: var(--text_color);
  font-family: var(--font_family_Semibold);
  margin-left: 0px;
}
.active_plan {
  background: var(--primary_brand_color);
  color: var(--white);
}
.plan_offer_text {
  color: var(--primary_brand_color);
  font-family: var(--font_family_Medium);
  font-size: 20px;
  margin: 20px 0 10px;
  text-align: left;

  .plan_offer_text_one {
    color: var(--primary_brand_color);
    font-family: var(--font_family_Medium);
    // font-size: 20px;
    // @media (max-width: 600px) {
    //     font-size: 16px;
    // }
    // @media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
    //     font-size: 16px;
    // }
    // @media (max-width: 1024px) {
    // }
  }
  .plan_offer_text_two {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // .lineBoxImage {
    //     margin-bottom: 40px;
    // }
    p {
      color: var(--primary_brand_color);
      font-family: var(--font_family_Medium);
      // font-size: 20px;
      margin-bottom: 0;
      margin-left: 5px;
    }
    // @media (max-width: 600px) {
    //     font-size: 16px;
    // }
    // @media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
    //     font-size: 16px;
    // }
  }
}
.fade_out {
  opacity: 0.5;
  cursor: not-allowed;
}
.scroll_div::-webkit-scrollbar { 
  display: none; 
}