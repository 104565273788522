
    .explore_plans_banner{
        background-color: var(--primary_background);
      
      .explore_title{
        color: var(--white);
        font-family: var(--font_family_Semibold);
        font-weight: var(--font_weight_2);
        line-height: 1.8;
      }
      .explore_subtitle{
       color: var(--white);
        font-family: var(--font_family_Medium);
        font-weight: var(--font_weight_1);
        line-height: 1.73;
    }}
