.details_coverage {
  .note_style {
    font-family: var(--font_family_Bold);
  }
  .check_coverage {
    background-color: var(--gray_background);
  }
  #iframeMapId {
    width: 100%;
    height: 100vh;
    border-width: 0px;
  }
}
