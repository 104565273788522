@mixin buttonStyle($bgcolor, $borderColor, $color, $borderRadius) {
  height: 2.8125em !important;
  background-color: $bgcolor !important;
  border: 0.0625rem solid $borderColor !important;
  color: $color !important;
  font-size: 1em !important;
  font-weight: var(--button_cust_font_weight) !important;
  line-height: 1.125em !important;
  text-align: center !important;
  letter-spacing: 0 !important;
  font-family: var(--font_family_Semibold) !important;
  text-transform: none !important;
  min-width: 200px !important;
  @media (max-width: 360px) {
    min-width: 100px !important;
  }
  @media (max-width: 600px) {
    min-width: 144px !important;
  }
  border-radius: $borderRadius !important;
  :global(.MuiCircularProgress-colorPrimary) {
    color: $color !important;
    width: 35px !important;
    height: 35px !important;
  }
}
.custom_primary_outlined_rounded {
  @include buttonStyle(
    var(--white),
    var(--primary_color),
    var(--primary_color),
    25px
  );
}
.custom_primary_contained_rounded {
  @include buttonStyle(
    var(--primary_color),
    var(--primary_color),
    var(--white),
    25px
  );
}
.custom_secondary_contained_rounded {
  @include buttonStyle(var(--dusk), var(--dusk), var(--white), 25px);
}
.custom_secondary_transparent_brand_rounded {
  @include buttonStyle(
    transparent,
    var(--primary_color),
    var(--primary_color),
    25px
  );
}
.custom_secondary_transparent_white_rounded {
  @include buttonStyle(transparent, var(--white), var(--white), 25px);
}
.custom_primary_outlined_square {
  @include buttonStyle(
    var(--white),
    var(--primary_color),
    var(--primary_color),
    4px
  );
}
.custom_primary_contained_square {
  @include buttonStyle(
    var(--primary_color),
    var(--primary_color),
    var(--white),
    4px
  );
}
.custom_secondary_contained_square {
  @include buttonStyle(var(--dusk), var(--dusk), var(--white), 4px);
}
.custom_secondary_transparent_brand_square {
  @include buttonStyle(
    transparent,
    var(--primary_color),
    var(--primary_color),
    4px
  );
}
.custom_secondary_transparent_white_square {
  @include buttonStyle(transparent, var(--white), var(--white), 4px);
}
.custom_disabled_button {
  opacity: 0.5;
  cursor: not-allowed !important;
  pointer-events: auto !important;
}
