.mainRoutes {
  margin: 71px auto 0 auto;
  max-width: 1680px;
  min-height: 88vh;
  // background-color: var(--light_primary_color);
  // background-color: var(--lightgrey);
  @media (max-width: 1920px) {
    max-width: 1440px;
    min-width: 1680px;
  }
  @media (max-width: 1680px) {
    max-width: 1680px;
    min-width: 1536px;
  }
  @media (max-width: 1536px) {
    max-width: 1536px;
    min-width: 1440px;
  }
  @media (max-width: 1440px) {
    max-width: 1440px;
    min-width: 1024px;
  }

  @media (max-width: 1024px) {
    max-width: 1024px;
    min-width: 769px;
    margin: 71px auto 0 auto;
  }
  @media (max-width: 768px) {
    max-width: 768px;
    min-width: 600px;
    margin: 71px auto 0 auto;
  }
  @media (max-width: 600px) {
    min-width: 375px;
    margin: 71px auto 0 auto;
    min-height: 320px;
  }
  @media (max-width: 425px) {
    max-width: 425px;
    min-width: 375px;
    min-height: 320px;
  }
  @media (max-width: 375px) {
    max-width: 375px;
    min-width: 100%;
    min-height: 320px;
  }
}

.mainRoutesLanding {
  padding-top: 67px !important;
  margin: 0 auto;
  max-width: 1680px;

  @media (max-width: 1920px) {
    max-width: 1920px;
  }
}

.landingPageRoutes {
  padding: 0;
}

/* Notification */
.rnc__notification-container--top-full {
  .rnc__notification {
    width: 100% !important;
    text-align: center !important;
  }
}
.rnc__notification-item--info {
  background-color: var(--info_notification) !important;
  border-left: 8px solid var(--info_notification) !important;
}
.rnc__notification-item--info .rnc__notification-close-mark {
  background-color: var(--info_notification) !important;
}

.rnc__notification-item--danger {
  background-color: var(--danger_notification) !important;
}
.rnc__notification-item--danger .rnc__notification-close-mark {
  background-color: var(--danger_notification) !important;
}
