.Header_Wrapper {
  .Header_Container {
    background-color: var(--white) !important;
    height: 71px;
    box-shadow: 0px 0px 5px rgba(83, 83, 83, 0.5);
    .logo {
      cursor: pointer;
      width: 140px;
      padding: 0 0 0 40px;
      vertical-align: middle;
    }
    .header_txt {
      font-size: var(--page_header_font_size_desktop);
      font-weight: var(--font_weight_4);
      color: var(--text_color);
    }
    .secondary_header {
      padding-right: 10px;
      :global(.MuiButtonBase-root) {
        color: var(--dark-charcoal-fade);
      }
    }
  }
  .d_view_port {
    display: none;
  }
  @media (max-width: 1024px) {
    .Header_Container {
      display: none;
    }
    .d_view_port {
      display: block;
      background-color: var(--white) !important;
      height: 71px;
      box-shadow: 0px 0px 5px rgba(83, 83, 83, 0.5);
      .menu_icon {
        color: var(--dark-charcoal-fade);
        width: 30px;
        height: 30px;
        padding: 0px 0px 0px 15px;
        @media (max-width: 600px) {
          width: 35px;
          height: 30px;
        }
      }
      .logo {
        padding: 0 0 0 15px;
        width: 90px;
        height: 30px;
        cursor: pointer;
      }
    }
  }
}
