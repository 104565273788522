.compare_plans {
  @media (max-width: 600px) {
    padding: 20px;
    border: 0.6px solid rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    background: var(--white);
    margin: 20px 4px;
  }
  .label_text {
    font-family: var(--font_family_Regular);
    font-size: 14px;
    font-weight: var(--font_weight_1);
    line-height: 2.29;
    color: var(--grey_shade);
  }
  .label_text b {
    color: var(--text_color);
    font-family: var(--font_family_Semibold);
  }
  .section_header {
    font-family: var(--font_family_Bold);
    font-size: 18px;
    font-weight: var(--font_weight_2);
    line-height: 2.17;
    color: var(--dark-charcoal-fade);
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
  .line_break {
    border-top: 1px solid var(--horizontal_divider);
  }

  .input_grp_bill {
    display: flex;
    :global(.MuiFilledInput-root) {
      border-radius: 6px 0 0 6px;
      border-right: none !important;
    }
  }
  .input_group_gb {
    height: 26px;
    border-radius: 0 6px 6px 0;
    padding: 0.875rem 0.75rem;
    font-size: 1rem;
    font-weight: var(--font_weight_0);
    white-space: nowrap;
    background-color: var(--primary_light);
    border: 1px solid var(--bill_cal_input_border_color);
    border-left: 0;
    color: var(--primary_color);
  }
  .bill_cal_popper {
    :global(.MuiPopper-arrow::before) {
      filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 3.08)) !important;
    }
  }
  .qs_mark_size {
    width: 17px;
    height: 17px;
    margin-bottom: -4px;
    cursor: pointer;
  }
}
