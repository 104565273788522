.infocard_detail_title {
  font-family: var(--font_family_Bold);
  font-size: 26px;
  line-height: 1.5;
  color: var(--text_color);
  @media (max-width: 600px) {
    font-size: 22px;
  }
}
.infocard_border_bottom {
  width: 75px;
  height: 5px;
  background-color: var(--primary_brand_color);
  margin-top: 20px !important;
  border-radius: 25px;
}
.infocard_detail_data {
  margin: 20px 0 25px;
  font-family: var(--font_family_Medium);
  font-size: 20px;
  font-weight: var(--font_weight_1);
  line-height: 1.5;
  color: var(--text_color);
  @media (max-width: 600px) {
    font-size: 16px;
  }
}
.infocard_detail_subdata {
  margin: -10px 0 25px;
  font-family: var(--font_family_Medium);
  font-size: 16px;
  font-weight: var(--font_weight_1);
  line-height: 1.88;
  color: var(--text_color);
  @media (max-width: 600px) {
    font-size: 14px;
  }
}
