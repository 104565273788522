.graph {
  margin-bottom: 30px;
  box-shadow: 0px 1px 4px 1px var(--lightgrey_4);
  padding: 25px 10px;
  text {
    font-family: var(--font_family_Semibold);
    color: var(--text-color);
    font-style: normal;
  }
  :global(.google-visualization-tooltip) {
    width: 190px;
    height: fit-content;
  }
  .tooltip_content {
    padding: 10px;
    .individual_div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid var(--lightgrey_4);
      div {
        font-family: var(--font_family_Medium);
      }
    }
    .comparison_font {
      font-family: var(--font_family_Bold) !important;
      color: var(--text_color);
    }
  }
}