.plan_features{
    .plan_features_title{
        font-family: var(--font_family_Semibold);
        font-weight: 600;
        font-size: 24px;
        color: var(--black);
    }
    .plan_features_sub_title{
        font-size: 18px;
        font-weight: 500;
        font-family: var(--font_family_Medium);
        color: var(--black);
    }
}