.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto;
  padding: 10px 17px 10px 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 hsl(0deg 0% 84% / 50%);
  background-color: var(--lightgrey);

  .img {
    width: 35px;
    height: 35px;
    vertical-align: middle;
    margin-right: 15px;
  }

  .owner {
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    font-weight: var(--font_weight_2);
    line-height: 1.14;
    color: var(--text_color);
    text-align: left;
    padding-bottom: 5px;
    float: left;
  }

  .simType {
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    line-height: 1.14;
    font-weight: var(--font_weight_2);
    color: var(--primary_brand_color);
    padding-bottom: 5px;
  }

  .owner_mail {
    font-family: var(--font_family_Medium);
    font-size: 12px;
    font-weight: var(--font_weight_1);
    line-height: 1.14;
    text-align: left;
    color: var(--grey_shade);
    word-break: break-all;
  }

  .active {
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    font-weight: var(--font_weight_2);
    line-height: 1.43;
    text-align: right;
    color: var(--manage_lines_user_status);
  }

  .active_fail {
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    font-weight: var(--font_weight_2);
    line-height: 1.43;
    text-align: right;
    color: var(--danger);
  }

  .try_again {
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    font-weight: var(--font_weight_2);
    line-height: 1.43;
    text-align: right;
    color: var(--dusk);
  }

  .disabled_btn {
    cursor: not-allowed;
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    opacity: 0.5;
  }

  .click {
    cursor: pointer;
    font-size: 14px;
    line-height: 1.43;
    text-align: right;
    color: var(--manage_lines_user_status);
    font-family: var(--font_family_Semibold);
  }

  .click_secondary_line {
    color: var(--dusk);
    font-family: var(--font_family_Bold);
  }

  @media only screen and (max-width: 600px) {
    .main {
      min-width: 100%;
    }
  }

  .img_active {
    width: 15px;
    height: 11px;
  }
}

.secondary_line_dialog {
  background-color: var(--white);
  width: 550px !important;
  padding: 26px !important;

  :global(.MuiTypography-root) {
    top: 0;
    left: 0;
    line-height: 1;
    height: 24px;
  }

  :global(.MuiSvgIcon-root) {
    font-size: 24px !important;
    opacity: 0.8 !important;
  }

  .secondary_email_err {
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    line-height: 1;
    height: fit-content;
    margin: 5px 0 15px 0;
    text-align: center;
    color: var(--danger);
  }
}

.secondary_line_dialog_email {
  background-color: var(--white);
  width: 500px !important;
  padding: 25px !important;

  :global(.MuiSvgIcon-root) {
    font-size: 20px !important;
    opacity: 0.8 !important;
  }

  :global(.MuiTypography-root) {
    line-height: 1;
  }

  .inner_container {
    padding: 36px 48px 16px 48px;

    @media screen and (max-width: 450px) {
      padding: 36px 10px 16px 10px;
    }

    .email_textfield_cust {
      border-bottom: none;
      background-color: var(--white);

      :global(.Mui-disabled) {
        background-color: var(--white) !important;
        color: var(--text_color) !important;
        -webkit-text-fill-color: var(--text_color);
      }

      :global(.MuiInput-underline) {
        padding: 15px;
        font-family: var(--font_family_Medium);
        font-size: 14px;
        color: var(--text_color);
        margin-top: 0;

        &::before {
          border-bottom: 1px solid var(--select_border);
        }

        &::after {
          border-bottom: 1px solid var(--select_border);
        }

        &:global(.Mui-error):after {
          border-bottom: 1px solid var(--danger);
        }

        :global(.MuiInputBase-input) {
          padding: 0;
        }

        :global(.MuiButtonBase-root) {
          padding: 0;

          :global(.MuiSvgIcon-root) {
            font-size: 24px !important;
            opacity: 1 !important;
            color: var(--black) !important;
          }
        }
      }

      :global(.MuiInputLabel-standard) {
        font-family: var(--font_family_Medium);
        padding-left: 15px;
        font-size: 16px;
        color: var(--grey_shade);
        top: -5px;

        &:global(.Mui-error) {
          color: var(--danger);
        }
      }

      :global(.MuiInputLabel-shrink) {
        display: none;
      }
    }

    .line_details {
      font-family: var(--font_family_Medium);
      font-size: 14px;
      font-weight: var(--font_weight_1);
      line-height: 1.43;
      color: var(--text_color_3);
    }
  }
}

.note_txt {
  height: 20px;
  color: var(--text_color);
  font-family: var(--font_family_Semibold) !important;
  font-size: 14px !important;
  font-weight: var(--font_weight_2) !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
  text-align: center;
}