.topup_failed_dialog {
  background-color: white;
  .title {
    font-family: var(--font_family_Bold);
    border-bottom: 1px solid var(--grey_shade_2);
    width: 100%;
    font-size: 18px;
  }
  .sub_head {
    font-family: var(--font_family_Medium);
  }
  .card_num {
    color: var(--red-color_2);
    font-family: var(--font_family_Bold);
    margin-left: 10px;
  }
}
