.contact_customer {
  background-color: var(--white);
  width: 550px !important;
  padding: 25px !important;
  :global(.MuiTypography-root) {
    top: 0;
    left: 0;
  }
  :global(.MuiSvgIcon-root) {
    font-size: 15px !important;
    margin: 15px;
    opacity: 0.3rem !important;
  }
  .dialog_main {
    display: flex;
    width: 70%;
    flex-direction: column;
    align-items: center;
    .mobile_img {
      width: 25px;
      height: 45px;
      margin-bottom: 15px;
    }
    .dialog_title {
      font-size: 22px;
      text-align: center;
      color: var(--text_color);
      margin-bottom: 15px;
      padding-bottom: 0;
      font-family: var(--font_family_Bold);
    }
    .dialog_desc_text {
      margin-bottom: 15px;
      text-align: center;
      letter-spacing: 0;
      font-family: var(--font_family_Medium);
    }
    .input_field {
      margin-bottom: 20px;
    }
  }
}
