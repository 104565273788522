.ns_link {
    color: var(--primary_color);
    font-family: var(--font_family) !important;
    cursor: pointer;
    text-decoration: none;
}

.disable_btn {
    cursor: not-allowed !important;
    opacity: 0.5;
}