.modal_main_container {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  .section {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 10px;
  }

  .modal_data_text {
    font-family: var(--font_family_Medium);
    font-size: 14px;
    font-weight: var(--font_weight_0);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-items: center;
    color: var(--text_color_8);
  }

  .modal_country_name {
    font-family: var(--font_family_Bold);
    font-size: 24px;
    font-weight: var(--font_weight_4);
    color: var(--black);
    text-transform: capitalize;
    line-height: 36px;
  }

  .modal_sub_title {
    font-family: var(--font_family_Medium);
    font-size: 18px;
    font-weight: var(--font_weight_2);
    color: var(--lite_black_3);
    line-height: 26px;
    border-bottom: 1px solid rgba(90, 90, 90, 0.2);
    margin: 0px 3%;
  }
  .modal_status_container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    .modal_status_daily_container,
    .modal_status_weekly_container {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 20px 15px;
      border: 1px solid rgba(227, 230, 231, 1);
      border-radius: 4px;
      .title {
        font-size: 18px;
        line-height: 27px;
        font-weight: var(--font_weight_1);
        font-family: var(--font_family_Medium);
        color: var(--black);
      }
      .price {
        font-size: 24px;
        line-height: 36px;
        font-weight: var(--font_weight_4);
        font-family: var(--font_family_Bold);
        color: var(--black);
      }
      .border {
        height: 3px;
        background-color: var(--primary_color);
        width: 45px;
        border-radius: 100px;
      }
    }
    .modal_status_weekly_container {
      height: 100%;
      border: 1px solid rgba(227, 230, 231, 1);
    }
  }
  .calling_from_us_container {
    display: flex;
    align-items: center;
    .calling_sub_title {
      font-size: 18px;
      line-height: 26px;
      font-weight: var(--font_weight_1);
      color: var(--text_color);
      font-family: var(--font_family_Medium);
      min-width: 100px;
    }
    .calling_title {
      font-size: 16px;
      line-height: 24px;
      font-weight: var(--font_weight_0);
      color: var(--text_color);
      font-family: var(--font_family_Regular);
      padding-left: 15px;
      min-width: 75px;
      text-align: center;
    }
  }
  .footer {
    width: 65%;
    margin: auto;
    font-size: 14px;
    font-family: var(--font_family_Medium);
    font-weight: var(--font_weight_0);
  }
}
