.checkout_left {
  padding-bottom: 5px;
  .billing_content {
    padding: 10px 35px;
    @media (max-width: 600px) {
      padding: 0px 20px 20px !important;
    }
  }
  .left_sec_heading {
    display: flex;
    align-items: center;
    font-family: var(--font_family_Bold) !important;
    font-size: 20px;
    font-weight: var(--font_weight_4);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    // margin-top: 20px;
    // margin-bottom: 15px;
    color: var(--black);
    padding: 20px 0px 15px 30px;
    @media (max-width: 600px) {
      padding: 15px 20px;
    }
    img {
      margin-right: 10px;
    }
    div {
      font-family: inherit !important;
    }
    .email_id_text {
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        overflow: visible;
      }
    }
  }
  .line_checkout {
    border-top: solid 1px #f3f3f3;
    margin: 15px 0;
    margin-left: 0px 30px;
    @media (max-width: 1024px) {
      margin: 15px;
      margin-left: 30px;
    }
  }
  .bill_content_title {
    font-family: var(--font_family_Semibold);
    font-size: 18px;
    font-weight: var(--font_weight_2);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--title-color_1);
    margin: 0 0 20px 0;
    @media (max-width: 600px) {
      padding: 0px 0px !important;
    }
    .bill_content_titlee {
      font-family: var(--font_family_Semibold);
      font-size: 18px;
      font-weight: var(--font_weight_2);
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--title-color_1);
      margin: 0 0 20px 0;
    }
  }
  .bill_content_title1 {
    font-family: var(--font_family_Medium);
    font-size: 18px;
    font-weight: var(--font_weight_1);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--title-color_1);
    margin: 0 0 15px 0;
    @media (max-width: 600px) {
      padding: 0px 20px !important;
    }
  }
  .bill_content_wrapper {
    @media (max-width: 600px) {
      padding: 0px 10px !important;
    }
  }
  .bill_content_subtext {
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    font-weight: var(--font_weight_2);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary_brand_color_light);
  }
  .text_fields_main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .text_fields_div {
      width: 48%;
      margin-bottom: 20px;
    }
    @media (max-width: 600px) {
      flex-direction: column;
      .text_fields_div {
        width: 100%;
      }
    }
    .account_labels {
      font-family: var(--font_family_Semibold);
      font-size: 15px;
      font-weight: var(--font_weight_2);
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--warm_grey);
      margin: 0 0 10px 0;
    }
    .error_message {
      color: var(--danger);
      font-size: 12px;
      font-family: var(--font_family_Medium);
      line-height: 24px;
      text-align: left;
      margin: 5px 5px 0 5px;
    }
  }
  .note_div {
    font-family: var(--font_family_Semibold);
    color: var(--text_color);
    font-size: 14px;
    margin: 0px 0 20px;
    .note_head {
      font-family: var(--font_family_Bold);
      color: var(--primary_brand_color);
      font-size: inherit;
    }
  }
  .shipping_options {
    margin: 20px 0;
  }
  .ship_cont {
    @media (max-width: 600px) {
      padding: 0px 20px !important;
    }
  }
  .radio_option_note {
    margin: 10px 0 20px;
  }
  .same_billing_address {
    display: flex;
    align-items: center;
    margin: 0 0 20px;
    .text {
      font-family: var(--font_family_Medium);
      font-size: 16px;
      font-weight: var(--font_weight_1);
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--text_color);
      margin-top: 2px;
    }
  }
  .billing_proceed {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .disable_section {
    pointer-events: none;
    opacity: 0.4;
  }
  .zipcode_div {
    border: 1px solid var(--zip_text);
    border-radius: 8px;
    padding: 13px 0px 13px 18px;
    font-size: 14px;
    background-color: var(--zip_background_color);
    color: var(--zip_text);
    margin-left: 35px;
    margin-right: 35px;
  }
  @media screen and (max-width: 600px) {
    .zipcode_div {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
