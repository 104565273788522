.main_container {
  border: 1px solid black;
  border-radius: 4px;
  border: 1px solid rgba(90, 90, 90, 0.3);
  .rows {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    font-size: 16px;
    &:nth-child(5) {
      color: var(--primary_color);
    }
  }
  .displayPack {
    font-family: var(--font_family_SemiBold) !important;
  }

  .auto_renual {
    padding: 20px 30px;
    background: var(--lightgrey);
    .toggleText {
      font-family: var(--font_family_SemiBold) !important;
    }
  }

  .main_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 42px;
    font-size: 16px;
    padding: 40px 30px;
    width: 60%;
    margin: auto;
    align-items: stretch;

    .changeCard {
      display: flex;
      align-items: center;
      gap: 1px;
      color: var(--primary_color);
      font-family: var(--font_family_SemiBold) !important;
    }
  }

  .card {
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    align-items: center;
  }
}

.fullName {
  color: var(--text_color);
  border: 1px solid var(--primary_color);
  width: fit-content;
  font-size: 16px;
  padding: 20px;
  border-radius: 8px;
  font-family: var(--font_family_Medium) !important;
  font-weight: var(--font_weight_4);
  background: var(--switch_tab_bg_color);
}
