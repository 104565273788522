.heading_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0px 30px 0px;
  .arrow_icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    z-index: 100;
    left: 0;
    top: 190px;
    position: absolute;
    @media (max-width: 600px) {
      top: 135px;
    }
  }
}
.contentContainer {
  border-radius: 10px;
  background-color: var(--white);
  text-align: center;
  .title_main {
    font-size: 24px;
    font-family: var(--font_family_Bold);
    font-weight: var(--font_weight_4);
    color: var(--black);
    line-height: 36px;
    @media (max-width: 600px) {
      font-size: 24px;
      line-height: 30px;
    }
  }
  .subtitle_main {
    font-size: 16px;
    font-family: var(--font_family_Semibold);
    font-weight: var(--font_weight_2);
    color: var(--text_color);
    line-height: 26px;
    margin-top: 22px;
    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 28px;
    }
  }
  .emailContainer {
    text-align: center;
    height: 96px;
    border-radius: 10px;
    background: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    .emailBox {
      max-width: 439px;
      border-radius: 24px;
      border: 1px solid var(--border_color);
      background: var(--white);
      display: flex;
      max-height: 96px;
      justify-content: center;
      padding: 20px 15px;
      align-items: flex-start;
      flex-direction: column;
      .email {
        font-size: 16px;
        font-family: var(--font_family_Regular);
        font-weight: var(--font_weight_0);
        color: var(--button_secondary_color);
        line-height: 28px;
      }
      .helper {
        font-size: 14px;
        font-weight: var(--font_weight_1);
        font-family: var(--font_family_Medium);
        line-height: 28px;
        text-align: start;
        text-decoration: underline;
        cursor: pointer;
        color: var(--primary_color);
      }
    }
  }
  .noteContainer {
    display: flex;
    width: 60%;
    margin: 0 auto 30px;
    text-align: left;
    line-height: 28px;
    @media (max-width: 600px) {
      width: 95%;
    }
    .note {
      font-size: 14px;
      font-family: var(--font_family_Regular);
      font-weight: var(--font_weight_0);
      color: var(--text_color);
      line-height: 20px;
      text-align: center;
    }
  }
}
