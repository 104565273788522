.title {
	color: var(--text_color);
	font-family: var(--font_family_Semibold) !important;
	font-weight: var(--font_weight_2) !important;
	line-height: 1 !important;
}
.description {
	color: var(--text_color);
	font-family: var(--font_family_Medium);
	text-align: left;
}
.sub_heading{
	color: var(--text_color_2);
    font-family: var(--font_family_Semibold) !important;
    font-size: 14px !important;
		font-weight: var(--font_weight_2) !important;
}
.limit_div {
	font-family: var(--font_family_Medium) !important;
	font-weight: var(--font_weight_0) !important;
	display: flex;
	font-size: 12px !important;
	justify-content: flex-end;
	margin: 10px 0 !important;
}
.progress_bar_div {
	:global(.MuiLinearProgress-bar) {
		background-color: var(--primary_color) !important;
	}
}