.line_count_button {
  border-radius: 5px;
  border: 1px solid var(--primary_color);
  background-color: var(--white);
  height: 1.3em !important;
  width: 1.3em !important;
  font-size: 16px !important;
  color: var(--primary_color);
  cursor: pointer;
}
.button_disable {
  border: 1px solid var(--primary_color);
  background-color: var(--white);
  color: var(--primary_color);
  opacity: 0.4;
  cursor: not-allowed;
}
.inputValue {
  font-size: 14px !important;
}
