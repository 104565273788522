
.check_coverage_outer {
  // height: 350px;
  @if(var(--home_check_coverage_bg_color)) {
    background-color: var(--home_check_coverage_bg_color);
  } @else {
    background-color: var(--primary_background);
  }
  // padding: 25px 50px;
  // @media (max-width: 600px) {
  //   height: 500px;
  // }
  // @media (min-width: 600px) and (max-width: 1024px) {
  //   height: 330px;
  // }
  :global(.TextField .MuiFilledInput-root) {
    border: 1px solid var(--white);
   
  }
  :global(.MuiContainer-root){
    width: 400px !important;
    @media (max-width: 600px) {
      width: 279px !important;
    }
  }
  :global(.TextField .MuiFilledInput-input) {
    background: var(--home_check_coverage_bg_color);
    color: var(--white);
  }
  :global(.TextField .MuiInputLabel-filled) {
    color: var(--white);
    @media (max-width: 600px) {
      overflow: inherit;
    }
  }
  :global(.MuiButton-startIcon) {
    margin-left: 8px !important;
    :global(.MuiSvgIcon-root) {
      font-size: 43px !important;
      font-weight: var(--font_weight_0);
    }
  }

  .coverage_check_heading {
    font-size: 30px;
    font-weight: var(--font_weight_2);
    line-height: 1.8;
    color: var(--white);
    font-family: var(--font_family_Demibold);
    @media (max-width: 1200px) {
      font-size: 22px;
    }
  }
  .coverage_check_subheading {
    font-size: 26px;
    font-weight: var(--font_weight_1);
    line-height: 1.73;
    color: var(--white);
    font-family: var(--font_family_Medium);
    @media (max-width: 1200px) {
      font-size: 18px;
    }
  }
  .address_holder {
    display: flex;
    :global(.MuiButton-root) {
      background-color: var(--home_check_coverage_bg_color);
      color: var(--white);
      border: 1px solid var(--white);
      height: 55px;
      margin-left: 10px;
    }
    .submit_btn {
      width: 32px;
      @media (max-width: 600px) {
        width: 45px;
        span {
          margin: 0px !important;
        }
      }
    }
  }
  .coverage_final_text {
    font-family: var(--font_family_Bold);
    font-size: 18px;
    font-weight: var(--font_weight_1);
    line-height: 1.73;
    color: var(--white);
    display: flex;
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
  .coverage_buttons {
    :global(.MuiButton-text) {
      color: var(--white);
      font-family: var(--font_family_Bold);
      font-size: 14px;
      font-weight: var(--font_weight_2);
      line-height: normal;
      margin-right: 20px;
      width: 200px;
      padding: 10px 0px;
      @media (min-width: 601px) and (max-width: 803px) {
        margin-top: 10px;
      }
      @media (max-width: 600px) {
        width: 100%;
        margin-top: 15px;
      }
    }
  
    Button {
      border: 1px solid var(--white);
    }
  }

  .coverage_error {
    color: var(--danger);
  }
  .signal_note1{
    margin: 0px 0px 16px 0px;
  }
}
