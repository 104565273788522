.title {
  font-family: var(--font_family_Medium) !important;
  margin: 0 37px 10px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  line-height: 1.77 !important;
  text-align: center !important;
  color: var(--text_color_1) !important;
}

.label_txt {
  font-family: var(--font_family_Medium) !important;
  font-size: 16px !important;
  font-weight: bold !important;
}
.esim_tooltip_container {
  :global(.MuiTooltip-tooltip) {
    padding: 9px 14px !important;
  }
}
.info_icon {
  height: 12px;
  width: 13px;
  object-fit: contain;
  cursor: pointer;
  margin-right: 9px;
  margin-top: 14px;
  color: 'black !important';

  @media (max-width: 600px) {
    top: 200px;
    right: 13px;
  }
}
.btn_container {
  margin-top: 30px;
  text-align: center !important;
}

.note_style {
  font-family: var(--font_family_Bold) !important;
  // font-size: 12px !important;
  font-weight: var(--font_weight_1);
  line-height: 18.96px;
}

.tooltip_style {
  width: 380px;
  color: var(--white) !important;
  :global(.MuiTooltip-tooltip) {
    font-family: var(--font_family_Medium) !important;
    color: var(--white);
    background-color: var(--black) !important;
    padding: 15px !important;
    border: none !important;
    a > p {
      font-family: var(--font_family_Medium) !important;
      font-size: 14px;
    }
  }
  :global(.MuiTooltip-arrow::before) {
    background-color: var(--black) !important;
  }
}
