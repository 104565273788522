.service_main {
  border-radius: 10px;

  font-family: var(--font_family_Medium) !important;
  text-align: center;

  padding: 40px 30px;
  background-color: var(--white);

  .service_header_text {
    font-family: var(--font_family_Bold) !important;
    font-size: 24px;
    line-height: 36px;
    font-weight: var(--font_weight_4);
    color: var(--black);
    margin-bottom: 15px;
    padding: 5px;
    @media screen and (max-width: 600px) {
      font-size: 18px;
      line-height: 30px;
    }
    .service_Desc_text {
      font-family: var(--font_family_Regular) !important;
      font-weight: var(--font_weight_0);
      font-size: 16px;
      line-height: 28px;
      color: var(--text_color);
    }
  }
  .btn_cmp {
    font-size: 16px !important;
    line-height: 20px !important;
    font-weight: var(--font_weight_2) !important;
    font-family: var(--font_family_Semibold) !important;
    min-width: unset !important;
    min-height: 2.8125em !important;
    height: auto !important;
    @media screen and (max-width: 600px) {
      line-height: 23px !important;
    }
  }
}
