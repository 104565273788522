.home_bestfit_block {
	background: var(--white_1);

	.home_bestfit_desc {
		font-weight: var(--font_weight_1);
		font-family: var(--font_family_Semibold);
		color: var(--text_color);
		font-size: 26px;
		margin-right: 8px;

		span {
			font-family: var(--font_family_Bold);
		}
	
		@media (max-width: 600px) {
			font-size: 22px;
			text-align: center;
		}
	}
}
