.porting_dialog {
  background-color: var(--white);
  width: 550px !important;
  padding: 25px !important;
  :global(.MuiTypography-root) {
    top: 0;
    left: 0;
  }
  :global(.MuiSvgIcon-root) {
    font-size: 15px !important;
    margin: 15px;
    opacity: 0.3rem !important;
  }
  .dialog_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    .mobile_img {
      width: 25px;
    }
    .dialog_title {
      font-size: 22px;
      text-align: center;
      color: var(--text_color);
      margin-bottom: 15px;
      padding-bottom: 0;
      font-family: var(--font_family_Bold);
    }
    .dialog_desc_text {
      margin-bottom: 25px;
      text-align: center;
      letter-spacing: 0;
      font-family: var(--font_family_Medium);
    }
    .input_field {
      margin-bottom: 25px;
    }
    .link {
      font-family: var(--font_family_Bold);
      margin: -15px 0 20px 0;
      cursor: pointer;
    }
  }
  .porting_failure {
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    text-align: center;
    color: var(--red-color_3);
    padding-top: 16px;
  }
}
