.help_modal_main {
  background-color: var(--white);
  width: 500px !important;
  padding: 25px !important;
  :global(.MuiTypography-root) {
    top: 0;
    left: 0;
  }
  :global(.MuiSvgIcon-root) {
    font-size: 15px !important;
    margin: 10px;
    opacity: 0.3rem !important;
  }
  .dialog_main {
    width: 100%;
    .dialog_activation_modal_heading {
      font-family: var(--font_family_Bold);
      font-size: 22px;
      line-height: 33px;
      text-align: center;
      color: var(--text_color);
      margin-bottom: 15px;
      padding-bottom: 0;
    }
    .dialog_activation_modal_divider {
      width: 75px;
      height: 5px;
      margin: 0 auto;
      border-radius: 2px;
      background-color: var(--primary_color) !important;
    }
    .support_center_row {
      display: flex;
      padding-top: 20px;
      .support_individual_row {
        padding-right: 15px;
        padding-left: 15px;
        display: flex;
        align-items: center;
        .sub_title_img {
          width: 20px;
          height: 20px;
        }
        .subheading {
          .dialog_sub_title_one {
            font-size: 14px;
            cursor: pointer;
            color: var(--primary_color);
            font-family: var(--font_family_Semibold);
            margin-left: 10px;
            line-height: 21px;
            font-weight: 400;
          }
          .dialog_sub_title_two {
            font-family: var(--font_family_Semibold);
            font-size: 14px;
            color: var(--text_color);
            margin-left: 10px;
            line-height: 21px;
            font-weight: 400;
          }
        }
      }
    }
    .dialog_support_bottom_row {
      padding-right: 15px;
      margin: 20px 0 5px 15px;
      display: flex;
      text-align: center;
      .sub_title_img {
        width: 20px;
        height: 20px;
      }
    }
    .mail_color {
      color: var(--primary_color);
      font-family: var(--font_family_Semibold);
      font-size: 14px;
      margin-left: 10px;
      font-weight: 400;
      text-decoration: none;
    }
  }

  .back_arrow {
    align-items: flex-start !important;
    padding: 5px 0 0 20px;
    cursor: pointer;

    .arrow_icon {
      width: 18px;
      height: 18px;
      margin: 0 5px -2px 0;
    }
  }
  @media (min-width: 900px) {
    width: 640px !important;
  }
}
