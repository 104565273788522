.user_name_text {
  position: absolute;
  bottom: 6%;
  color: var(--white);
  font-family: var(--font_family_Bold);
  font-weight: var(--font_weight_4);
  cursor: pointer;
}

.dialog_container{
    font-family: var(--font_family);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: var(--black);
    .user_title{
    padding-top: 25px;
    font-size: 22px;
    font-weight: var(--font_weight_4);
    line-height: 1;
    }
    .user_description{
        font-size: 14px;
        padding: 20px 30px;
    }
}