.activation_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  width: fit-content;

  .activation_bottom_text1 {
    display: flex;
    justify-content: center;
    font-size: 14px;
    padding-bottom: 0;
    color: var(--text_color_2);
  }

  .activation_version_container {
    display: flex;

    .os_img {
      margin-right: 1rem;
    }

    .os_text_1 {
      font-family: var(--font_family_Semibold);
      font-size: 14px;
      color: var(--text_color_2);
    }

    .os_text_2 {
      font-family: var(--font_family_Medium);
      font-size: 14px;
      color: var(--text_color);
    }
  }
}
