.content_carousel {
  width: 100%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 2%;
  @media (max-width: 600px) {
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 80%;
  }
  @media (min-width: 768px) and (max-width: 900px) {
    top: 50%;
    width: 60%;
  }
  @media (min-width: 600px) and (max-width: 768px) {
    width: 50%;
  }
  @media (min-width: 900px) and (max-width: 1301px) {
    width: 47%;
  }

  .ticks_main_div {
    margin-bottom: 25px;
    @media (max-width: 600px) {
      margin: 20px 0;
    }
  }
  .list_item_txt {
    margin-left: 10px;
    color: var(--text_color);
    line-height: 20px;
    font-family: var(--main_banner_sub_title_ff) !important;
    font-size: var(--main_banner_sub_title_fs_desktop) !important;
    font-weight: var(--main_banner_sub_title_fw) !important;
  
    @media (max-width: 600px) {
      font-size: var(--main_banner_sub_title_fs_mobile) !important;
    }
  
    @media (767px < width < 1301px) {
      font-size: var(--main_banner_sub_title_fs_ipad) !important;
    }
  }
  .button2 {
    margin-top: 25px;
    font-size: 16px;
    font-weight: var(--font_weight_4);
    color: white;
    font-family: var(--font_family_Bold);
  }
}
