.terms_page_content {
    .terms_title {
      font-family: var(--font_family_Semibold);
      font-size: 28px;
      font-weight: var(--font_weight_2);
      line-height: 1.43;
      color: var(--text_color);
      text-align: center;
      margin-bottom: 8px;
    }
    .terms_headings {
      font-family: var(--font_family_Bold);
      font-size: 15px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: normal;
      color: var(--text_color_3);
    }
    .terms_para {
      font-family: var(--font_family_Medium);
      font-size: 15px;
      line-height: 2.27;
      font-weight: 400;
      color: var(--text_color_3);
      text-align: left;
      margin: 20px 0px;
    }
    a {
      text-decoration: none;
      color: var(--dusk);
      font-weight: bold;
    }
    p {
      font-family: var(--font_family_Medium);
    }
    strong{
      font-family: var(--font_family_Bold);
    }
    .caps_para {
      font-family: var(--font_family_Medium);
      font-size: 16px;
      font-weight: var(--font_weight_4);
      line-height: 1.7;
      letter-spacing: normal;
      color: var(--text_color_3);
      text-align: left;
    }
  }
  .terms_line {
    width: 80px;
    height: 5px;
    border-radius: 2.5px;
    background-color: var(--primary_brand_color);
    margin: 10px auto 50px auto;
  }
  .terms_page_content:nth-child(2),
  .terms_page_content:nth-child(3) {
    padding-top: 0px;
    margin-top: 0px;
  }