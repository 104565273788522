
  .letter_grid_container {
    border-radius: 5px;
    background-color: var(--white_1);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    display: flex;
    justify-content: center;
    text-align: center;
    .letter_text_grid {
      margin-bottom: 25px;
      display: flex;
      flex-direction: column;
      font-family: var(--font_family_Bold);
      .letter_title {
        font-weight: var(--font_weight_4);
      }
      .letter_title_line {
        margin: 20px 0 25px;
        width: 80px;
        height: 5px;
        border-radius: 2.5px;
        background-color: var(--divider_color);
      }
      .letter_description{
        line-height: 1.6;
        font-size: 15px;
        font-weight: var(--h3_font_weight);
        font-family: var(--font_family_Semibold);
      }
    }
    .ceo_image_grid{
      display: flex;
      flex-direction: column;
      justify-content: center;
      .ceo_name{
        font-size: 18px;
        font-weight: var(--font_weight_2);
        font-family: var(--font_family_Bold);
        padding-top: 10px;
        text-align: center;
      }
    }
  }
