.modal_main_container {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  .section {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 10px;
  }

  .modal_data_text {
    font-family: var(--font_family_Medium);
    font-size: 14px;
    font-weight: var(--font_weight_0);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-items: center;
    color: var(--text_color_8);
  }

  .modal_country_name {
    font-family: var(--font_family_Bold);
    font-size: 24px;
    font-weight: var(--font_weight_4);
    text-transform: capitalize;
  }

  .modal_sub_title {
    font-family: var(--font_family_Medium);
    font-size: 14px;
    font-weight: var(--font_weight_2);
    border: 1px solid var(--lightnavy);
    padding: 10px 0;
  }

  .footer {
    width: 65%;
    margin: auto;
    font-size: 14px;
    font-family: var(--font_family_Medium);
    font-weight: var(--font_weight_0);
  }
}
