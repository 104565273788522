.profile_main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .form_label {
    font-family: var(--font_family_Medium);
    font-size: 16px;
    font-weight: 400;
  }
  .form_Second_label {
    color: var(--primary_color);
    font-family: var(--font_family_Medium);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
  .form_address {
    font-weight: 600;
    font-family: var(--font_family_Bold);
  }
  :global(.react-datepicker__input-container) {
    border-radius: 6px;
    border: solid 1px var(--date_picker_inp);
    cursor: pointer;
    input {
      border: none;
      outline: none;
      width: calc(100% - 10px - 6px);
      padding: 11px 5px;
      margin: 3px;
      font-family: var(--font_family_Medium);
    }
  }
  :global(.react-datepicker__triangle) {
    transform: translate3d(200px, 0px, 0px) !important;
  }
  .profile_row {
    margin-top: 30px;
  }
}

.profile_email_main {
  display: flex;
  .profile_email_block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .profile_email_head {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: center;
      padding: 40px 20px;
      .back_button {
        cursor: pointer;
        z-index: 100;
        .arrow_icon {
          left: 20px;
          position: absolute;
          width: 18px;
          height: 18px;
          margin: 0 5px 5px 0;
        }
      }
      .profile_email_changeHead {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font_family_Bold);
        font-size: 22px;
        font-weight: var(--font_weight_4);
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: var(--text_color);
        text-align: center !important;
      }
    }
    .profile_email_content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .profile_email_text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .profile_email_note {
          font-size: 14px;
          text-align: center;
          font-family: var(--font_family_Medium);
          color: var(--grey_shade);
          margin: 20px 0;
        }
      }
    }
  }
}
