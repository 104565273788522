.step1_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    .heading_container {
      display: flex;
      justify-content: center;
      align-items: center;
      .arrow_icon {
        cursor: pointer;
        width: 24px;
        height: 24px;
        z-index: 100;
        left: 0;
        position: absolute;
      }
  
      .title_main {
        font-family: var(--font_family_Bold);
        font-size: 32px;
        font-weight: var(--font_weight_4);
        line-height: 1;
        text-align: center;
        color: var(--primary_color);
      }
    }
    .container {
      width: 50%;
      .subtitle_main {
        margin-bottom: 15px;
        font-family: var(--font_family_Bold);
      }
      .bold_title {
        font-size: 40px;
        font-weight: var(--font_weight_4);
        line-height: 50px;
        font-family: var(--font_family_Bold);
        @media (max-width: 600px) {
          font-size: 24px;
          line-height: 30px;
        }
      }
      .bold_subtitle {
        font-size: 18px;
        font-family: var(--font_family_Bold);
        font-weight: var(--font_weight_4);
        line-height: 30px;
        margin: 30px auto 30px auto;
        @media (max-width: 600px) {
          font-size: 16px;
          line-height: 28px;
        }
      }
  
      .ship_page_line {
        margin: 20px auto 25px;
        width: 80px;
        height: 5px;
        border-radius: 2.5px;
        background-color: var(--primary_brand_color);
      }
  
      .text_fields_main {
        width: 100%;
        display: flex;
        justify-content: space-between;
  
        .text_fields_div {
          width: 48%;
          margin-bottom: 20px;
        }
  
        @media (max-width: 600px) {
          flex-direction: column;
  
          .text_fields_div {
            width: 100%;
          }
        }
      }
    }
  }
  