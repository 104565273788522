.where_we_give_main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .where_we_give_title_container {
    .where_we_give_title {
      line-height: 40px;
    }
    .where_we_give_line {
      width: 80px;
      height: 5px;
      border-radius: 2.5px;
      background-color: var(--primary_brand_color);
    }
  }
  .map_component {
    width: 978px;
    path:focus {
      outline: none;
    }
    .circleAnimation {
      stroke: var(--lavender);
      stroke-width: 2px;
      stroke-opacity: 1;
      fill: var(--lavender);
    }
    .pulse {
      fill: var(--white);
      fill-opacity: 0;
      transform-origin: 50% 50%;
      animation-duration: 2s;
      animation-name: pulse;
      animation-iteration-count: infinite;
      cursor: pointer;
    }
    .outerCircle {
      stroke: var(--lavender);
      stroke-width: 2px;
      fill: transparent;
    }
    .innerCircle {
      fill: var(--lavender);
    }

    @keyframes pulse {
      from {
        stroke-width: 3px;
        stroke-opacity: 2;
        transform: scale(0.3);
      }
      to {
        stroke-width: 0;
        stroke-opacity: 0;
        transform: scale(1.5);
      }
    }
    .markers_div:before {
      border: 2px solid var(--lavender);
      border-radius: 30px;
      height: inherit;
      width: inherit;
      top: -2px;
      left: -2px;
      position: absolute;
      opacity: 0;
      box-sizing: content-box;
    }
    @media (max-width: 768px) {
      width: calc(100% - 50px);
    }
    @media (min-width: 768px) and (max-width: 1200px) {
      width: calc(100% - 70px);
      max-width: 978px;
    }
  }
}
.tooltip_main_container {
  width: 442px;
  @media (max-width: 600px) {
    width: calc(100% - 20px) !important;
  }
  .close_icon {
    color: var(--black);
    opacity: 0.5;
    width: 15px;
    height: 15px;
    margin: 10px 0 0 10px;
    cursor: pointer;
  }
  .tooltip_content_div {
    padding: 0px 20px 20px 20px;
    .map_image_div {
      margin: 15px auto;
      .map_image {
        width: 100px;
      }
    }
    .map_location {
      font-size: 14px;
      line-height: 20px;
      font-family: var(--font_family_Medium);
      font-weight: var(--description_text_font_weight);
      color: var(-text_color_1);
    }
    .tooltip_description {
      margin: 20px 0;
      text-align: justify;
      a {
        color: var(--primary_color);
        text-decoration: none;
        font-family: var(--font_family_Bold);
        font-weight: bold;
      }
    }
  }
}
