.main_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .top_heading {
    text-align: center;
    font-size: 30px;
    font-family: var(--font_family_Medium) !important;
    display: flex;
    align-items: center;
    justify-content: center;

    .heading {
      font-size: 24px;
      line-height: 36px;
      font-family: var(--font_family_Bold) !important;
      font-weight: var(--font_weight_4);
      color: var(--black);
      @media (max-width: 600px) {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }

  .back_arrow {
    position: absolute;
    left: 90px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: var(--font_family_Medium) !important;
    font-weight: var(--font_weight_5);
    @media (max-width: 900px) {
      left: 20px;
      top: 14.5%;
    }
    @media (max-width: 600px) {
      left: 10px;
      top: 15.5%;
    }
  }

  .full_name,
  .country_label,
  .schedule,
  .selected_date {
    color: var(--text_color);
    text-align: center;
    font-size: 16px;
    font-family: var(--font_family_Medium) !important;
    font-weight: var(--font_weight_0);
    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  .selected_date {
    font-family: var(--font_family_Medium) !important;
    font-weight: var(--font_weight_0) !important;
  }
  .packName {
    padding: 16px 0px;
    border-radius: 8px 8px 0 0;
    //   background-color: var(--white);
    display: flex;
    justify-content: space-between;
    color: var(--text_color);
    font-size: 18px;
    @media (max-width: 900px) {
      width: 90%;
      margin: auto;
    }
  }
  .display_name,
  .display_name_service_unit,
  .data_cost {
    font-family: var(--font_family_Semibold) !important;
    font-weight: var(--font_weight_2);
    font-size: 18px;
    @media (max-width: 600px) {
      font-family: var(--font_family_Bold) !important;
      font-weight: var(--font_weight_4);
    }
  }
  .display_name_service_unit {
    margin-right: 5px;
  }
  .display_name,
  .display_name_service_unit,
  .data_cost {
    @media (max-width: 600px) {
      color: var(--black);
    }
  }
  .data_cost {
    margin-left: 5px;
  }

  .card_sub_container {
    border-radius: 0 12px 12px;
    // box-shadow: 2px 2px 15px 0px rgba(174, 172, 172, 0.25);
    display: flex;
    padding: 25px;
    flex-wrap: wrap;
    background-color: var(--white);
    @media (max-width: 900px) {
      width: 90%;
      margin: 0 auto;
      justify-content: space-between;
      padding: 55px;
    }
    @media (max-width: 600px) {
      width: 90%;
      margin: 0 auto;
      justify-content: space-between;
      padding: 25px;
      border-radius: 0 0 12px 12px;
    }
  }
  .card_sub_container > :first-child {
    border-right: 2.25px solid var(--plan_card_border_color);
    padding: 0px 25px 0px 0px;
    @media screen and (max-width: 600px) {
      border-right: none;
      padding: 0px 0px 25px;
      border-bottom: 2.25px solid var(--plan_card_border_color);
    }
  }

  .card_sub_container > :nth-child(2),
  .card_sub_container > :nth-child(3),
  .card_sub_container > :nth-child(4) {
    border-right: 2.25px solid var(--plan_card_border_color);
    padding: 0px 25px;
    @media screen and (max-width: 600px) {
      border-right: none;
      padding: 25px 0px;
      border-bottom: 2.25px solid var(--plan_card_border_color);
    }
  }

  .card_sub_container > :nth-child(5) {
    padding: 0px 25px;
    @media screen and (max-width: 600px) {
      padding: 25px 0px;
    }
  }

  .card_sub_container_title {
    font-size: 16px;
    color: var(--primary_color);
    font-family: var(--font_family_Regular) !important;
    font-weight: var(--font_weight_0);
    margin-right: 5px;
  }

  .card_sub_container_value {
    font-size: 14px;
    color: rgba(86, 88, 94, 1);
    font-family: var(--font_family_Medium) !important;
    font-weight: var(--font_weight_1);
  }

  .term_condition {
    font-size: 16px;
    color: var(--text_color);
    font-family: var(--font_family_Bold) !important;
    text-decoration: underline;
    font-weight: var(--font_weight_4);
    cursor: pointer;
    @media (max-width: 600px) {
      justify-content: center !important;
    }
  }
  .note {
    color: var(--text_color);
    font-size: 14px;
    font-family: var(--font_family_Medium);
    font-weight: var(--font_weight_0);
    text-align: center !important;
  }
}
.tooltip_style {
  width: 390px;
  color: var(--white) !important;
  :global(.MuiTooltip-tooltip) {
    font-family: var(--font_family_Medium) !important;
    color: var(--white);
    background-color: var(--primary_color) !important;
    padding: 15px !important;
    a > p {
      font-family: var(--font_family_Medium) !important;
      font-size: 14px;
    }
  }
  :global(.MuiTooltip-arrow::before) {
    background-color: var(--primary_color) !important;
  }
}
//   .tooltip_style {
//     width: 650px;
//     color: var(--white) !important;
//     margin-right: 100px;
//     :global(.MuiTooltip-tooltip) {
//       font-family: var(--font_family_Medium) !important;
//       color: var(--white);
//       background-color: var(--primary_color) !important;
//       padding: 15px !important;
//     }
//     :global(.MuiTooltip-arrow::before) {
//       background-color: var(--primary_color) !important;
//     }
//     @media (max-width: 600px) {
//       width: 370px;
//       margin-left: 10px !important;
//     }
//   }
