.new_num_modal {
  background-color: var(--white);
  width: 500px !important;
  padding: 25px !important;
  :global(.MuiTypography-root) {
    top: 0;
    left: 0;
  }
  :global(.MuiSvgIcon-root) {
    font-size: 15px !important;
    margin: 10px;
    opacity: 0.3rem !important;
  }
  .dialog_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    .dialog_heading {
      font-size: 24px;
      text-align: center;
      color: var(--black);
      margin-bottom: 25px;
      padding-bottom: 0;
      font-family: var(--font_family_Bold);
      @media (max-width: 600px) {
        font-size: 20px;
      }
    }
    .dialog_text {
      font-family: var(--font_family_Medium);
      font-size: 14px;
      text-align: center;
      color: var(--text_color);
      padding: 20px 0 15px 0;
      letter-spacing: normal;
    }
    .textField_limit_container {
      .limit_text {
        font-family: var(--font_family_Medium);
        font-weight: var(--font_weight_0);
        display: flex;
        font-size: 12px;
        justify-content: flex-end;
        margin: 10px 0;
      }
    }
  }
}
