.btn_container {
  width: 380px;
  height: 40px;
  margin: 40px auto 0 auto;
  border-radius: 6px;
  border: solid 1px var(--grey77-color);
  display: flex;
  cursor: pointer;
  .inactive_btn {
    width: 50%;
    text-align: center;
    color: var(--text_color);
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: var(--font_weight_2);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-family: var(--font_family_Bold) !important;
  }
  .active_btn {
    width: 50%;
    text-align: center;
    color: var(--white);
    background-color: var(--primary_color);
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: var(--font_weight_2);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-family: var(--font_family_Bold) !important;
  }
  .active_btn.right {
    border-radius: 0 6px 6px 0;
  }
  .active_btn.left {
    border-radius: 6px 0 0 6px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
}
.post_service_btn_container {
  width: 400px;
  height: 40px;
  margin: 40px auto 0 auto;
  border-radius: 22px;
  border: solid 1px var(--grey77-color);
  display: flex;
  cursor: pointer;
  .inactive_btn {
    width: 50%;
    text-align: center;
    color: var(--text_color);
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: var(--font_weight_2);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-family: var(--font_family_Bold) !important;
  }
  .active_btn {
    width: 50%;
    text-align: center;
    color: var(--white);
    background-color: var(--primary_color);
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: var(--font_weight_2);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-family: var(--font_family_Bold) !important;
  }
  .active_btn.right {
    border-radius: 0 16px 16px 0;
  }
  .active_btn.left {
    border-radius: 16px 0 0 16px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
}
.oval {
  width: 6px;
  height: 6px;
  margin: 12px 5px 12px 0;
  border-radius: 50%;
  background-color: var(--primary_brand_color);
}
.sub_heading{
  font-family: var(--font_family_Bold) !important;
  font-size: 18px !important;
  font-weight: var(--font_weight_4);
  @media (max-width: 600px) {
    font-size: 14px !important;
  }
}
.description_secondary_txt_int {
  font-family: var(--font_family_Medium) !important;
  font-size: 14px !important;
  font-weight: var(--services_font_weight);
  @media (max-width: 600px) {
    font-size: 12px !important;
  }
}
.roamingContent {
  font-size: 14px;
  margin: 0px 5px 6px 0;
  font-family: var(--font_family_Medium) !important;
  span {
    cursor: pointer;
    display: inline-block;
    font-family: var(--font_family_Semibold) !important;
    a {
      text-decoration: none;
      color: var(--primary_brand_color);
      font-family: var(--font_family_Bold);
    }
  }
}

.note_text {
  color: var(--text_color);
  font-weight: var(--font_weight_1);
  font-family: var(--font_family_Medium);
  font-size: 13px;
  max-width: 485px;
  margin-top: 20px;
  span {
    color: var(--note_danger);
    font-weight: var(--font_weight_2);
    font-family: var(--font_family_Bold);
    font-size: 13px;
  }
  strong {
      color: var(--text_color);
      font-weight: var(--font_weight_2);
      font-family: var(--font_family_Bold);
      font-size: 13px;
  }
}
