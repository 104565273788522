.activation_step2_main {
  .title {
    color: var(--text_color);
    font-family: var(--font_family_Semibold) !important;
    font-weight: var(--font_weight_2) !important;
    line-height: 1 !important;
  }

  .description {
    color: var(--text_color);
    font-family: var(--font_family_Medium);
    text-align: left;
  }

  :global(.MuiLinearProgress-bar) {
    background-color: var(--primary_color) !important;
  }

  .description_sub {
    color: var(--text_color);
    font-family: var(--font_family_Medium) !important;
    font-weight: var(--font_weight_0) !important;
    font-size: 14px !important;
    margin-bottom: 30px !important;
  }

  .sub_title {
    font-family: var(--font_family_Semibold) !important;
    color: var(--text_color_2);
    margin-bottom: 30px !important;
    font-weight: var(--font_weight_2) !important;
    font-size: 18px !important;
  }

  .number_preference {
    .limit_div {
      font-family: var(--font_family_Medium) !important;
      font-weight: var(--font_weight_0) !important;
      display: flex;
      font-size: 12px !important;
      justify-content: flex-end;
      margin: 10px 0 !important;
    }

    .sub_title {
      color: var(--text_color);
      font-family: var(--font_family_Medium) !important;
      font-weight: var(--font_weight_0) !important;
      font-size: 14px !important;
      margin: 20px 0px 15px !important;
    }

    :global(.MuiFilledInput-input) {
      font-size: 15px;
    }
  }

  .cur_carrier_leftdiv .sub_heading {
    color: var(--text_color_2);
    font-family: var(--font_family_Semibold) !important;
    font-size: 14px !important;
  }

  .final_sub_heading {
    color: var(--text_color_2);
    font-family: var(--font_family_Medium) !important;
    font-weight: var(--font_weight_0) !important;
    font-size: 14px !important;
    line-height: 21px !important;
  }

  .form_container {
    display: flex;
    flex-direction: column;

    .form_title {
      font-family: var(--font_family_Semibold);
      font-size: 14px;
      color: var(--text_color_2);
      margin-bottom: 10px;
    }

    .form_description {
      font-family: var(--font_family_Medium);
      font-size: 14px;
      color: var(--text_color);
      padding-bottom: 10px !important;
    }

    .limit_text {
      font-family: var(--font_family_Medium);
      font-weight: var(--font_weight_0);
      text-align: right;
      font-size: 12px;
      color: var(--text_color_3);
    }
  }

  .btm_title {
    font-family: var(--font_family_Semibold);
    font-weight: var(--font_weight_1);
    font-size: 14px;
    color: var(--text_color_2);
  }

  .btm_description {
    font-family: var(--font_family_Medium);
    font-size: 14px;
    color: var(--text_color);
    font-weight: var(--font_weight_0);
    letter-spacing: normal;
  }

  .chat_box_img {
    width: 24px;
    height: 25px;
  }

  .acdn_title {
    font-family: var(--font_family);
    font-weight: var(--font_weight_2);
    font-size: 14px;
    color: var(--primary_color);
  }

  .accordian_main {
    margin-bottom: 10px;
    :global(.MuiAccordionSummary-root) {
      min-height: fit-content;
      padding-top: 10px;
      :global(.MuiAccordionSummary-content) {
        margin: 0;
      }
      :global(.MuiAccordionSummary-expandIconWrapper) {
        color: var(--black);
      }
    }
    &::before {
      background-color: transparent;
    }
  }
  .pin_label {
    font-size: 12px;
    font-family: var(--font_family_Semibold);
    width: 100%;
    text-align: left;
  }
}
