.footer_t1 {
  background-color: var(--footer_background_color) !important;

  .header_text {
    font-size: 14px;
    font-weight: var(--font_weight_4);
    font-family: var(--font_family_Bold);
    color: var(--white);
  }
  @media screen and (min-width: 1020px) and (max-width: 1100px) {
    .sub_links{
      max-width: 150px;
    }
  }

  .sub_links {
    color: var(--white);
    font-family: var(--font_family_Medium);
    font-size: 14px;
    font-weight: var(--font_weight_1);
    cursor: pointer;
    width: fit-content;
  }

  .line {
    border-color: var(--footer_divider_color);
    opacity: 0.3;
  }

  .copyright_style {
    font-size: 13px;
    font-family: var(--font_family_Bold);
    font-weight: var(--font_weight_1);
    color: var(--copyright_text_color);
  }

  .sublink_hightlight {
    font-family: var(--font_family_Semibold);
    font-size: 12px;
    background-color: var(--primary_color);
    color: var(--white);
  }
  .version_indicator {
    color: var(--grey-shade9d);
    font-size: 13px;
  }
  .trustpilot_footer {
    @media (max-width: 600px) {
      position: relative;
      left: -8px;
    }
  }

  @media (max-width: 357px) {
    .mobile_button_store_main {
      margin: 0.75rem 0px;
      padding-left: 0px;
    }
    .logo_mobile_apps {
      display: flex;
      flex-direction: column;
    }
  }

  @media (min-width: 900px) and (max-width: 924px) {
    .mobile_button_ios {
      padding-left: 1px !important;
    }
    .logo_mobile_apps {
      padding-right: 8px !important;
    }
  }
  @media (min-width: 920px) and (max-width: 972px) {
    .mobile_button_ios {
      padding-left: 4px !important;
    }
  }
}
