.user_list_container {
    border-radius: 4px;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);

    .view_history_cta {
        border: 1px solid var(--lightgrey_8);
        text-align: center;
        padding: 25px 0;
        font-size: 18px;
        color: var(--primary_brand_color);
        font-weight: var(--font_weight_8);
        cursor: pointer;

        &:hover {
            background: rgba(217, 217, 217, 0.09);
        }
    }

    .user_name {
        display: flex;
        align-items: center;
        font-family: var(--font_family_Medium);
        font-size: 20px;
        font-weight: 800;

        .is_primary {
            color: var(--text_color_11);
            padding: 0 10px 0 5px;
        }
    }

    .user_num {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 18px;
        color: var(--text_color_11);

        span {
            font-family: var(--font_family_Medium);
            font-weight: var(--font_weight_1);
        }
    }

    .sim_type {
        font-size: 12px;
        background:var(--toggle_button);
        color: var(--lite_background2);
        border-radius: 2px;
        border: 1px solid var(--lite_background2);
        padding: 1px 10px;
    }
}