.title {
  color: var(--text_color);
  font-family: var(--font_family_Semibold) !important;
  font-weight: var(--font_weight_2) !important;
  line-height: 1 !important;
}
.description {
  color: var(--text_color);
  font-family: var(--font_family_Medium) !important;
  font-weight: var(--font_weight_0) !important;
}
.device_content {
  display: flex;
}
.device_details {
  display: flex;
  .sub_title,
  .sub_description {
    color: var(--text_color_2);
    font-weight: var(--font_weight_0);
    font-size: 14px;
    line-height: 21px;
    word-break: break-all;
  }
  .sub_title {
    font-family: var(--font_family_Semibold);
  }
  .sub_description {
    font-family: var(--font_family_Medium);
  }
}
.sub_heading {
  color: var(--text_color_2);
  font-family: var(--font_family_Semibold) !important;
  font-size: 14px !important;
}
.final_sub_heading {
  color: var(--text_color_2);
  font-family: var(--font_family_Medium) !important;
  font-weight: var(--font_weight_0) !important;
  font-size: 14px !important;
  line-height: 21px !important;
}
