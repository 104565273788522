.compare_result {
  padding-top: 100px;
}
.page_title {
  color: var(--text_color);
  text-align: center;
}
.page_line {
  margin: 20px auto 25px;
  width: 80px;
  height: 5px;
  border-radius: 2.5px;
  background-color: var(--divider_color);
}
.sub_title {
  font-size: 16px !important;
  font-family: var(--font_family_Regular) !important;
  color: var(--text_color) !important;
}
.separator {
  width: 60px;
  height: 5px;
  border-radius: 2.5px;
  margin: 20px auto;
}
.btn {
  width: 312px;
  margin: 0 auto;
}
.font_weight {
  font-weight: 400 !important;
}
.madal_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800;
  border-radius: 0.3rem;
  border: 1px solid var(--black);
}
