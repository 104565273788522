.container {
  display: flex;
  justify-content: center;
  .inner_container {
    display: grid;
    @media (max-width: 800px) {
      grid-template-columns: 1fr;
    }
    @media (min-width: 801px) {
      grid-template-columns: 1fr 1fr;
    }
    align-items: center;
    gap: 20px;
    .card {
      width: 100%;
      margin: 0px auto;
      // max-width: 650px;
      box-sizing: border-box;
      box-shadow: 0 2px 4px 0 var(--gray_background);
      border: 1px solid var(--white_1);
      min-height: 287px;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .address {
        font-family: var(--font_family_Medium);
        color: var(--primary_brand_color);
        cursor: pointer;
      }
      .disable_address {
        font-family: var(--font_family_Medium);
        color: var(--primary_brand_color);
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
    .toggle_group {
      &:global(.MuiToggleButtonGroup-root) {
        border-radius: 10px;
        color: var(--text_color);
        height: 40px;
      }
    }
    .wifi_disable {
      &:global(.MuiToggleButton-root) {
        text-transform: capitalize;
        font-family: var(--font_family_Medium);
        font-size: 14px;
        font-weight: var(--h4_font_weight);
      }
    }
    .toggle_btn {
      &:global(.MuiToggleButton-root) {
        text-transform: capitalize;
        font-family: var(--font_family_Medium);
        font-size: 14px;
        font-weight: var(--h4_font_weight);
        &:global(.Mui-selected) {
          background-color: var(--primary_brand_color);
          color: var(--white);
        }
      }
    }
    .reset_btn {
      &:global(.MuiToggleButton-root) {
        background-color: var(--primary_brand_color);
        width: 50%;
        color: var(--white);
        height: 40px;
        font-size: 14px;
        font-weight: var(--h4_font_weight);
        text-transform: capitalize;
      }
    }
    .divider {
      margin: 10px 0px 25px 0px;
    }
    .row1 {
      width: 70%;
    }
    .row2 {
      width: 70%;
    }
    .lables {
      font-family: var(--font_family_Medium);
      margin-bottom: 12px;
    }
  }
}
