@mixin plan_review_styles($fs, $ff, $fw, $color) {
  @if $fs {
    font-size: $fs;
  }
  @if $ff {
    font-family: $ff;
  }
  @if $fw {
    font-weight: $fw;
  }
  @if $color {
    color: $color;
  }
}
.Review_plan {
  .review_header {
    @include plan_review_styles(
      18px,
      var(--font_family_Bold),
      var(--font_weight_4),
      var(--black)
    );
  }
  .review_sub_header {
    @include plan_review_styles(
      14px,
      var(--font_family_Bold),
      var(--font_weight_2),
      var(--text_color)
    );
  }
  .offer_details {
    @include plan_review_styles(
      16px,
      var(--font_family_Redular),
      null,
      var(--primary_brand_color)
    );
    strong {
      font-family: var(--font_family_Bold);
    }
  }
  .your_line,
  .tax_and_fee,
  .welcome_kit_charge,
  .shipping_charge,
  .additional_lines,
  .primary_line_type {
    @include plan_review_styles(
      14px,
      var(--font_family_Regular),
      var(--font_weight_1),
      null
    );
    line-height: 28px;
    .primary_line_type {
      color: var(--primary_brand_color);
      font-family: var(--font_family_Semibold);
    }
  }
  .change_line_lable {
    @include plan_review_styles(
      15px,
      var(--font_family_Medium),
      null,
      var(--text_color_13)
    );
  }
  .change_pre_link {
    @include plan_review_styles(
      14px,
      var(--font_family_Semibold),
      null,
      var(--primary_color)
    );
    text-align: right;
    cursor: pointer;
  }
  .estimated_bill {
    @include plan_review_styles(
      14px,
      var(--font_family_Bold),
      var(--font_weight_2),
      null
    );
    line-height: 2.14;
  }
  .charge_per_person {
    @include plan_review_styles(
      16px,
      null,
      var(--font_weight_4),
      var(--primary_brand_color)
    );
  }
  .promo_txt_container {
    padding: 10px;
    border-radius: 5px;
    border: 1px dashed var(--text_color);
    .referal_text {
      color: var(--primary_brand_color);
      font-family: var(--font_family_Semibold);
    }
    .referal_text_light {
      font-family: var(--font_family_Medium);
      color: var(--primary_brand_color);
      font-size: 14px;
    }
  }
  .one_time_charges {
    @include plan_review_styles(
      14px,
      var(--font_family_Bold),
      null,
      var(--text_color)
    );
  }
  .total_due {
    @include plan_review_styles(
      14px,
      var(--font_family_Bold),
      var(--font_weight_4),
      null
    );
    line-height: 29px;
  }
  .note_header {
    @include plan_review_styles(
      14px,
      var(--font_family_Semibold),
      null,
      var(--primary_brand_color)
    );
  }
  .note_description {
    @include plan_review_styles(
      14px,
      var(--font_family_Regular),
      null,
      var(--text_color)
    );
  }
  .offer_details {
    @include plan_review_styles(
      16px,
      var(--font_family_Medium),
      null,
      var(--primary_brand_color)
    );
  }
}
