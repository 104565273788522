.container_style {
  box-shadow: none !important;
  .table_root .table_row:first-of-type {
    .table_cell {
      padding: unset;
      width: 25%;
    }
    .heading_style {
      padding: 30px 0px;
    }
  }
  .table_root .table_row:nth-child(2) {
    .table_cell {
      padding: 24px 0px;
    }
  }
  .table_root .table_row:last-child {
    .table_cell {
      padding: 24px 0px;
    }
  }
  .row_style {
    border: 1px solid var(--default);
    .heading_style {
      font-size: 18px;
      font-family: var(--font_family_Bold);
      color: var(--text_color);
    }
    .cell_style {
      padding: 30px 0px;
    }
    .plan_desc_style {
      font-size: 16px;
      font-family: var(--font_family_Medium);
      color: var(--text_color);
    }
    .amount2_desc_style {
      font-size: 22px;
      font-family: var(--font_family_Bold);
      color: var(--primary_brand_color);
    }
    .amount4_desc_style {
      font-family: var(--font_family_Bold);
      color: var(--primary_brand_color);
    }
    .last_cell_style {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      padding-right: 27px;
    }
    .amount1_style {
      font-size: 20px;
    }
    .amount5_desc_style {
      width: 85%;
    }
  }
}
.table_row:last-child > .table_cell:first-of-type {
  color: var(--primary_brand_color) !important;
}
.table_row:not(:first-of-type) .table_cell:last-child .heading_style {
  font-size: 20px;
}
.table_row:last-child .table_cell:last-child .heading_style {
  text-decoration: line-through;
}
.table_row:last-child {
  border: 3px solid var(--primary_brand_color) !important;
}

@media (max-width: 1200px) {
  .table_root .table_row {
    display: flex !important;
    flex-direction: column;
  }
  .table_root {
    display: flex !important;
    flex-direction: row;
  }
  .table_row {
    height: 500px !important;
  }
  .table_row .table_cell {
    height: 125px;
    width: 100% !important;
    padding: unset !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .last_cell_style {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    padding-right: 0px !important;
    div {
      text-align: center !important;
    }
  }
  .table_root .table_row:nth-child(2) {
    width: 36% !important;
  }
  .table_root .table_row:first-of-type {
    width: 28% !important;
  }
  .table_root .table_row:last-child {
    width: 36% !important;
    .table_cell {
      padding: unset !important;
      display: flex;
      align-items: center;
    }
  }
  .heading_style,
  .plan_desc_style,
  .table_row:not(:first-of-type) .table_cell:last-child .heading_style {
    font-size: 14px !important;
  }
  .amount2_desc_style,
  .amount1_style,
  .amount4_desc_style,
  .amount5_desc_style {
    font-size: 12px !important;
  }
  .table_root .table_row {
    .table_cell:last-child {
      height: 230px !important;
    }
  }
}
