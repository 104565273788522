.select_field {
  .MuiOutlinedInput-root {
    border-radius: 10px;
    background-color: var(--white);
    font-size: 14px;
    .MuiSelect-icon {
      color: var(--text_color_2);
    }
    .MuiSelect-outlined {
      padding: 15px;
      border-radius: 6px;
      height: 1.4375rem;
      display: flex;
      align-items: center;
    }
  }
  .MuiInputLabel-root {
    top: 2px;
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--text_field_border) !important;
    border-color: var(--text_field_border) !important;
  }
  .MuiInputLabel-root,
  .MuiInputLabel-root.Mui-focused,
  .MuiOutlinedInput-root {
    color: var(--text_field_text) !important;
    font-family: var(--font_family_Regular);
    // font-size: var(--text_field_font_size);
  }
  //hover styles
  .MuiOutlinedInput-root:hover fieldset {
    border-color: var(--primary_brand_color) !important;
  }
  //error styles
  .MuiInputLabel-root.Mui-error,
  .MuiFormHelperText-root.Mui-error,
  .MuiOutlinedInput-root.Mui-error .MuiSelect-icon {
    color: var(--danger) !important;
  }
  .MuiFormHelperText-root.Mui-error {
    margin: 3px 14px 0 0;
  }
  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: var(--danger) !important;
  }
  //disabled styles
  .MuiOutlinedInput-root.Mui-disabled {
    .MuiSelect-outlined {
      background-color: var(--cascading-white) !important;
      cursor: not-allowed !important;
      border-radius: 12px;
      font-family: var(--text_field_font_family);
      font-size: var(--text_field_font_size);
      padding: 16.5px 0 16.5px 15px;
    }
    fieldset {
      border-color: var(--text_field_border) !important;
    }
  }

  //underline select styles
  .MuiInput-underline {
    border-radius: 6px;
    background-color: var(--white) !important;
    font-size: 16px;
    margin-top: 0px;
    .MuiSelect-icon {
      color: var(--text_color_2);
    }
    .MuiSelect-standard {
      border-radius: 6px;
      &:focus {
        background-color: var(--white) !important;
      }
      .selected_menu_img {
        display: none !important;
      }
    }
    &::before {
      border-bottom: 1px solid var(--select_border) !important;
    }
    &::after {
      border-bottom: 1px solid var(--select_border) !important;
    }
  }
}

.menu_placeholder.Mui-disabled {
  opacity: 1 !important;
  background-color: var(--white) !important;
}

@mixin fontStyles($family, $color, $weight) {
  font-family: $family;
  color: $color;
  font-weight: $weight;
  font-size: 14px;
  line-height: 1.14;
}

.menu_item_class {
  background-color: var(--white) !important;
}

.select_option_comp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .select_opt_inner {
    .opt_name {
      margin-bottom: 5px;
      @include fontStyles(
        var(--font_family_Semibold),
        var(--text_color),
        var(--font_weight_2)
      );
    }

    .opt_num {
      @include fontStyles(
        var(--font_family_Medium),
        var(--grey_shade),
        var(--font_weight_1)
      );
    }
  }
}
.selected_menu_item {
  background-color: var(--success) !important;
  color: var(--white) !important;
  .select_opt_inner {
    .opt_name {
      margin-bottom: 5px;
      @include fontStyles(
        var(--font_family_Semibold),
        var(--white),
        var(--font_weight_2)
      );
    }

    .opt_num {
      @include fontStyles(
        var(--font_family_Medium),
        var(--white),
        var(--font_weight_1)
      );
    }
  }
  .selected_menu_img {
    color: var(--white);
  }
}
