.card_div {
  width: 100%;
  margin-top: 1.5rem;
  padding: 1rem 15px;
  border: solid 1px var(--border_color);
  border-radius: 5px;
  .inner_div {
    display: flex;
    justify-content: space-between;
    .left_div {
      display: flex;
      .image {
       
        margin: 5px 0;
        @media (max-width: 600px) {
          margin: 15px 0;
        }
      }
      .text1 {
        color: var(--text_color);
        font-family: var(--font_family_Bold) !important;
        font-size: 15px;
        margin-left: 20px;
      }
      .text2 {
        font-family: var(--font_family_Bold) !important;
        color: var(--subtect-color_1);
        font-size: 14px;
        margin-left: 20px;
      }
    }
    .right_div {
      .inner_right_div {
        display: flex;
        .code {
          font-family: var(--font_family_Bold) !important;
          border: 2px dashed var(--primary_color);
          text-align: center;
          padding: 5px;
          float: left;
          color: var(--primary_color);
          width: 100px;
          cursor: pointer;
        }
        .image {
          width: 30px;
          margin: 5px 0 5px 20px;
          cursor: pointer;
        }
      }
      @media (max-width: 600px) {
        margin-top: 1rem;
        margin-left: 3.3rem;
      }
    }
    @media (max-width: 600px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}
.share_container {
  width: 350px;
  padding: 15px;
  .share_header {
    display: flex;

    .close_icon {
      height: 15px;
      width: 15px;
      cursor: pointer;
      color: var(--black);
      opacity: 0.5;
    }
    .share_header_text {
      font-family: var(--font_family_Bold) !important;
      font-weight: var(--font_weight_4);
      font-size: 16px;
      text-align: center;
      margin: 0 auto;
    }
  }
  .icons {
    display: flex;
    justify-content: space-around;
    .individual_icon {
      margin-top: 10px;
      text-align: center;
      .text {
        margin-top: 10px;
        font-size: 14px;
        font-family: var(--font_family_Medium);
        color: var(--text_color_4);
      }
      .imei_mail_link {
        text-decoration: none;
      }
    }
  }
  @media (max-width: 600px) {
    width: 260px;
  }
}
:global(.MuiPopper-arrow::before) {
  filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 3.08)) !important;
}
:global(.css-10yqk1k-MuiPopper-root){
  z-index: 2 !important;
}