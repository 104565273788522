.coverage_modal {
  background: var(--white);
  .coverage_check_heading {
    color: var(--text_color_1);
    font-family: var(--font_family_Bold) !important;
    font-size: 22px;
  }
  .coverage_check_subheading {
    color: var(--subtext-color);
    font-family: var(--font_family_Medium);
  }
  .greenline {
    height: 5px;
    border-radius: 2.5px;
    background-color: var(--primary_brand_color);
    width: 80px !important;
  }
  .coverage_error {
    color: var(--danger);
    font-family: var(--font_family_Semibold);
  }
}

.coverage_text1 {
  font-family: var(--font_family_Semibold) !important;
}

.coverage_final_text {
    font-family: var(--font_family_Bold);
  }
  .coverage_final_text p {
    font-family: var( --font_family_Bold);
  }
.detailed_coverage {
  cursor: pointer;
  color: var(--primary_color);
  font-family: var(--font_family_Bold) !important;
}
