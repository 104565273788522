
.container{
   :global(.MuiInputLabel-root),
   :global(.MuiInputLabel-root.Mui-focused) {
      top: -10px;
      left: -10px;
      font-family: var(--font_family_Bold);
      color: var(--primary_color);
      font-weight: var(--font_weight_2);
   }
}



