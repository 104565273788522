.modal_main_container {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    .section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 10px;
    }

    .modal_data_text {
        font-family: var(--font_family_Medium);
        font-size: 16px;
        font-weight: var(--font_weight_5);
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-items: center;
    }

    .modal_country_name {
        font-family: var(--font_family_Medium);
        font-size: 22px;
        font-weight: var(--font_weight_5);
    }

    .modal_sub_title {
        font-family: var(--font_family_Medium);
        font-size: 18px;
        font-weight: var(--font_weight_1);
    }

    .footer {
        width: 80%;
        margin: auto;
        font-size: 14px;
        font-family: var(--font_family_Medium);
        font-weight: var(--font_weight_1);
    }
}
