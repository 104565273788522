.plan_features {
	.feature_header {
		background-color: var(--plan_feature_heading_bg_color);
		color: var(--white);
	}
	.plan_features_title {
		color: var(--text_color);
		font-family: var(--font_family_Medium);
		font-weight: var(--font_weight_4);
		font-size: 24px;
		margin-bottom: 9px;
		padding-bottom: 0;
		line-height: 1.2;
	}
	.plan_features_sub_title {
		color: var(--text_color);
		font-family: var(--font_family_Medium);
		font-size: 18px;
		padding-bottom: 0;
		line-height: 28px;
	}
	.acc_feature_header {
		font-family: var(--font_family_Semibold);
		font-size: 24px;
		font-weight: var(--font_weight_4);
		line-height: 1.25;
		text-align: center;
	}
	.acc_plan_features_title {
		color: var(--text_color);
		font-family: var(--font_family_Semibold);
		font-size: 24px;
		font-weight: var(--font_weight_2);
		margin-bottom: 9px;
		padding-bottom: 0;
	}
	.acc_plan_features_sub_title {
		color: var(--text_color);
		font-family: var(--font_family_Medium);
		font-size: 18px;
		text-align: center;
		padding-bottom: 0;
		line-height: 28px;
	}
}