.plan_card {
  width: 250px;
  margin: 0 15px 20px;
  background-color: var(--white);
  position: relative;
  min-width: 260px !important;
  padding: 20px 10px;
  width: 15% !important;
  border-radius: 14px !important;
}

.planData {
  align-items: center;
  margin: 5px 0;

  .planImg {
    width: 20px;
    height: 18px;
  }

  .planRate {
    font-family: var(--font_family_Bold);
    font-size: 24px;
    font-weight: var(--font_weight_4);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.04;
    letter-spacing: normal;
    text-align: center;
    color: var(--text_color);
    margin: 0 5px;
  }

  .planText {
    font-size: 15px;
    font-family: var(--font_family_Semibold);
    font-weight: var(--font_weight_2);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: var(--text_color);
  }
}

.separator {
  width: 60px;
  height: 5px;
  border-radius: 2.5px;
  margin: 20px auto;
}
