@mixin textStyle($fontSize, $fontFamily, $fontWeight, $color) {
  font-family: $fontFamily !important;
  font-size: $fontSize !important;
  font-weight: $fontWeight !important;
  color: $color !important;
}
.manage_card_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  .card_heading {
    @include textStyle(
      20px,
      var(--font_family_Bold),
      var(--font_weight_2),
      var(--text_color_13)
    );
    line-height: 1.1;
    width: 100%;
    @media (max-width: 600px) {
      font-size: 20px !important;
    }
  }
  .description {
    @include textStyle(
      14px,
      var(--font_family_Regular),
      var(--font_weight_1),
      var(--text_color_13)
    );
    line-height: 1.43;
    padding: 0 0px 0px 0px;
    // @media (max-width: 600px) {
    //   padding: 10px;
    // }
  }
  .bottom_section {
    padding: 10px 0px;
    margin: 0 auto;
    width: 100% !important;
    .save_card_text {
      margin-bottom: 10px;
      @include textStyle(
        16px,
        var(--font_family_Semibold),
        var(--font_weight_2),
        var(--text_color_13)
      );
      text-align: left !important;
    }
    .number_container {
      // border: 1px solid #F8F8F8;
      display: flex;
      justify-content: space-between;
      // align-items: center;
      margin: 0 0 15px;
      padding-top: 15px !important;
      padding: 0px 0px 10px;
      // border-top: 1px solid rgba(151, 151, 151, 0.3);
      text-align: left !important;
      .expiry {
        @include textStyle(
          14px,
          var(--font_family_Medium),
          var(--font_weight_1),
          var(--text_color)
        );
        line-height: 1.43;
        margin-bottom: 3px;
      }
      .card_number {
        @include textStyle(
          18px,
          var(--font_family_Semibold),
          var(--font_weight_2),
          var(--text_color)
        );
        line-height: 1.29;
        margin-bottom: 3px;
      }
      .update_text {
        @include textStyle(
          16px,
          var(--font_family_Semibold),
          600,
          var(--primary_color)
        );
        align-self: flex-start;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}
.left_arrow_icon {
  position: absolute;
  width: 15px;
  height: 18px;
  cursor: pointer;
  left: 0;
  margin-left: 20px;
  margin-top: 7px;
}
.add_card {
  @include textStyle(16px, var(--font_family_Bold), bold, var(--text_color_9));
}

.manage_cards_dialog {
  background-color: var(--white);
  .bottom_section {
    padding: 0;
    margin: 0;
    width: 100% !important;
  }
}
.button_container {
  display: flex;
  align-items: center;
}
.auto_pay_dialog {
  width: 600px !important;
  background-color: white;
  .dialog_heading {
    color: var(--text_color);
    font-size: 22px;
    font-family: var(--font_family_Bold);
    text-align: center;
  }
  .dialog_desc {
    font-size: 16px;
    font-family: var(--font_family_Medium);
    line-height: 1.63;
    color: var(--text_color);
  }
}
.add_new_card_ghost {
  align-self: "flex-start";
}
.add_new_card_wow {
  align-self: "center";
  border-radius: 0px !important;
}
.card_details_missing {
  font-size: 16px;
  font-weight: var(--font_weight_1);
  font-family: var(--font_family_Medium);
  text-align: left;
}
.cus_care {
  color: var(--primary_color);
  font-size: 14px;
  font-family: var(--font_family_Medium);
  font-weight: var(--font_weight_1);
  margin-left: 6px;
  cursor: pointer;
}
.auto_pay_card {
  padding: 20px 0px !important;
}
.smooth_tranc_text {
  font-family: var(--font_family_Bold) !important;
}
.update_text {
  @include textStyle(
    16px,
    var(--font_family_Semibold),
    bold,
    var(--primary_color)
  );
  align-self: flex-start;
  margin-right: 10px;
  cursor: pointer;
}
.expiry {
  @include textStyle(
    14px,
    var(--font_family_Medium),
    var(--font_weight_1),
    var(--text_color)
  );
  line-height: 1.43;
  margin-bottom: 3px;
}
