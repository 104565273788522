.otc {
  width: 600px !important;
  background-color: var(--white);
  padding: 40px !important;

  @media (max-width: 300px) {
    padding: 30px 20px 20px 20px !important;
  }

  .main_class {
    .header_txt {
      font-size: 22px;
      font-weight: var(--font_weight_4);
      text-align: center;
    }

    .row {
      margin: 0 0 25px;
      padding: 13px 29px 13px 25px;
      object-fit: contain;
      border-radius: 6px;
      box-shadow: 0 2px 4px 0 rgba(58, 133, 128, 0.06);
      background-color: var(--lightgrey);

      .row_text,
      .row_value {
        font-family: var(--font_family_Bold);
        font-weight: var(--font_weight_0);
        color: var(--text_color_4);
      }

      @media only screen and (min-width: 435px) and (max-width: 495px) {
        width: 70%;
      }

      @media only screen and (min-width: 390px) and (max-width: 435px) {
        width: 64%;
      }

      @media only screen and (min-width: 341px) and (max-width: 390px) {
        width: 72%;
      }

      @media only screen and (min-width: 308px) and (max-width: 341px) {
        width: 45%;
      }

      @media only screen and (min-width: 270px) and (max-width: 310px) {
        width: 40%;
      }

      @media only screen and (min-width: 0px) and (max-width: 270px) {
        width: 30%;
      }

      .selectotc {
        :global(.MuiSelect-select) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 20px;
          font-family: var(--font_family_Bold);
          font-weight: var(--font_weight_0);
          color: var(--text_color_4);
        }

        @media (max-width: 600px) {
          :global(.select_field) {
            width: 60%;
          }
        }
      }
    }

    .card_section {
      .card_text,
      .shipping_text,
      .address_text {
        font-size: 14px;
        font-family: var(--font_family_Bold);
        font-weight: var(--font_weight_0);
        color: var(--text_color_4);
      }

      .shipping_text {
        :global(.MuiCheckbox-root) {
          color: var(--black);
          opacity: 0.54;
        }

        :global(.MuiCheckbox-colorPrimary.Mui-checked) {
          color: #3f51b5;
          opacity: 1;
        }
      }

      .btn {
        @media (max-width: 300px) {
          p: 13px 0px;
        }
      }
    }

    .line {
      border-radius: 2.5px;
      background-color: var(--primary_brand_color) !important;
    }
  }
}
.row_label {
  font-family: var(--font_family_Regular) !important;
  font-weight: var(--font_weight_0) !important;
  font-size: 16px !important;
  line-height: 28px !important;
}

.boxContainer {
  border-radius: 10px;
  // background: var(--white);
  // box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  // width: 35%;
  margin: 0px auto;
  padding: 30px;
  @media (max-width: 600px) {
    width: 90%;
    padding: 25px 0px;
  }
}

.selectShipDetails {
  .taxesAndFees {
    display: flex;
    justify-content: space-between;
    padding: 0 15px 15px;
    font-size: 14px;
  }
  .totalAmount {
    position: absolute;
    right: 15px;
    top: 20px;
    font-family: var(--font_family_Bold);
  }
  :global(.MuiSelect-select) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
    font-family: var(--font_family_Medium);
    font-weight: var(--font_weight_1);
    color: var(--text_color);
    background: var(--checkout_background);
  }
  :global(.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline) {
    border: 1px solid #bababa !important;
  }
}
li {
  font-family: var(--font_family_Medium) !important;
  font-weight: var(--font_weight_0) !important;
  color: var(--dark-charcoal-fade);
  font-size: 14px !important;
}
