.content_carousel {
  width: 802px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 5%;
  @media (max-width: 600px) {
    top: 40%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 85%;
  }
  @media (min-width: 768px) and (max-width: 900px) {
    width: 60%;
  }
  @media (min-width: 600px) and (max-width: 768px) {
    width: 50%;
  }
  @media (min-width: 900px) and (max-width: 1301px) {
    width: 47%;
  }

  .ticks_main_div {
    // margin-bottom: 25px;
    @media (max-width: 600px) {
      // margin: 20px 0;
    }
  }
  .list_item_txt {
    margin-left: 10px;
    color: var(--dark-charcoal-fade);
    line-height: 40px;
    font-family: var(--main_banner_sub_title_ff) !important;
    font-size: "20px !important";
    font-weight: var(--main_banner_sub_title_fw) !important;

    @media (max-width: 600px) {
      font-size: "20px !important";
      line-height: 30px;
    }

    // @media (767px < width < 1301px) {
    //   font-size: var(--main_banner_sub_title_fs_ipad) !important;
    // }
  }
  .button2 {
    padding-top: 10px;
    font-size: 16px;
    font-weight: var(--font_weight_4);
    color: var(--primary_color);
    font-family: var(--font_family_Bold);
  }
}
.content_carousel_mweb {
  width: 802px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 5%;
  @media (max-width: 600px) {
    width: 100%;
    transform: unset;
    background-color: #ffffff;
    padding: 20px 0px 5px;
  }
  @media (min-width: 768px) and (max-width: 900px) {
    width: 60%;
  }
  @media (min-width: 600px) and (max-width: 768px) {
    width: 50%;
  }
  @media (min-width: 900px) and (max-width: 1301px) {
    width: 47%;
  }

  .ticks_main_div {
    // margin-bottom: 25px;
    @media (max-width: 600px) {
      // margin: 20px 0;
    }
  }
  .list_item_txt {
    margin-left: 10px;
    color: var(--dark-charcoal-fade);
    line-height: 40px;
    font-family: var(--main_banner_sub_title_ff) !important;
    font-size: "20px !important";
    font-weight: var(--main_banner_sub_title_fw) !important;

    @media (max-width: 600px) {
      font-size: "20px !important";
      line-height: 30px;
    }

    // @media (767px < width < 1301px) {
    //   font-size: var(--main_banner_sub_title_fs_ipad) !important;
    // }
  }
  .button2 {
    padding-top: 10px;
    font-size: 16px;
    font-weight: var(--font_weight_4);
    color: var(--primary_color);
    font-family: var(--font_family_Bold);
  }
}
.banner_image {
  @media screen and (min-width: 950px) and (max-width: 1300px) {
    height: 600px !important;
  }
}
.main_banner_title {
  @media (max-width: 600px) {
    font-size: 20px !important;
  }
}
