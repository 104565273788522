.main_container {
  .back_button {
    font-family: var(--font_family_Semibold);
    font-size: 20px;
    font-weight: var(--font_weight_2);
    line-height: 1.95;
    color: var(--text_color);
    width: fit-content;
    cursor: pointer;
    .arrow_icon {
      width: 18px;
      height: 18px;
      margin: 0 5px -2px 0;
    }
  }
  .sub_main_container {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    color: var(--text_color);
    .title {
      font-weight: var(--font_weight_4);
      font-family: var(--font_family_Bold);
      font-size: 24px;
      line-height: 1.95;
      .neg_image {
        margin-right: 10px;
      }
      color: var(--title-color_1);
    }
    .group_img {
      width: 250px;
      margin: 30px auto 20px;
    }
    .sub_title {
      font-weight: var(--font_weight_2);
      font-family: var(--font_family_Semibold);
      font-size: 18px;
      line-height: 2.17;
    }
    .description {
      font-family: var(--font_family_Medium);
      margin: 15px 0 30px;
      font-size: 18px;
      line-height: 1.67;
      font-weight: var(--font_weight_0);
    }
    .bottom_div {
      width: 80%;
      margin: 0 auto;
      .bottom_description {
        font-size: 16px;
        margin: 15px 0;
      }
      .video_div {
        cursor: pointer;
        display: flex;
        width: fit-content;
        .video_img {
          margin-right: 5px;
        }
        .video_content {
          font-family: var(--font_family_Semibold);
          font-size: 16px;
          font-weight: var(--font_weight_2);
          line-height: normal;
          color: var(--primary_color);
        }
      }
    }
    @media (max-width: 1024px) {
      width: calc(100% - 60px);
    }
    @media (max-width: 600px) {
      width: calc(100% - 20px);
    }
  }
}
