.AlphabetTabcontent {
    display: flex;
    justify-content: center;
    font-family: var(--font_family_Semibold);
    font-size: 16px;
    font-weight: var(--font_weight_2);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--text_color);
    .alphabetsList {
      margin-right: 20px;
      cursor: pointer;
      font-family: var(--font_family_Semibold);
    }
    .ignoreAlphabet {
      opacity: 0.5;
      cursor: default;
    }
    @media (width <= 768px) {
      justify-content: start;
    }
  }