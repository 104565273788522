.switchTabContainer {
  box-shadow: var(--switch_tab_box_shadow);
  padding: var(--switch_tab_padding);
  margin: 30px 0;
  border-radius: var(--toggle_border_radius);
  display: flex;
  gap: 10px;
  background-color: var(--switch_tab_bg_color);
  border: 1px solid var(--switch_border_color);
  .switchTab {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 47px;
    font-weight: var(--toggle_font_weight);
    border-radius: var(--toggle_border_radius);
    font-size: 16px;
    cursor: pointer;

    @media (max-width: 600px) {
      font-size: 14px;
  }
  }
  .tabActive {
    color: var(--primary_brand_color_dark);
  }
  .tabInactive {
    background: var(--toggle_button);
    color: var(--text_color);
    opacity: 0.3;
  }
}
