:root {
  --primary_color: #008fd5;
  --white: #ffffff;
  --white_1: #f5f5f5;
  --white_2: #f0f0f0;
  --success: #008fd5;
  --default: #e8e8e8;
  --border_color: #bababa;
  --notificaton_danger: #962504;
  --danger: #d0021b;
  --red-color_2: #c30e0e;
  --red-color_3: #912f2f;
  --red-color_4: #f44336;
  --note_danger: #ed1c24;
  --info: #008fd5;
  --navy: #004282;
  --black: #000;
  --dark-charcoal-fade: #313131;
  --bluish_purple: #3e1f71;
  --light_black_1: #2b2b2b;
  --light_black_2: #222222;
  --lite_black_3: #1a1a1a;
  --primary_brand_color: #008fd5;
  --primary_brand_color_light: #008fd526;
  --azureish-white: #dfebf2;
  --lavender: #008fd5;
  --login-signup-color: #f5f5f5;
  --davys-grey: #56585e;
  --text_color: #56585e;
  --text_color_1: #2c2c2c;
  --text_color_2: #383838;
  --text_color_3: #212529;
  --text_color_4: #413131;
  --text_color_5: #434343;
  --text_color_6: #494949;
  --text_color_7: #4a4444;
  --text_color_8: #e6e6e680;
  --text_color_9: #ffffff;
  --text_color_10: #12abe3;
  --text_color_11: #808080;
  --text_color_12: var(--davys-grey);
  --text_color_13: var(--dark-charcoal-fade);
  --text_color_14: var(--lite_black_3);
  --grey-85: #d9d9d9;
  --tab_color_1: #414042;
  --grey_shade: #747474;
  --grey_shade_2: #bebebe;
  --grey_shade_3: #7e7e7e;
  --concrete-grey: #f3f3f3;
  --honey: #008fd5;
  --dusk: #008fd5;
  --dusty-orange: #008fd5;
  --grey: #575757;
  --bluish-cyan: #e7f4fa;
  --lightgrey: #f9f9f9;
  --lightgrey_2: #f0f0f0;
  --lightgrey_3: #f2f2f2;
  --lightgrey_4: #eee;
  --lightgrey_5: #999;
  --lightgrey_6: #f8f8f8;
  --bright-grey: #eaeaea;
  --date_picker_inp: #bbb;
  --select_border: #bbb;
  --medium-shade-grey: #404040;
  --medium-grey-shade_2: #515151;
  --border_dictator: #008fd5;
  --primary_background: #008fd5;
  --lite_background: #e1f0f5;
  --gray_background: #e7e7e7;
  --cool_blue: #4a7bb4;
  --dark_blue: #4e759b;
  --warm-grey: #9c9c9c;
  --apn_background_color: #fff7dc;
  --apn_text_color: #ff9d00;
  --danger_notification: #962504;
  --info_notification: #008fd5;
  --default_notification: #008fd5;
  --success_notification: #008fd5;
  --warning_notification: #f6d9a0;
  --subtext-color: #898989;
  --subtect-color_1: #666;
  --accordion-body_color: #686868;
  --mute_color: #e6e6e6;
  --complete: #6d6875;
  --title-color_1: var(--black);
  --title-color_2: #525252;
  --radio-color: #bbbbbb;
  --card-color: #dee2e6;
  --header-color_1: #121629;
  --subheader-color_1: #585757;
  --support-color: #1aabe0;
  --light-silver_color: #d8d8d8;
  --ford-grey-color: #979797;
  --grey33-color: #545454;
  --grey90-color: #e5e5e6;
  --cascading-white: #f6f6f6;
  --water-color: #d1eef9;
  --bleached_aqua: #3c8f8c;
  --bleached_aqua_2: #3d9997;
  --lavendar_color: #eaeaf0;
  --grey-seashell: #f2f1f1;
  --grey81-color: #cfcfcf;
  --grey98-color: #fafafa;
  --grey77-color: #c4c4c4;
  --grey-shade87: #878787;
  --grey-faded-1: #e5e8ee;
  --grey-shade9d: #8c909d;
  --darkgrey_shade: #262626;
  --cyan-blue-shade: #75787b;
  --medium-cyan-shade: #9cc2bf;
  --medium-cyan-shade_2: #80d5f2;
  --cyan-blue-shade_2: #777;
  --dark-slate-blue: #378781;
  --blue-color: #0000ff;
  --chinese-silver: #ccc;
  --light-brown: #b6aca2;
  --green-shade: #1a342f;
  --cruise-blue: #33746e;
  --light-blue-shade: #5e85b2;
  --dark-charcoal: #333;
  --grey_color_1: #979797;
  --warning: #005bbb;
  --button_secondary_color: #313131;
  --lead_generation_bg_color: #f9f9f9;
  --customer_service_bg_color: var(--cascading-white);
  --check_coverage_bg_color: #f9f9f9;
  --plan_section_bg_color: var(--cascading-white);
  --plan_card_upper_bg_color: rgb(62, 35, 199, 5%);
  --plan_card_hover_box_shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.07);
  --line_tab_border_radius: 8px;
  --line_tab_border_color: none;
  --main_banner_title_color: var(--black);
  --copyright_bg_color: var(--white);
  --line_tab_box_shadow: 0 4px 27px 0 rgba(0, 0, 0, 0.14);
  --plan_card_border_color: #f6f6f6;
  --lead_gen_input_box_shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  --byod_sections_order: row-reverse;
  --login-signup-color: var(--lead_generation_bg_color);
  --font_family_Medium: "Poppins-Medium";
  --font_family_Semibold: "Poppins-SemiBold";
  --font_family_Bold: "Poppins-Bold";
  --font_family_Regular: "Poppins-Regular";
  --main_footer_min_height: 159px;
  --lead_generation_dividor: #747474;
  --circular_loader_color: #fff;
  --footer_background_color: var(--white);
  --secondary_header_link_txt_color: #747474;
  --footer_divider_color: #9b9b9b;
  --copyright_text_color: var(--davys-grey);
  --manage_lines_user_status: #008fd5;
  --h3_font_family: var(--font_family_Bold);
  --h3_font_size_desktop: 26px;
  --h3_font_weight: bold;
  --h3_font_size_mobile: 22px;
  --h4_font_family: var(--font_family_Semibold);
  --h4_font_size_desktop: 20px;
  --h4_font_size_mobile: 20px;
  --h4_font_weight: 600;
  --description_text_font_family: var(--font_family_Medium);
  --description_text_font_size_desktop: 20px;
  --description_secondary_text_fs_desktop: 14px;
  --description_text_font_weight: 500;
  --page_header_font_family: var(--font_family_Bold);
  --page_header_font_size_desktop: 22px;
  --page_header_font_weight: "bold";
  --main_banner_title_ff: var(--font_family_Bold);
  --main_banner_title_line_height: 50px;
  --main_banner_title_line_height_mobile: 29px;
  --main_banner_title_fw: bold;
  --main_banner_title_fs_desktop: 36px;
  --main_banner_title_fs_ipad: 36px;
  --main_banner_title_fs_mobile: 26px;
  --main_banner_sub_title_ff: var(--font_family_Medium);
  --main_banner_sub_title_fw: semi-bold;
  --main_banner_sub_title_fs_desktop: 20px;
  --main_banner_sub_title_fs_ipad: 26px;
  --main_banner_sub_title_fs_mobile: 16px;
  --main_banner_title_f_align_mobile: "left";
  --internal_banner_title_ff: var(--font_family_Bold);
  --internal_banner_title_fw: bold;
  --internal_banner_title_fs_desktop: 50px;
  --internal_banner_title_fs_ipad: 36px;
  --internal_banner_title_fs_mobile: 24px;
  --brand_feature_background: #e8f1f1;
  --help_support_links: #476690;
  --border_color: #e3e3e3;
  --horizontal_divider: #e3e6e7;
  --selected_data_color: #e3eef0;
  --header_divider_color: var(--primary_color);
  --active_header_text: var(--primary_color);
  --bill_cal_input_bg_color: var(--primary_color);
  --bill_cal_input_border_color: #ced4da;
  --slider_bg_color: var(--primary_color);
  --slider_circle_border: var(--light-blue-shade);
  --select_disabled_bg_color: #e9ecef;
  --slider_popper_bg_img1: #484848;
  --slider_popper_bg_img2: #151515;
  --cement_color: #3c4043;
  --desktop_section_padding: 100px;
  --mobile_section_padding: 50px;
  --ipad_section_padding: 50px;
  --divider_color_2: #faa916;
  --divider_color: #faa916;
  --button_hover_color: #0b789e;
  --bill_cal_data_usage_unit_color: var(--white);
  --pay_now_card: #f3e3cf;
  --pay_now_element: #f3e3cf;
  --button_cust_font_weight: var(--font_weight_1);
  --support_modal_divider: var(--primary_color);
  --support_modal_mail_color: var(--success);
  --terms_anchor_tag_color: var(--primary_brand_color);
  --terms_anchor_tag_font_weight: 400;
  --progress_bar_disabled_bg_color: #e9ecef;
  --link_item_color: var(--primary_color);
  --services_font_weight: bold;
  --account_create_criteria_un_match_txt_color: var(--text_color);
  --plan_card_bottom_section_min_height: 90px;
  --input_base_border_radious: 27.5px;
  --text_field_label: var(--davys-grey);
  --text_field_text: var(--text_color_12);
  --text_field_border: var(--grey-85);
  --text_field_font_size: 12px;
  --text_field_font_family: var(--font_family_Regular);
  --text-field_border_radius: 10px;
  --faq-background: var(--cascading-white);
  --faq-title: var(--dark-charcoal-fade);
  --faq-question: var(--dark-charcoal-fade);
  --faq-answer: var(--davys-grey);
  --faq-answer-bg: var(--azureish-white);
  --account_suspended_color: var(--cyan-blue-shade);
  --zip_code_success: #459148;
  --zipcode_validation_color: #56585e;
  --zipcode_fail: #ff1e1e;
  --zip_text: #f80d0d;
  --zip_background_color: #f80d0d1c;
}
@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins"),
    url("../assets/Fonts/Poppins-Medium.ttf") format("opentype");
  font-weight: var(--font_weight_1);
  font-display: swap;
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins"),
    url("../assets/Fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: var(--font_weight_1);
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins"),
    url("../assets/Fonts/Poppins-Bold.ttf") format("opentype");
  font-weight: var(--font_weight_4);
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins"),
    url("../assets/Fonts/Poppins-Regular.ttf") format("opentype");
  font-weight: var(--font_weight_0);
  font-display: swap;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "SharpSansDispNo1", "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text_color);
}
.checkout_review_plan_card {
  background-color: none;
  border: none;
  border-radius: 0;
  margin-left: 0px;
}
.card_dlg_confirm_btn {
  background-color: var(--primary_brand_color) !important;
  color: var(--white) !important;
  height: 45px !important;
}
@media (max-width: 1024px) {
  .checkout_review_plan_card {
    margin: 0px;
    padding: 0px;
  }
}
.footer_logo_alignment {
  @media (max-width: 600px) {
    justify-content: center !important;
  }
}
