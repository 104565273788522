.no_order_icon {
  height: 200px;
  width: 160px;
}
.tooltip_style {
  width: 380px;
  color: var(--white) !important;
  :global(.MuiTooltip-tooltip) {
    font-family: var(--font_family_Medium) !important;
    color: var(--white);
    background-color: var(--primary_color) !important;
    padding: 0 15px !important;
    a > p {
      font-family: var(--font_family_Medium) !important;
      font-size: 14px;
    }
  }
  :global(.MuiTooltip-arrow::before) {
    background-color: var(--primary_color) !important;
  }
}
.line {
  width: 80px;
  height: 5px;
  border-radius: 3px;
  background: #333;
  margin: 15px auto 0;
  text-align: center;
}
.all_pack_container {
  border-radius: 10px;
  background: var(--white);
  .pack_details {
    padding: 25px 25px 0;
  }
  .dot {
    height: 6px;
    width: 6px;
    background-color: var(--grey-85);
    border-radius: 50%;
    display: inline-block;
    margin: 0 10px;
  }
  .horizontal_line {
    height: 0.4px;
    background-color: var(--text_color);
    width: 100%;
    margin: 20px 0 25px;
    opacity: 0.1;
  }
  .vertical_line {
    width: 2px;
    height: 20px;
    margin: 0 15px;
    background-color: var(--text_color);
  }
  .cancel_txt,
  .reschedule_txt {
    font-size: 16px;
    font-family: var(--font_family_Bold);
    font-weight: var(--font_weight_4);
    padding: 11px 20px;
    text-align: center;
    cursor: pointer;
    border-radius: 60px;
  }
  .cancel_txt {
    color: var(--tab_color_2) !important;
    border: 1px solid var(--primary_color) !important;
    color: var(--text_color) !important;
    background-color: var(--white) !important;
    width: 200px !important;
  }

  .reschedule_btn {
    display: flex;
    gap: 30px;
    margin-left: 25px;
    margin-bottom: 20px;
    justify-content: center;
    @media (max-width: 600px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .reschedule_txt {
    background-color: var(--primary_color) !important;
    color: var(--white) !important;
    width: 200px !important;
  }
  .country_name {
    text-transform: capitalize;
    color: var(--text_color);
    font-weight: var(--font_weight_0);
    font-size: 18px;
    @media (max-width: 900px) {
      font-size: 14px;
    }
  }
}

.data_boost_wrapper {
  width: 100%;
  .data_boost_container {
    height: auto;
    border-radius: 4px;
    border: 1px solid rgba(90, 90, 90, 0.3);
    background: var(--white);
    padding: 50px;
    text-align: center;
    .data_proceed_btn {
      width: 400px;
      border-radius: 6px;
      margin: 40px 0 50px;
      cursor: pointer;
    }
  }
  .user_name_db {
    color: var(--primary_brand_color_dark);
    border: 1px solid var(--primary_brand_color_dark);
    padding: 1px 7px;
    font-size: 20px;
    border-radius: 2px;
    background: var(--toggle_button);
    font-family: var(--font_family_SemiBold) !important;
    height: fit-content;
  }
}

.ir_cancel_title {
  font-family: var(--font_family_Bold);
  font-weight: var(--font_weight_4);
  font-size: 24px;
  color: var(--black);
}

.ir_cancel_text {
  font-family: var(--font_family_Regular);
  font-weight: var(--font_weight_0);
  font-size: 18px;
  color: var(--text_color);
  margin: 30px 0 0;
}

.ir_cancel_date {
  font-family: var(--font_family_Regular);
  font-weight: var(--font_weight_0);
  font-size: 18px;
  color: var(--text_color);
  margin: 0px 0 20px;
}

.ir_cancel_suc_title {
  font-family: var(--font_family_Bold);
  font-weight: var(--font_weight_4);
  font-size: 24px;
  color: var(--black);
  margin-top: 15px;
}

.ir_cancel_suc_text {
  font-family: var(--font_family_Medium);
  font-weight: var(--font_weight_0);
  font-size: 16px;
  color: var(--text_color);
  margin: 20px 0 30px;
}
