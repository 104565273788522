.disconnect_container {
  .disconnect_text {
    font-size: 20px;
    line-height: 1.15;
    color: var(--text_color);
    text-align: center;
    width: 60%;
    a {
      text-decoration: none;
      color: var(--primary_color);
      font-family: var(--font_family_Semibold);
      font-weight: var(--font_weight_2);
    }
    p {
      margin: 0px auto !important;
    }
    @media screen and (max-width: 768px) {
      width: 90%;
    }
  }
}