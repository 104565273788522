.left_arrow_icon {
    position: absolute;
    width: 15px;
    height: 18px;
    cursor: pointer;
    left: 0;
    margin-left: 20px;
    margin-top: 7px;
  }
  .add_card {
    font-size: 16px !important;
    font-weight: bold !important;
    font-family: var(--font_family_Bold) !important;
    color: var(--primary_color) !important;
  }
  
  .managecard_container {
    display: flex;
    justify-content: space-between; 
    width: 100%;
    margin: auto;
    // margin-top: 20px;
  
    @media (min-width: 1024px) {
      width: 830px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  