.privacy_main_container {
    margin: 0px auto 0px auto;
    padding: 50px 0;
    width: 80%;
    .privacy_content {
      color: var(--text_color);
      p {
        margin: 20px 0px 0px 0px;
        font-family: var(--font_family_Medium);
      }
      a {
        text-decoration: none;
        color: var(--dusk);
        font-family: var(--font_family_Bold);
        font-weight: bold;
      }
      .privacy_title {
        font-size: 28px !important;
        line-height: 1.43;
        text-align: center;
      }
      .privacy_line {
        width: 130px;
        height: 5px;
        border-radius: 2.5px;
        background-color: var(--success);
        margin: 10px auto 20px auto;
      }
      .privacy_text {
        font-family: var(--font_family_Medium);
        font-size: 14px;
        font-weight: normal;
        line-height: 2.14;
        letter-spacing: normal;
        margin: 20px 0px 0px 0px;
      }
      .privacy_top_para {
        margin-top: 50px;
      }
      .privacy_heading {
        font-size: 16px !important;
        line-height: normal;
        letter-spacing: normal;
        margin-top: 30px;
        text-transform: uppercase;
      }
      strong{
        font-family: var(--font_family_Bold);
      }
      table {
        font-family: var(--font_family_Medium);
        border-collapse: collapse;
        width: 100%;
      }
      th {
        font-family: var(--font_family);
        border: 1px solid #dddddd;
        font-family: var(--font_family_Bold);
        text-align: left;
      }
      td {
        font-family: var(--font_family_Medium);
        padding: 1px;
        border: 1px solid #dddddd;
      }
    }
    @media (max-width: 1024px) {
      margin-top: 71px;
    }
  }