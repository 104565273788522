.coverage_modal {
  background: var(--white);
  width: 540px!important;
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
  align-items: 'center';
  @media (max-width: 1300px) {
    width: 540px!important;
  }
  @media (max-width: 600px) {
    width: 100% !important;
    padding: 10px !important;
  }
  .final_heading {
    font-family: var(--font_family_Bold);
    font-size: 26px;
    color: var(--text_color);
    // margin-top: 20px;
    margin-bottom: 25px;
    text-align: center;
  }
  .final_success_modal_one {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ededed;
    border-radius: 10px;
    margin-bottom: 9px;
    .final_coverage_box {
      padding: 5%;
      width:63% !important;
      @media (max-width: 600px) {
        padding: 4% !important;
      }
    }
    .coverage_image_blk {
      text-align: center;
      // margin-bottom: 10%;
      width: 163px;
    }

    .coverage_text {
      font-family: var(--font_family_Bold);
      font-size: 20px;
      color: var(--text_color);
      margin-top: 20px;
      text-align: center;
    }
    .final_IMEI_box {
      padding: 5%;
      // border-left: 1px solid #ededed;
      width:70% !important;
      @media (max-width: 600px) {
        padding: 2% !important;
      }
    }
    .border_left{
      border-left: 1px solid #ededed;
    }
    .final_device_img_blk {
      text-align: center;
    }

    .final_device_heading {
      margin: 20px 0 30px;
      text-align: center;
      font-family: var(--font_family_Bold);
      font-size: 20px;
      color: var(--text_color);
    }

    .final_device_details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        font-family: var(--font_family_Medium);
        font-size: 14px;
        color: var(--text_color);
        padding: 5px 0;
      }
      span {
        font-family: var(--font_family_Medium);
      }
      .make_model {
        width:50%;
        text-align: end;
      }
    }
    .final_device_details div span::after {
      content: ":";
      position: absolute;
      right: 308px;
      @media (max-width: 600px) {
        right: 126px !important;
      }
      @media (min-width: 768px) {
        right: 175px;
      }
      @media (min-width: 1024px) {
        right: 305px;
      }
    }

    .final_action_blk {
      width: 35%;
      text-align: center;
      margin: 0 auto;
      padding-top: 25px;
      @media (max-width: 600px) {
        width: 68%;
      }
      @media (max-width: 768px) {
        width: 68%;
      }
    }
  }
  .final_check_again {
    font-family: var(--font_family_Semibold);
    font-size: 16px;
    color: var(--primary_color);
    border-bottom: 1px solid var(--primary_color);
    cursor: pointer;
  }
}
