.user_list_container {
  border-radius: 10px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  background: var(--white);
  .view_history_cta {
    text-align: center;
    padding-bottom: 20px;
    font-size: 16px;
    color: var(--text_color);
    font-weight: var(--font_weight_4);
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      background: rgba(217, 217, 217, 0.09);
    }
  }
  .user_name {
    display: flex;
    align-items: center;
    font-family: var(--font_family_Medium);
    font-size: 16px;
    color: var(--text_color);
    font-weight: var(--font_weight_2);
    text-transform: capitalize;
    @media (max-width: 900px) {
      flex-direction: column;
      gap: 10px;
    }
    .is_primary {
      font-weight: var(--font_weight_0);
    }
    .plan_active {
      font-weight: var(--font_weight_0);
      padding-left: 5px;
    }
  }
  .user_num {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    color: var(--text_color);
    @media (max-width: 900px) {
      justify-content: center;
    }
    span {
      font-family: var(--font_family_Medium);
      font-weight: var(--font_weight_2);
    }
  }
}
