.step3_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .heading_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0px 15px 0px;
    .arrow_icon {
      cursor: pointer;
      width: 24px;
      height: 24px;
      z-index: 100;
      left: 0;
      position: absolute;
    }
    .title_main {
      font-family: var(--font_family_Bold);
      font-size: 22px;
      font-weight: var(--font_weight_4);
      line-height: 1;
      text-align: center;
      color: var(--text_color);
    }
  }
  .stepper_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .stepper_txt {
      font-family: var(--font_family_Medium);
      color: var(--text_color);
      font-size: 16px;
      padding-bottom: 20px;
      margin: 25px auto;
    }
  }
  .inner_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 6px;
    box-shadow: 0 0 1px 1px rgb(60 64 67 / 30%);
    border: 1px solid var(--chinese-silver);
    background-color: var(--lightgrey_6);
    .step3_heading {
      display: flex;
      justify-content: center;
      font-family: var(--font_family_Semibold);
      font-size: 14px;
      text-align: center;
      color: var(--text_color_2);
      padding-bottom: 0;
      margin-bottom: 20px;
    }
    .step3_sim_number {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
      font-weight: 400;
      .step3_limit_div {
        align-self: flex-end;
        margin: 10px 0;
        font-family: var(--font_family_Regular);
        font-size: 12px;
      }
    }
    .step3_btn {
      display: flex;
      justify-content: center;
    }
  }
  .loaderCheck {
    display: flex;
    display: -webkit-box;

    :global(
        .MuiCircularProgress-root.MuiCircularProgress-colorPrimary.MuiCircularProgress-indeterminate
      ) {
      height: 30px !important;
      width: 30px !important;
    }

    svg {
      color: var(--primary_color);
    }
  }
}
