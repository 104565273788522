.main_container {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  align-items: center;
  @media (max-width: 600px) {
    width: 100%;
  }

  .title {
    font-size: 24px;
    font-family: var(--font_family_Bold) !important;
    line-height: 36px;
    color: var(--black);
    @media (max-width: 600px) {
      font-size: 18px;
      font-family: var(--font_family_Bold) !important;
    }
  }

  .description {
    font-size: 16px;
    font-weight: var(--font_weight_0);
    line-height: 28px;
    font-family: var(--font_family_Regular) !important;
    color: var(--text_color);
    margin-bottom: 20px;
    @media (max-width: 600px) {
      font-size: 14px;
      line-height: 25px;
      font-family: var(--font_family_Regular) !important;
    }
  }
  .btn {
    font-size: 16px;
    font-family: var(--font_family_Semibold) !important;
  }
}
