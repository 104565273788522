
.plan_card:hover, .current_plan_card:hover {
  border: 1px solid var(--primary_color);
  box-shadow:inset 0 -5px  0 var(--primary_color) !important
}
.plan_card, .current_plan_card {
  min-width: 283px;
  max-width: 283px;
  margin: 20px 23px;
  padding: 16px;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  .plan_content > * {
    font-family: var(--font_family_Medium) !important;
    color: var(--text_color) !important;
  }
  .plan_header {
    font-size: 22px;
    margin: 8px 0px;
    line-height: 1.235;
    letter-spacing: 0.00735em;
    font-weight: var(--font_weight_0);
    font-family: var(--font_family_Medium);
  }
  .plan_content {
    display: flex;
    flex-direction: column !important ;
    justify-content: space-between;
    height: 100%;
    text-align: center !important;
    .data_price_content > * {
      font-family: var(--font_family_Semibold);
      font-weight:var(--font_weight_0);
      line-height: 1.5;
      letter-spacing: 0.00938em;
    }
    .data_price_content {
      display: flex;
      justify-content: center;
      .sub_tag{ 
      font-weight: var(--font_weight_1) !important;
      color: var(--black);
      }
      .data_pack{
        margin: 8px 5px 8px 5px;
         font-weight:var(--font_weight_2) !important ;
        font-size:20px ;  
      }
      .line_price{
        font-family: var(--font_family_Semibold) !important;
        font-weight: (--font_weight_0) !important;
        font-size: 32px;
        line-height: 1.5;
      }
      .acc_line_price {
        font-family: var(--font_family_Semibold) !important;
        font-weight: var(--font_weight_2) !important;
        font-size: 24px;
        line-height: 1;
      }
    }
    .plan_description {
      margin: 8px 0px;
      font-weight:var(--font_weight_0);
      line-height: 1.5;
      letter-spacing: 0.00838em;
      opacity: 0.8;
      font-size: 12px;
      font-family: var(--font_family_Medium);
    }
    .additional_lines ,.unlimited_content{
      color:var(--primary_color);
      margin: 0px;
      font-size: 14px !important;
      font-family: var(--font_family_Medium);
      font-weight:var(--font_weight_0);
      line-height: 1.5;
      letter-spacing: 0.00938em; 
    }
  }
  .current_plan_tag {
    font-size: 12px;
    font-weight: var(--font_weight_2);
    background-color: var(--primary_brand_color);
    width: 55%;
    color: var(--white) !important;
    position: relative;
    margin-top: -17px;
    margin-left: -17px;
    padding: 3px 0px;
    border-radius: 5px 0px 5px 0px;
    line-height: 1.235;
    z-index: 1000;
  }
  .acc_subText {
    font-family: var(--font_family_Medium);
    font-weight: var(--font_weight_0);
    margin: 16px 0 16px 5px;
    font-size: 15px;
    color: var(--text_color);
  }
  .acc_unlimited_subText{
    font-size: 10px;
    color: var(--primary_color);
    margin:0px 0px 8px;
    line-height: 1.5;
    letter-spacing: 0.12194px;
    font-family: var(--font_family_Medium);
  }
  .unlimited_data_content{
    font-size: 15px;
    margin: 8px 0px;
    font-family: var(--font_family_Medium);
  }
  .line_price_tag{
    line-height: 1.5;
    letter-spacing: 0.00938em;
    font-family: var(--font_family_Medium);
    font-weight: var(--font_weight_0);
    font-size: 14px;
    color: var(--text_color);
 }
}
  
.current_plan_card{ 
   border: 1px solid var(--border_dictator);
}