.title {
  font-family: var(--font_family_Medium) !important;
  margin: 0 37px 10px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  line-height: 1.77 !important;
  text-align: center !important;
  color: var(--text_color_1) !important;
}

.label_txt {
  font-family: var(--font_family_Medium) !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.btn_container {
  margin-top: 30px;
  text-align: center !important;
}

.note_style {
  font-family: var(--font_family_Bold) !important;
  font-size: 12px !important;
  font-weight: var(--font_weight_1);
  line-height: 18.96px;
}