.discover_page_title {
    color: var(--text_color);
    text-align: center;
  }
  .discover_page_line {
    margin: 20px auto 25px;
    width: 80px;
    height: 5px;
    border-radius: 2.5px;
    background-color: var(--primary_brand_color);
  }
  