.check_coverage_container {
  // background: var(--light_primary_color);
}

.coverage_modal {
  background: var(--white);

  .coverage_check_heading {
    color: var(--title-color_1);
    font-family: var(--font_family_Bold) !important;
    font-weight: var(--font_weight_4);
    font-size: 28px;
  }

  .coverage_check_subheading {
    color: #313131;
    font-size: 16px;
    font-weight: 400;
    font-family: var(--font_family_Regular);
  }
  .coverage_error {
    color: var(--danger);
    font-family: var(--font_family_Semibold);
  }
}

.coverage_text1 {
  font-family: var(--font_family_Semibold) !important;
}

.coverage_final_text {
  font-family: var(--font_family_Regular);
}

.coverage_final_text p {
  font-family: var(--font_family_Regular);
}

.detailed_coverage {
  cursor: pointer;
  color: var(--primary_color);
  font-family: var(--font_family_Semibold) !important;
}
