.footer_t2 {
  font-family: var(--font_family_Bold);
  background-color: var(--footer_background_color) !important;

  .links_grid {
    .links {
      color: var(--white);
      font-family: var(--font_family_Medium);
      font-size: 14px;
      font-weight: var(--font_weight_1);

      .link_item {
        @media (max-width: 1224px) {
          margin: 5px 0 5px;
          flex: 0 0 33.333333%;
        }

        .sub_links {
          color: var(--white);
          font-family: var(--font_family_Medium);
          font-size: 14px;
          font-weight: var(--description_text_font_weight);
          cursor: pointer;

          .new_tag {
            font-family: var(--font_family_Semibold);
            font-size: 12px;
            background-color: var(--primary_color);
            color: var(--white);
            padding-top: 0.125rem;
            padding-bottom: 0.125rem;
            width: max-content;
            margin-left: 5px;
            border-radius: 2px;
            padding: 1px 5px;
          }
        }
      }
    }
  }

  .copyright_text {
    display: flex;
    justify-content: center !important;
    font-size: 14px;
    font-weight: var(--description_text_font_weight);
    line-height: 1.29;
    letter-spacing: -0.11px;
    color: var(--white);
    font-family: var(--font_family_Medium);
  }

  .disclaimer_text {
    font-size: 12px;
    font-weight: var(--description_text_font_weight);
    line-height: 2;
    color: var(--white);
    font-family: var(--font_family_Medium);
  }

  .version_indicator {
    color: var(--grey-shade9d);
    font-size: 13px;
    margin-left: 13px;
    line-height: 1.5;
  }
}