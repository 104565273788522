.left_div {
  .selected_plan {
    font-weight: var(--font_weight_2);
    font-family: var(--font_family_Semibold);
    line-height: 1.5;
  }

  .selected_plan_box {
    background-color: var(--white);
    border-radius: 4px;
    margin: 0 auto;
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
      rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    border: 2px solid var(--dusk);
    overflow: hidden;

    .select_title {
      font-size: 22px;
      font-weight: var(--font_weight_2);
      margin-top: 15px;
      font-family: var(--font_family_Semibold);
    }

    .select_desc {
      font-size: 15px;
      margin-top: 30px;
      margin-bottom: 30px;
      font-family: var(--font_family_Semibold);
    }

    .select_price {
      font-size: 24px;
      font-weight: var(--font_weight_2);
      line-height: 1.235;
      letter-spacing: 0.00735em;
      font-family: var(--font_family_Medium);
      opacity: 0.87;
    }

    .sub_tag {
      font-size: 14px;
      opacity: 0.87;
      font-family: var(--font_family_Semibold);
      margin: 8px 0px;
      font-weight: var(--font_weight_0);
      line-height: 1.5;
      letter-spacing: 0.00938em;
    }

    .sharable {
      font-size: 11px;
      margin-top: 30px;
      font-family: var(--font_family_Semibold);
      line-height: 1.5;
      letter-spacing: 0.00938em;
      font-weight: var(--font_weight_0);
      opacity: 0.87;
    }
  }
}

.right_div {
  .title {
    font-family: var(--font_family_Semibold);
    font-weight: var(--font_weight_2);
    margin-bottom: 10px;
    padding-left: 20px;
  }

  .description {
    color: var(--text_color);
    font-family: var(--font_family_Medium);
    font-weight: var(--font_weight_1);
    line-height: 1.33;
    padding-left: 20px;
  }

  .total_data_item {
    padding: 18px 20px;
    display: flex;
    justify-content: space-between;
    background-color: var(--pay_now_element);
  }

  .data_item {
    display: flex;
    justify-content: space-between;
    padding: 30px 20px;
  }

  .left_item,
  .right_item {
    color: var(--text_color);
    font-family: var(--font_family_Semibold);
    font-weight: var(--font_weight_2);
  }

  .division {
    opacity: 0.31;
    border: 1px solid var(--radio-color);
  }

  .card_desc {
    color: var(--text_color);
    font-family: var(--font_family_Medium);
    font-size: 16px;
    line-height: 24px;
  }

  .change_link {
    text-decoration: none;
    color: var(--primary_color);
    font-family: var(--font_family_Semibold);
    font-size: 16px;
    font-weight: var(--font_weight_4);
    line-height: 24px;
  }

  .card_num {
    color: var(--text_color);
    font-family: var(--font_family_Medium);
    line-height: 24px;
    margin-left: 10px;
  }
}
