  .service_content_main,
  .service_main {
    background-color: var(--white_1) !important;
    font-family: var(--font_family_Medium) !important;

    a {
      color: var(--dusk) !important;
      font-weight: bold;
      text-decoration: none;
    }

    .is_paragraph {
      margin: 0 0 0 10px;
      font-family: var(--font_family_Medium) !important;
      font-size: 14px;
      font-weight: var(--description_text_font_weight);
      line-height: 1.71;
      letter-spacing: normal;
      color: var(--text_color_2);
      margin-bottom: 10px;
    }

    ul {
      list-style: none;
      display: block;
      justify-content: center;
    }

    ul li::before {
      display: inline-block;
      content: "\2022";
      color: var(--primary_brand_color);
      font-weight: var(--h3_font_weight);
      width: 14px;
      font-size: 18px;
      margin-left: -1em;
      margin-right: 7px;
    }

    li {
      display: flex;
      align-items: center;
      font-family: var(--font_family_Medium);
      font-size: 14px;
      font-weight: var(--description_text_font_weight);
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: var(--text_color_2);
    }

    .dot {
      background-color: var(--primary_brand_color);
      height: 4px;
      width: 4px;
      border-radius: 50%;
      margin-bottom: 10px;
      margin-left: 40px;
    }

    .service_header_text,
    .service_header {
      font-family: var(--font_family_Medium) !important;
      font-size: 18px;
      color: var(--black);
      font-weight: var(--font_weight_4);
      margin-bottom: 15px;
    }
    .no_bullet{
      margin-top: 12px; 
      &::before {
        content: none;
      }
    }
  }