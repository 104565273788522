.main_container {
  border-radius: 10px;
  background: var(--white);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  padding: 30px;
  @media (max-width: 600px) {
    width: 95%;
    margin: auto;
    padding: 30px 15px;
  }
  .rows {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    font-size: 16px;
    color: var(--text_color);
    &:nth-child(3) {
      font-family: var(--font_family_Bold) !important;
      font-weight: var(--font_weight_4);
    }
  }
  .displayPack {
    font-family: var(--font_family_SemiBold) !important;
  }

  .auto_renual {
    padding: 20px 30px;
    background: var(--lightgrey);
    .toggleText {
      font-family: var(--font_family_SemiBold) !important;
    }
  }

  .main_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid var(--navy);
    @media (max-width: 600px) {
      font-size: 12px;
    }

    .changeCard {
      color: var(--text_color);
      font-family: var(--font_family_Bold) !important;
      text-decoration: underline;
    }
  }

  .card {
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
  }
}
