.brand_features {
  .feature_card {
    border: solid 0.3px var(--grey_color_1) !important;
    background-color: var(--white) !important;
    border-radius: 4px;
    color: var(--tab_color_1);
  }
  .line {
    border-radius: 2.5px;
    background-color: var(--success) !important;
    margin: 20px 0;
  }
  .high_light_cards {
    border: solid 0.3px var(--success) !important;
    background-color: var(--success) !important;
    color: var(--white);
  }
  .tab_mobile_icons {
    width: 52px;
    height: 53px;
  }
  .web_icons {
    width: 69px;
    height: 68px;
  }
  .img_name {
    font-family: var(--font_family_Semibold);
  }
}
