.linear_progress_bar_main {
  .MuiLinearProgress-root {
    height: 8px;
    border-radius: 5px;
    background-color: var(--progress_bar_disabled_bg_color);
    .MuiLinearProgress-bar {
      background-color: var(--primary_brand_color);
    }
  }
}
