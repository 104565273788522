.user_list_container {
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 30px;
  // box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  background: var(--white);
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 15px;
  }
  .user_name {
    display: flex;
    align-items: center;
    font-family: var(--font_family_Medium);
    font-size: 16px;
    color: var(--text_color);
    @media (max-width: 900px) {
      flex-direction: column;
      gap: 10px;
    }
    .is_primary {
      font-weight: var(--font_weight_0);
    }
    .plan_active {
      font-weight: var(--font_weight_0);
      padding-left: 5px;
    }
    .pack_active {
      display: flex;
      align-items: center;
      gap: 3px;
      color: var(--primary_color);
      font-family: var(--font_family_Medium);
      font-weight: var(--font_weight_2);
      @media (max-width: 900px) {
        margin-left: 0px;
      }
    }
  }
  .user_num {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    color: var(--text_color_18);
    font-family: var(--font_family_Medium);
    font-weight: var(--font_weight_0) !important;
    span {
      color: var(--text_color_18);
    }
    @media (max-width: 900px) {
      justify-content: center;
    }
  }
}
