.about_us_content {
  position: absolute;
  bottom: 6%;
  left: 16px;
  color: var(--white);
  font-size: var(--h4_font_size_desktop);
}
._mission_description {
  font-family: var(--font_family_Semibold) !important;
  font-size: 16px;
  line-height: 1.5;
}
.main_content {
  font-family: var(--description_text_font_family) !important;
  font-size: var(--description_text_font_size_desktop) !important;
  font-weight: var(--description_text_font_weight) !important;
}
@media screen and (min-width: 760px) {
  .main_content {
    max-width: 470px;
    text-align: center;
  }
}
