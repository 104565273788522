.visa_img {
  margin-bottom: 15px;
  margin-top: 15px;
  width: 45px;
  height: 30px;
}
@mixin fontStyle($fontSize, $fontFamily, $marginBottom, $color) {
  font-size: $fontSize !important;
  font-family: $fontFamily !important;
  margin-bottom: $marginBottom !important;
  color: $color !important;
}
.heading {
  @include fontStyle(22px, var(--font_family_Bold), 0px, var(--text_color));
  font-weight: var(--font_weight_4);
}
.card_name {
  @include fontStyle(
    14px,
    var(--font_family_Semibold),
    15px,
    var(--text_color)
  );
}
.card_number {
  @include fontStyle(14px, var(--font_family_Semibold), 5px, var(--text_color));
}
.expiry {
  @include fontStyle(14px, var(--font_family_Medium), 15px, var(--text_color));
}
.confirm_button {
  height: 45px !important;
}
.arrow_icon {
  cursor: pointer;
  width: 22px;
  height: 22px;
  left: 5%;
  top: 6%;
  position: absolute;
}
.auto_pay_dialog {
  width: 600px !important;
  background-color: white;
  text-align: center;
  .dialog_heading {
    color: var(--text_color);
    font-size: 22px;
    font-family: var(--font_family_Bold);
  }
  .dialog_desc {
    font-size: 16px;
    font-family: var(--font_family_Medium);
    line-height: 1.63;
    color: var(--text_color);
  }
}
