@mixin dlg_font_styles($fs, $ff, $fw, $color) {
  @if $fs {
    font-size: $fs !important;
  }
  @if $ff {
    font-family: $ff !important;
  }
  @if $fw {
    font-weight: $fw !important;
  }
  @if $color {
    color: $color !important;
  }
}
.title {
  margin: 0 37px 10px !important;
  line-height: 1.77 !important;
  text-align: center !important;
  @include dlg_font_styles(
    24px,
    var(--font_family_Bold),
    bold,
    var(--text_color_3)
  );
}
.label_txt {
  @include dlg_font_styles(
    16px,
    var(--font_family_Semibold),
    bold,
    var(--text_color_3)
  );
}
.esim_tooltip_container {
  :global(.MuiTooltip-tooltip) {
    padding: 9px 14px !important;
  }
}
.info_icon {
  height: 12px;
  width: 13px;
  object-fit: contain;
  cursor: pointer;
  margin-right: 9px;
  margin-top: 9px;

  @media (max-width: 600px) {
    top: 200px;
    right: 13px;
  }
}
.btn_container {
  margin-top: 25px;
  text-align: center !important;
}
.note {
  @include dlg_font_styles(
    14px,
    var(--font_family_Regular),
    Medium,
    // var(--font_weight_2),
    var(--text_color)
  );
  line-height: 1.58 !important;
  letter-spacing: normal !important;
  text-align: justify;
}
