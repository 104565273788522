.item_container {
  font-size: 14px;
  margin: 0 15px 0 0;
  padding: 15px 0 15px 0;
  text-align: center;
  color: var(--dark-charcoal-fade);

  :global(.MuiButtonBase-root) {
    color: var(--text_color);
    font-family: var(--font_family_Medium);
    font-size: 14px;
    font-weight: var(--font_weight_2);
  }

  :global(.MuiButtonBase-root:hover) {
    color: var(--primary_color);
    background: var(--white);
  }

  :global(.MuiButton-root:active),
  :global(.MuiButton-root:hover) {
    background: var(--white);
  }

  .arrow_up_key {
    fill: var(--black);
  }

  .header_btn_txt {
    font-family: var(--font_family_Medium);
    color: var(--dark-charcoal-fade) !important;
  }

  .header_menu {

    :global(.MuiPaper-root),
    :global(.MuiMenu-paper),
    :global(.MuiPopover-paper),
    :global(.MuiPaper-elevation8),
    :global(.MuiPaper-rounded) {
      margin-top: 18px;
      top: 52px !important;
      box-shadow: none !important;
      border-bottom-left-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
    }

    :global(.MuiList-root):global(.MuiMenu-list),
    :global(.MuiList-padding) {
      padding: 0;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    :global(.MuiMenuItem-root) {
      padding: 8px 20px;
    }

    :global(.MuiButtonBase-root:hover) {
      color: var(--white);
      background: var(--primary_color);
    }

    :global(.MuiListItem-button) {
      background: #fff;
    }

    :global(.MuiListItem-button:hover) {
      background-color: var(--primary_color) !important;
    }

    :global(.MuiPaper-rounded) {
      border-radius: 0;
    }

    .menu_item {
      font-family: var(--font_family_Medium) !important;
      display: flex;
      font-size: 14px;
    }
  }

  .active_toggle_button:global(.MuiButtonBase-root) {
    color: var(--primary_color);
  }

  @media screen and (min-width: 1280px) {
    margin: 0px 30px 0 0;
  }
}

.active_header_item {
  border-bottom: 4px solid var(--header_divider_color);
  :global(.MuiButton-text){
    color: var(--active_header_text) !important;
  }
}