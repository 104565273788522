.service_main {
  border-radius: 4px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
  font-family: var(--font_family_Medium) !important;
  text-align: center;
  font-weight: var(--font_weight_1);
  padding: 40px 20px;

  .service_header_text {
    font-family: var(--font_family_Medium) !important;
    font-size: 30px;
    font-weight: var(--font_weight_4);
    color: var(--black);
    margin-bottom: 15px;
    .service_Desc_text {
        font-family: var(--font_family_Medium) !important;
        font-weight: var(--font_weight_1);
        font-size: 18px;
      }
  }
   
}