.payment_description {
  color: var(--text_color);
  font-family: var(--font_family_Medium) !important;
  font-weight: var(--font_weight_1) !important;
  line-height: 1.33 !important;
  margin-right: 10px !important;
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
}

.payment_status {
  color: var(--text_color);
  font-family: var(--font_family_Medium) !important;
  font-weight: var(--font_weight_2) !important;
  line-height: normal !important;
}

.loader_spin,
.loader_spin:before,
.loader_spin:after {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  content: "";
  display: block;
  opacity: 0.3;
  background-color: var(--text_color);
  animation-name: loader_spin_animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

.loader_spin {
  position: relative;
  left: 25px;
  animation-delay: 0s;
}

.loader_spin:before,
.loader_spin:after {
  position: absolute;
}

.loader_spin:before {
  left: -25px;
  animation-delay: 0.25s;
}

.loader_spin:after {
  left: 25px;
  animation-delay: 0.5s;
}

@keyframes loader_spin_animation {
  from {
    opacity: 0.6;
  }

  to {
    opacity: 0.3;
  }
}
