.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .cal_title {
    font-size: 22px;
    font-family: var(--font_family_Semibold);
  }

  .desc {
    text-align: center;
    font-size: 16px;
    font-family: var(--font_family_medium);
  }

  .dateCalendar {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 9px;
  }
}
