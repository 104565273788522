.meet_the_recipients {
    .meet_the_recipients_line {
        width: 80px;
        height: 5px;
        border-radius: 2.5px;
        background-color: var(--primary_brand_color);
    }
    .video_Cards_Wrap {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;

        .Video_card {
            box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5);
            border-radius: 4px;
            transition: 0.3s;
            width: 20%;
            max-width: 300px;
            margin: 15px 20px;
            img {
                cursor: pointer;
            }
            .container {
                padding: 10px 5px 18px 5px;
            }
            .Card_Text {
                font-family: var(--font_family_Semibold);
                font-size: 14px;
                font-weight: var(--font_weight_2);
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: var(--text_color_3);
                padding: 5px 15px;
            }
        }
    }

    @media (max-width: 1024px) {
        .video_Cards_Wrap {
            .Video_card {
                width: 300px;
            }
            .respcardmargin {
                margin-right: 25px;
            }
        }
    }
    @media (max-width: 768px) {
        .video_Cards_Wrap {
            .Video_card {
                width: 300px;
                .container {
                    padding: 5px 5px 5px 5px;
                }
            }
        }
    }
    @media (max-width: 600px) {
        margin-top: 50px;
        .video_Cards_Wrap {
            .Video_card {
                width: calc(100% - 40px);
                max-width: none;
                .container {
                    padding: 5px 5px 5px 5px;
                }
            }
        }
    }

    .meet_the_recipients_sub_heading {
        font-family: var(--font_family_Medium);
        font-size: 16px;
        font-weight: var(--font_weight_1);
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: center;
        color: var(--text_color);
        margin: 10px 0;
    }
}