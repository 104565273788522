.making_difference_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .diff_img_div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

    .share_now_btn {
        display: flex;
        justify-content: center;
        width: 165px;
        height: 50px;
        margin: 0px 0px 20px 0px;
        border-radius: 30px;
        background-color: var(--dusk);
        .share_now_btn1 {
            font-size: 16px;
            white-space: nowrap;
            color: var(--white);
            font-stretch: normal;
            font-weight: bold;
            text-transform: none;

        }
        @media (max-width: 600px) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .make_difference_text {
        font-family: var(--font_family);
        font-size: 20px;
        font-weight: var(--font_weight_4);
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: var(--text_color);
        padding-bottom: 5px;
    }

    .make_difference_sub_text {
        font-family: var(--font_family_Medium);
        font-size: 16px;
        font-weight: var(--font_weight_1);
        font-stretch: normal;
        font-style: normal;
        line-height: 1.88;
        letter-spacing: normal;
        text-align: center;
        color: var(--text_color);
        padding-bottom: 20px;
    }
    @media (max-width: 1024px) {
        margin-top: 50px;
    }
    .home_post_activation_title {
        font-family: var(--font_family);
        font-size: 26px;
        font-weight: var(--font_weight_4);
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: var(--text_color);
        text-align: center;
    }
    .home_post_activation_sub_title {
        font-family: var(--font_family_Medium);
        font-size: 22px;
        font-weight: var(--font_weight_1);
        font-stretch: normal;
        font-style: normal;
        line-height: 1.18;
        letter-spacing: normal;
        text-align: center;
        color: var(--text_color);
        margin-top: 15px;
    }
    .home_post_activation_line {
        width: 120px;
        height: 5px;
        border-radius: 2.5px;
        background-color: var(--primary_brand_color);
        margin: 20px auto 25px auto;
    }
}
.post_home_tool_tip {
    .share_style {
        font-size: 15px;
        font-family: var(--font_family_Semibold);
        padding: 10px 0px 0px 0px;
        color: var(--primary_color);
        cursor: pointer;
        .donations_share_icon {
            margin-left: 10px;
            width: 20px;
            height: 18px;
        }
    }
}
.post_home_share_container {
    width: 230px;
    padding: 1rem;
    .post_home_share_header {
        display: flex;

        .post_home_close_icon {
            height: 15px;
            width: 15px;
            cursor: pointer;
            color: #000;
            opacity: 0.5;
        }
        .post_home_share_header_text {
            font-family: var(--font_family);
            font-weight: var(--font_weight_4);
            font-size: 16px;
            text-align: center;
            margin: 0 auto;
        }
    }
    .social_icons_home {
        display: flex;
        justify-content: space-around;
        .individual_social_iconhome {
            margin-top: 10px;
            text-align: center;
            .media_icon_home {
                width: 50px;
            }
            .icon_text_home {
                margin-top: 10px;
                font-size: 14px;
                font-family: var(--font_family_Medium);
                color: #414141;
            }
        }
    }
    @media (max-width: 600px) {
        width: 100%;
    }
}
