.arrow_icon {
    cursor: pointer;
    width: 18px;
    height: 18px;
    z-index: 100;
    left: 0;
    position: absolute;
  }
  .title_main {
    font-family: var(--font_family_Bold);
    font-size: 22px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    
  }
  .stepper_txt {
    font-family: var(--font_family_Medium);
    color: var(--text_color);
    font-size: 16px;
    padding: 20px 0;
  }
