.check_coverage_outer {
  background-color: var(--primary_background);
  height: 272px;
  padding: 20px;
  @media (max-width: 600px) {
    height: 400px;
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    height: 380px;
  }
  .coverage_check_heading {
    font-size: 30px;
    font-weight: var(--font_weight_2);
    line-height: 1.8;
    color: var(--white);
    font-family: var(--font_family_Semibold);
    text-align: start;
    @media (max-width: 600px) {
      font-size: 22px;
      text-align: center;
    }
    @media (max-width: 1024px) {
      font-size: 24px;
    }
    @media (max-width: 1200px) {
      font-size: 26px;
    }
  }
  .coverage_check_subheading {
    font-size: 26px;
    font-weight: var(--font_weight_1);
    line-height: 1.73;
    color: var(--white);
    font-family: var(--font_family_Medium);
    margin: 14px 0px;
    @media (max-width: 600px) {
      font-size: 18px;
      text-align: center;
    }
  }
  .address_holder {
    display: flex;
    width: 400px !important;
    @media (max-width: 600px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 95% !important;
    }
    :global(.TextField .MuiFilledInput-root) {
      border: 1px solid var(--white);
    }
    :global(.TextField .MuiFilledInput-input) {
      background-color: var(--primary_background);
      color: var(--white);
    }
    :global(.TextField .MuiInputLabel-filled) {
      color: var(--white);
      @media (max-width: 600px) {
        overflow: inherit;
      }
    }

    .submit_btn {
      background-color: var(--primary_background);
      color: var(--white);
      border: 1px solid var(--white);
      height: 55px;
      margin-left: 10px;
      width: 32px;
      span {
        margin: 0px !important;
      }
      @media (max-width: 600px) {
        width: 45px;
      }
    }
  }

  .coverage_final_text {
    font-family: var(--font_family_Bold);
    font-size: 26px;
    font-weight: var(--font_weight_1);
    line-height: 1.73;
    color: var(--white);
    @media (max-width: 600px) {
      font-size: 15px;
      text-align: center;
    }
  }
  .coverage_buttons {
    @media (max-width: 600px) {
      display: flex;
      width: 100%;
    }
    .button_text {
      color: var(--white);
      font-family: var(--font_family_Bold);
      font-size: 14px;
      font-weight: var(--font_weight_2);
      line-height: normal;
      margin-right: 20px;
    }
  }
  .coverage_error {
    color: var(--danger);
    @media (max-width: 600px) {
      text-align: center;
      margin-top: 5px;
    }
  }
}
