.modal_main_container {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  .section {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin: 3px 0px 30px;
  }

  .modal_data_text {
    font-family: var(--font_family_Medium);
    font-size: 16px;
    font-weight: var(--font_weight_0);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-items: center;
    color: var(--text_color);
  }

  .modal_country_name {
    font-family: var(--font_family_Bold);
    font-size: 24px;
    color: var(--black);
    font-weight: var(--font_weight_4);
    text-transform: capitalize;
  }

  .modal_sub_title {
    font-family: var(--font_family_Medium);
    font-size: 16px;
    font-weight: var(--font_weight_1);
    color: var(--text_color);
    border-bottom: 1px solid var(--lightnavy);
    padding: 10px 0 35px;
  }

  .footer {
    width: 65%;
    margin: auto;
    font-size: 14px;
    font-family: var(--font_family_Medium);
    font-weight: var(--font_weight_0);
  }
}
