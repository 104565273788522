.data_purchase_history_dialog{
  background-color: var(--white);
  padding: 25px !important;
  width: 600px !important;
  :global(.MuiTypography-root) {
      top: 15px;
      left: 25px;
    }
    :global(.MuiSvgIcon-root) {
      font-size: 15px !important;
      opacity: 0.3rem !important;
    }
  .data_purchase_history_main{
      width: 100%;
      .data_purchase_history_heading{
          display: flex;
          justify-content: center;
          letter-spacing: 0;
          width: 100%;
          font-family: var(--font_family_Bold);
          margin-bottom: 20px;
          font-weight: bold;
          font-size: 22px;
      }
      .data_purchase_history_container{
          padding: 10px;
          box-shadow: inset 0 0 10px var(--lightgrey_4);
          overflow: auto;
          margin: auto;
          width: 85%;
          max-height: 400px;
          .data_purchase_history_card{
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              padding: 15px 0;
              border-bottom: 1px solid var(--lightgrey_4);
              .data_purchase_card_details{
                  font-family: var(--font_family_Medium);
                  font-size: 14px;
                  font-weight: 500;
              }
              .data_and_date_xs {
                display: none;
              }
              @media screen and (max-width: 550px) {
                flex-direction: column;
                .data_and_date_xs {
                  display: flex;
                  justify-content: space-between;
                }
                .data_and_date_sm {
                  display: none;
                }
              }
              &:nth-last-child(1) {
                border-bottom: none;
              }
          }
      }
  }
}