.main_container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: space-between;

    .top_heading {
        text-align: center;
        font-size: 30px;
        font-family: var(--font_family_Medium) !important;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
                font-family: var(--font_family_Medium) !important;
                font-weight: var(--font_weight_5)
            }
    }

    .back_arrow {
        position: absolute;
        left: 90px;
        display: flex;
        align-items: center;
        cursor: pointer;
        gap:8px;
        font-family: var(--font_family_Medium) !important;
        font-weight: var(--font_weight_5);

      
    }

    .country_label {
        font-size: 30px;
        color: var(--tab_color_2);
        font-family: var(--font_family_SemiBold) !important;
    }

    .full_name {
        color: var(--primary_brand_color_dark);
        border: 1px solid var(--primary_brand_color_dark);
        padding: 1px 7px;
        font-size: 20px;
        border-radius: 2px;
        background: var(--toggle_button);
    font-family: var(--font_family_Medium) !important;
        font-weight: var(--font_weight_5);

    }

    .schedule {
        font-size: 16px;
        font-family: var(--font_family_Medium) !important;
    }

    .selected_date {
        color: var(--primary_color);
        font-family: var(--font_family_Medium) !important;
    }

    .display_name {
        font-size: 24px;
        font-family: var(--font_family_SemiBold) !important;
        font-weight: var(--font_weight_5);

        span{
            font-weight:var(--font_weight_5);
        }
    }

    .display_name_service_unit {
        color: var(--primary_color);
        font-family: var(--font_family_Medium) !important;
    }

    .data_cost {
        font-size: 24px;
        color: var(--primary_color);
        font-family: var(--font_family_Medium) !important;
        font-weight: var(--font_weight_3);
    }

    .card_sub_container {
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: space-evenly;
        padding: 25px;
        gap: 10px;
        flex-wrap: wrap;
    }
    .card_sub_container_title {
        font-size: 16px;
        color: var(--primary_color);
        font-family: var(--font_family_SemiBold) !important;
        font-weight: var(--font_weight_3);
    }

    .card_sub_container_value {
        font-size: 14px;
        font-family: var(--font_family_Medium) !important;
        font-weight: var(--font_weight_1);
    }

    .term_condition {
        font-size: 18px;
        color: var(--primary_color);
        font-family: var(--font_family_Medium) !important;
    }
}
