.footer_t2 {
  font-family: var(--font_family_Bold);
  background-color: var(--footer_background_color) !important;
  margin-top: auto;

  .links_grid {
    min-height: var(--main_footer_min_height);

    .links {
      color: var(--text_color);
      font-family: var(--font_family_Medium);
      font-size: 14px;
      font-weight: var(--font_weight_1);

      .link_item {
        // width: 100%;
        @media (max-width: 1224px) {
          width: 100%;
          text-align: center;
        }

        @media (max-width: 1224px) {
          margin: 5px 0 5px;
          // flex: 0 0 33.333333%;
        }

        .sub_links {
          color: var(--text_color);
          font-family: var(--font_family_Regular);
          font-size: 14px;
          cursor: pointer;

          .new_tag {
            font-family: var(--font_family_Semibold);
            font-size: 12px;
            background-color: var(--primary_color);
            color: var(--text_color);
            padding-top: 0.125rem;
            padding-bottom: 0.125rem;
            width: max-content;
            margin-left: 5px;
            border-radius: 2px;
            padding: 1px 5px;
          }
        }
      }
    }
  }

  .copyright_text {
    font-family: var(--font_family_Regular);
    padding: 16px 0px 16px 0px;
    text-align: center;
    color: var(--copyright_text_color);
    font-size: 14px;
    line-height: 12px;
    background: var(--copyright_bg_color);
    margin-left: auto;
  }
}
