.data_usage_label {
  font-family: var(--font_family_Bold) !important;
  font-size: 20px !important;
  font-weight: var(--font_weight_0) !important;
  line-height: 40px !important;
}
.bill_cycle_label {
  font-family: var(--font_family_Bold) !important;
  font-size: 20px !important;
  font-weight: var(--font_weight_0) !important;
  display: flex !important;
}
.data_usage_txt {
  font-size: 28px !important;
  line-height: 1.5 !important;
  font-weight: 700 !important;
  display: flex !important;
  font-family: var(--font_family_Bold) !important;
}
.load_icon {
  width: 28px !important;
  margin-left: 10px;
  color: var(--primary_color);
}
.load_icon_gif {
  width: 28px !important;
  height: 28px !important;
  margin-left: 10px;
  color: var(--primary_color);
  display: inline-block;
  animation: rotate_animation 1.5s linear infinite;
}

@keyframes rotate_animation {
  0% {
    transform: rotate(365deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@media (max-width: 600px) {
  .data_usage_label {
    font-size: 20px !important;
    line-height: 1.2 !important;
  }
  .bill_cycle_label {
    font-size: 20px !important;
  }
  .data_usage_txt {
    font-size: 26px !important;
    line-height: normal !important;
  }
}

.progress_bar {
  :global(.MuiLinearProgress-bar) {
    background-color: var(--primary_brand_color) !important;
    width: 500px;
    border: 1px solid red;
  }
}
