.home_page_plan_card {
  min-width: 250px;
  width: 250px;
  margin: 0 15px 20px;
  padding: 25px 0;
  border-radius: 4px;
  background-color: var(--white);
  position: relative;
  .tag {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px 10px;
    border-radius: 4px 0 4px 0;
    font-size: 12px;
    font-weight: var(--font_weight_2);
  }

  .separator {
    width: 60px;
    height: 5px;
    border-radius: 2.5px;
    margin: 12px auto;
    background-color: var(--primary_color) !important;
  }
  .save_money_area {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-family: var(--font_family_Semibold);
    font-size: 12px;
    color: var(--text_color);
    .save_money_img {
      height: 15px;
      padding: 0 4px 4px 0;
    }
  }
  .view_detail {
    font-family: var(--font_family_Bold);
    font-size: 14px;
    font-weight: var(--font_weight_4);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin: 10px 0 0 0;
    color: var(--primary_color);
    cursor: pointer;
  }
  .plan_page_offer {
    margin-left: 10px;
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    font-weight: var(--font_weight_2);
    color: var(--text_color);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
  }
  @media (max-width: 1366px) {
    min-width: 280px;
    width: 285px;
  }
  @media (max-width: 1024px) {
    margin: 0 10px 20px 10px;
    min-width: 325px;
  }
  @media (max-width: 600px) {
    margin: 0 0px 30px 0;
    min-width: 300px;
  }
}

.apply_border123 {
  border: 2px solid var(--primary_color);
}

.cards_div {
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  margin-top: 24px;
  @media (max-width: 600px) {
    margin-top: 30px;
  }
}
.plans_div {
  width: 100% !important;
  .plan_page_plan_card {
    min-width: 272px !important;
    padding: 20px 10px;
    width: 15% !important;
    border-radius: 10px;
    box-shadow: none !important;
  }
}
.fade_out {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.current_plan {
  border: 2px solid var(--primary_color);
}

@media (max-width: 600px) {
  .wrapper_div {
    overflow-x: auto;
    // white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    display: flex;
    max-width: 100%;
    position: relative;
  }
  .wrapper_div::-webkit-scrollbar {
    display: none;
  }
  .cards_div {
    justify-content: flex-start;
    margin-left: 30px;
    gap: 25px;
    flex-wrap: nowrap !important;
  }
  .plans_div {
    .plan_page_plan_card {
      padding: 40px 20px;
      border: 0.6px solid rgba(0, 0, 0, 0.15);
    }
  }
  .cards_div::-webkit-scrollbar {
    display: none;
  }
}
.mb_img {
  margin-bottom: 20px;
}
