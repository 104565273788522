.Para_Heading1 {
    padding-top: 70px;
    font-family: var(--font_family_Bold);
    font-size: 26px;
    font-weight: var(--font_weight_4);
    line-height: 1.15;
    letter-spacing: normal;
    color: var(--text_color);
    @media (max-width: 1024px) {
        padding-top: 50px;
    }
}
.post_activation_discover_card {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    @media(min-width: 400px) {
        padding: 0px 60px;
    }
    .prev_next_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        svg {
            border: 1px solid var(--lightgrey_4);
            border-radius: 50%;
        }
    }
}
.view_more_post {
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dusk);
    cursor: pointer;
    margin-top: 20px;
}