.user_list_container {
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
    .user_name {
        display: flex;
        align-items: center;
        font-family: var(--font_family_Medium);
        font-size: 20px;
        font-weight: 800;
        .is_primary {
            color: var(--text_color_11);
            padding: 0 0 0 5px;
        }
    }
    .user_num {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 18px;
        color: var(--text_color_11);

        span{
        font-family: var(--font_family_Medium);
        font-weight: var(--font_weight_1);
        }
    }
    .pack_active, .sim_type {
        font-size: 12px;
        background: var(--toggle_button);
        padding: 2px 10px;
        color: var(--lite_background2);
        border-radius: 2px;
    }
    .pack_active {
        margin-left: 10px;
    }
    .sim_type {
        border: 1px solid var(--lite_background2);
        padding: 1px 10px;
    }
}