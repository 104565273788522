.support_main {
  background-color: var(--lite_background);
  position: relative;
  width: 100%;
  .support_line {
    display: flex;
    justify-content: center;
    width: 100%;
    .horizontal_line {
      height: 4.8px;
      background-color: var(--dusty-orange);
      position: absolute;
      top: 29%;
      width: 100%;
    }
    .rectangle {
      align-items: center;
      background-color: var(--primary_background);
      display: flex;
      height: 83px;
      justify-content: center;
      margin: 0 auto;
      position: absolute;
      top: 20%;
      width: 485px;
      .text {
        color: var(--white);
        font-size: 30px;
        text-align: center;
        font-family: var(--font_family_Medium);
      }
    }
  }
  .support_div {
    border-right: 1px solid var(--support-color);
    margin: 8px 0;
    padding: 0 12px;
    @mixin supportTextStyles($fontSize, $lineHeight) {
      font-weight: var(--font_weight_1);
      color: var(--text_color_3);
      font-family: var(--font_family_Medium);
      font-size: $fontSize;
      line-height: $lineHeight;
    }
    .support_text {
      @include supportTextStyles(22px, normal);
      margin: 25px 0 0;
      word-wrap: break-word;
      font-family: var(--font_family_Semibold) !important;
    }
    .support_text_1 {
      @include supportTextStyles(16px, 1.5);
    }
    &:nth-last-child(1) {
      border: none;
    }
  }
  @media (max-width: 600px) {
    .support_line {
      .rectangle {
        height: 60px;
        left: unset;
        top: 4%;
        width: 225px;
      }
      .horizontal_line {
        top: 10%;
      }
    }
    .support_div {
      border: none;
      .support_text {
        font-size: 18px;
      }
    }
  }
}