.stepperWrapper {
    width: 70%;
    margin: auto;
    @media (max-width: 600px) {
        width: 95%;
    }
    .stepperBar {
        display: flex;
        padding: 0 75px;
        justify-content: space-between;
        z-index: 10;
        .bars {
            flex-grow: 1;
            .baseBar {
                width: 100%;
                height: 10px;
                border-radius: 5px;
                background-color: var(--mute_color);
            }
            .progressBar {
                height: 10px;
                border-radius: 5px;
                margin-top: -10px;
                transition: all 200ms;
            }
            .failed {
                background-color: var(--warm_grey);
            }
            .success {
                background: var(--primary_brand_color_light);
            }
        }
        @media (max-width: 750px) {
            padding: 0 18%;
        }
        @media (max-width: 300px) {
            padding: 0 9%;
        }
    }

    .stepperContentWrapper {
        margin-top: -32px;
        display: flex;
        justify-content: space-between;
        z-index: -1;
        .avatar{
            display: flex;
            margin-top: -30px;
            position: absolute;
        }
        .stepperStep {
            display: flex;
            align-items: center;
            flex-flow: column nowrap;
            width: 154px;
            @media (max-width: 700px) {
                width: 60%;
            }
            @media (max-width: 300px) {
                width: 20%;
            }
            .knob {
                height: 16px;
                width: 16px;
                border-radius: 50%;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5);
                background-color: var(--white);
                margin: 1.15rem auto 0.8rem;
            }
            .stepTitle {
                text-align: center;
                font-size: 14.4px;
                font-weight: var(--font_weight_4);
                color: var(--complete);
                line-height: 21.6px;
                //word-break: break-word;
                // width: 100%;
            }
        }
    }
}