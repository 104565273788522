.team_feedback {
    .description {
        color: var(--text_color);
        font-weight: var(--font_weight_1);
        font-size: 12px;
    }
    .name_designation {
        font-family: var(--font_family_Bold);
        font-size: 12px;
        font-weight: var(--font_weight_2);
        color: var(--text_color_7);
        span {
            font-family: var(--font_family_Semibold);
        }
    }
}