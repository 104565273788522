.plan_card_container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-top: 20px;
  .plan_card {
    padding: 16px;
    margin: 20px 23px;
    border-radius: 4px;
    min-width: 258px;
    max-width: 258px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
      rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    &:hover {
      border: 1px solid var(--primary_color);
      box-shadow: inset 0 -5px 0 var(--primary_color);
    }
    .current_plan_tag {
      width: 50%;
      border-radius: 4px 0 4px 0;
      font-size: 12px;
      font-weight: var(--font_weight_2);
      background-color: var(--primary_background);
      color: var(--white);
      text-align: center;
      margin: -16px 0 0 -16px;
      font-family: var(--font_family_Medium);
    }
    .plan_heading {
      font-size: 22px;
      font-weight: 600;
      margin: 8px 0px;
      line-height: 1.235;
      font-family: var(--font_family_Medium);
    }
    .plan_text1 {
      font-size: 20px;
      font-weight: 600;
      font-family: var(--font_family_Bold);
      .plan_span_text {
        margin: 8px 5px 8px 0;
        font-size: 15px;
        font-family: var(--font_family_Medium);
        font-weight: 400;
      }
    }
    .plan_text2 {
      font-family: var(--font_family_Semibold);
      margin: 8px 0px;
      font-weight: 400;
    }
    .plan_text3 {
      text-align: center;
      color: var(--text_color_1);
      font-size: 14px;
      padding: 0px 8px;
      line-height: 1.8;
      letter-spacing: 0.00838em;
      font-family: var(--font_family_Medium);
      span {
        font-weight: var(--font_weight_2);
      }
    }
  }
  .selected_plan_card {
    border: 1px solid var(--primary_color);
  }
}