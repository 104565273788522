.privacy_main_container {
  margin: 0px auto 0px auto;
  padding: 50px 0;
  width: 80%;
  .privacy_content {
    color: var(--text_color);
    p {
      margin: 20px 0px 0px 0px;
    }
    a {
      text-decoration: none;
      color: var(--dusk);
      font-family: var(--font_family_Bold);
      font-weight: bold;
    }
    .privacy_title {
      font-size: 28px !important;
      line-height: 1.43;
      text-align: center;
    }
    .privacy_line {
      width: 130px;
      height: 5px;
      border-radius: 2.5px;
      background-color: var(--primary_color);
      margin: 10px auto 20px auto;
    }
    .privacy_text {
      font-family: var(--font_family_Medium);
      font-size: 14px;
      font-weight: normal;
      line-height: 2.14;
      letter-spacing: normal;
      margin: 20px 0px 0px 0px;
    }
    a {
      text-decoration: none;
      color: var(--primary_color);
      font-weight: bold;
    }
    .privacy_top_para {
      margin-top: 50px;
    }
    .privacy_heading {
      font-size: 16px !important;
      line-height: normal;
      letter-spacing: normal;
      margin-top: 30px;
      text-transform: uppercase;
    }
    .privacy_subheading {
      font-size: 16px !important;
      line-height: 1.88;
      letter-spacing: normal;
      margin-top: 18px;
    }
  }

  @media (max-width: 600px) {
    width: calc(100% - 40px);
  }
  @media (max-width: 1024px) {
    // margin-top: 71px;
  }
}

.wrapper {
  overflow-y: auto;
  height: 100vh;
}
.wrapper::-webkit-scrollbar {
  display: unset;
}
.wrapper::-webkit-scrollbar {
  width: 12px;
}

.wrapper::-webkit-scrollbar-track {
  background: var(--light_primary_color);
  border-radius: 10px;
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  min-height: 140px;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
