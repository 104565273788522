.compare_result {
  padding-top: 100px;
}
.page_title {
  color: var(--text_color);
  text-align: center;
}
.page_line {
  margin: 20px auto 25px;
  width: 80px;
  height: 5px;
  border-radius: 2.5px;
  background-color: var(--divider_color);
}
.sub_title {
  font-size: 16px !important;
  font-family: var(--font_family_Regular) !important;
  color: var(--text_color) !important;
}
.separator {
  width: 60px;
  height: 5px;
  border-radius: 2.5px;
  margin: 20px auto;
}
.btn {
  width: 312px;
  margin: 0 auto;
}
.font_weight {
  font-weight: 400 !important;
}

.back_button {
  font-family: var(--font_family_Semibold);
  font-size: 20px;
  font-weight: var(--font_weight_2);
  line-height: 1.95;
  color: var(--text_color);
  width: fit-content;
  margin-top: 20px;
  cursor: pointer;
  .arrow_icon {
    width: 18px;
    height: 18px;
    margin: 0 5px -2px 0;
  }
}
