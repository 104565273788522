.all_plan_cards {
  border-radius: 6px;
  box-shadow: 0 6px 30px 0 rgba(189, 189, 189, 0.25);
  background-color: var(--white);
  padding-top: 50px;
  margin-top: 30px;
  width: 94%;
  .home_page_title {
    color: var(--text_color);
    text-align: center;
    font-size: 30px !important;
  }
  .card_items {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 20px 0px 40px 0px;
    height: fit-content;
    padding: 20px 10px;
    @media (max-width: 600px) {
      flex-direction: column;
      padding: 0px;
    }
    .first_wrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      @media (max-width: 1024px) {
        width: 50%;
      }
      @media (max-width: 600px) {
        width: 100%;
        margin-left: 0px;
      }
      .cost_data {
        padding: 10px 0 0;
        .card_cost {
          font-family: var(--font_family);
          font-size: 26px;
          font-weight: var(--font_weight_2);
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          text-align: center;
          color: var(--primary_background);
        }

        .card_cost1 {
          margin: 11px 0 7px 4px;
          font-family: var(--font_family_Semibold);
          font-size: 13px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: var(--primary_background);
          font-weight: var(--font_weight_1);
        }
        span {
          font-family: var(--font_family_Semibold);
          font-size: 12px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          font-weight: var(--font_weight_2);
          color: var(--black);
          s {
            padding-left: 5px;
            color: var(--grey_shade);
            font-size: 18px;
            color: var(--black);
          }
        }
        .text_space {
          margin-left: 10px !important;
        }
      }
      .second_cost_data {
        margin-bottom: 54px;
        padding: 5px 0;
        .card_cost {
          font-family: var(--font_family);
          font-size: 26px;
          font-weight: var(--font_weight_2);
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: center;
          color: var(--text_color);
        }
        .card_cost1 {
          margin: 11px 0 7px 4px;
          font-family: var(--font_family_Semibold);
          font-size: 13px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: var(--text_color);
          font-weight: var(--font_weight_2);
        }
        .span_color,
        .span_color s {
          font-size: 10px !important;
          font-family: var(--font_family_Semibold) !important;
          color: var(--text_color) !important;
          @media (max-width: 600px) {
            font-size: 16px !important;
            font-weight: var(--font_weight_2);
            font-stretch: normal;
            font-style: normal;
            line-height: 2.25;
            letter-spacing: normal;
            color: var(--text_color) !important;
          }
        }
        .span_color {
          @media (max-width: 600px) {
            display: flex;
            margin-top: 10px;
          }
        }
        .text_space {
          margin-left: 10px !important;
          @media (max-width: 600px) {
            margin-left: 0px !important;
          }
        }
        .card_cost2 {
          width: 400px;
          margin: 11px 0 7px 4px;
          font-family: var(--font_family_Semibold);
          font-size: 12px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.79;
          letter-spacing: normal;
          text-align: left;
          color: var(--text_color);
          font-weight: var(--font_weight_1);
          @media (max-width: 600px) {
            width: 100%;
          }
        }
        span {
          font-family: var(--font_family_Semibold);
          font-size: 12px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          font-weight: var(--font_weight_2);
          color: var(--black) s {
            padding-left: 5px;
            color: var(--grey_shade);
            font-size: 18px;
            color: var(--black);
          }
        }
      }
    }
    .GB_wrap {
      display: flex;
      flex-direction: column;
      width: 233px;
      @media (max-width: 1024px) {
        margin-left: 50px;
        width: 47%;
        margin-bottom: 54px;
      }
      @media (max-width: 600px) {
        margin-left: 0px;
        width: 100%;
      }
      .card_data {
        margin: 5px 0;
        text-align: left;
        @media (max-width: 600px) {
          display: flex;
          flex-direction: column;
        }
        .card_GB {
          font-family: var(--font_family);
          font-size: 36px;
          font-weight: var(--font_weight_2);
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          color: var(--primary_background);
          margin-right: 5px;
        }
        .card_text {
          font-family: var(--font_family_Semibold);
          font-weight: var(--font_weight_2);
          color: var(--text_color);
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-top: 15px;
          align-items: center;
          @media (max-width: 1024px) {
            justify-content: flex-start;
          }
          img {
            width: 22px;
            height: 18px;
            margin-right: 4px;
          }
        }
      }
      .no_contract {
        padding: 11px 0;
        display: flex;
        align-items: center;
        img {
          width: 25px;
          height: 26px;
          margin-right: 4px;
        }
        .no_contract_text {
          font-family: var(--font_family);
          font-weight: var(--font_weight_4);
          color: var(--text_color);
          font-size: 15px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 4px;
        }
      }
    }
    .wrap_line {
      width: 4px;
      height: 175px;
      opacity: 0.2;
      border: solid 1px #979797;
      @media (max-width: 1024px) {
        display: none;
      }
    }
    .features_div {
      display: flex;
      flex-direction: column;
      @media (max-width: 1024px) {
        width: 100%;
        margin-top: 10px;
        margin-left: 50px;
      }
      @media (max-width: 600px) {
        margin-left: 0px;
        width: 100%;
      }
      img {
        width: 16px;
        height: 12px;
        margin-right: 10px;
      }
      .feature_title {
        font-family: var(--font_family);
        font-weight: var(--font_weight_2);
        color: var(--text_color);
        font-size: 22px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.64;
        letter-spacing: normal;
        text-align: left;
        margin-bottom: 5px;
      }
      span {
        font-family: var(--font_family_Semibold);
        font-size: 15px;
        font-weight: var(--font_weight_2);
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: var(--text_color);
      }
      .features_wrap {
        display: flex;
        margin-top: 10px;
        @media (max-width: 1024px) {
        }
        @media (max-width: 600px) {
          display: unset;
        }

        .features_inner_wrap {
          display: flex;
          flex-direction: column;
          margin-right: 15px;
        }
      }
    }
  }
  .buttons_div {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
  }
  .terms_offer {
    display: flex;
    justify-content: flex-end;
    padding: 0px 25px 30px 0px;
    color: var(--primary_brand_color);
  }
}
