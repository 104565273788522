.current_line {
    width: 80px;
    height: 5px;
    border-radius: 2.5px;
    background-color: var(--primary_brand_color);
    margin:"20px 0px 0px"
}
.title{
    font-family: var(--font_family);
    font-size: 26px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--text_color);
}
.details{
    font-family: var(--font_family_Medium);
    font-weight: 500;
    font-weight: var(--font_weight_1);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--text_color);
    margin: 20px 0 25px;
    font-size: 20px;
    line-height: 1.5;
}