.home_page_plan_card {
    min-width: 250px;
    width: 250px;
    margin: 0 10px 20px 13px;
    border-radius: 8px;
    background-color: var(--white);
    position: relative;
    padding: 15px;
    box-shadow: 0px 4px 27px 0px rgba(0, 0, 0, 0.14);

    .tag {
      position: absolute;
      top: 0;
      left: -3px;
      padding: 5px;
      border-radius: 5px 4px 4px 0;
      margin-left: 3px;
      font-size: 12px;
      font-weight: var(--font_weight_2);
      margin-bottom: 10px;
    }
  
    .title {
      font-family: var(--font_family_Medium);
      font-size: 22px;
      font-weight: var(--font_weight_4) !important;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.59;
      letter-spacing: normal;
      text-align: start;
      color: var(--text_color);
      text-align: center;
      width: 163px;
      margin: auto;
      word-wrap: break-word;
    }
  
    .separator {
      width: 60px;
      height: 5px;
      border-radius: 2.5px;
      margin: 12px auto;
      background-color: var(--divider_color) !important;
    }
  
    .save_money_area {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      font-family: var(--font_family_Semibold);
      font-size: 12px;
      color: var(--text_color);
  
      .save_money_img {
        height: 15px;
        padding: 0 4px 4px 0;
      }
    }
  
    .plan_cost_data {
      text-align: center;
  
      .cost {
        // margin: 0px;
        font-family: var(--font_family_Bold);
        font-size: 30px;
        font-weight: var(--font_weight_4);
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: var(--light_black_1);
      }
  
      .cost1 {
        margin: 11px 0 7px 0px;
        font-family: var(--font_family_Semibold);
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: var(--light_black_2);
        font-weight: var(--font_weight_4);
      }
  
      &:hover {
        box-shadow: none;
      }
    }
  
    .plan_page_offer {
      margin-left: 10px;
      font-family: var(--font_family_Medium);
      font-size: 16px;
      font-weight: var(--font_weight_1);
      color: var(--text_color);
      font-stretch: normal;
      font-style: normal;
      line-height: 21px;
      letter-spacing: normal;
    }
  
    @media (max-width: 1366px) {
      min-width: 280px;
      width: 285px;
    }
  
    @media (max-width: 1024px) {
      margin: 0 10px 20px 10px;
      min-width: 325px;
    }
  
    @media (max-width: 600px) {
      margin: 0 0px 30px 0;
      min-width: 300px;
    }
  }
  
  .apply_border123 {
    border: 2px solid var(--primary_color);
  }
  
  .cards_div {
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    margin-top: 50px;
  }
  
  .plans_div {
    width: 96% !important;
  
    .plan_page_plan_card {
      min-width: 250px !important;
      // padding: 20px 10px;
      width: 18% !important;
      border: 2px solid transparent;
    box-shadow: 5px 5px 5px 5px rgba(126, 125, 125, 0.07) !important;
    }
  
    .plan_page_plan_card:hover {
      border: 2px solid var(--primary_color);
      box-shadow: 5px 5px 5px 5px rgba(126, 125, 125, 0.07) !important;
    }
  }
  
  .fade_out {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
  
  .current_plan {
    border: 2px solid var(--primary_color);
  }