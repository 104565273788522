.loader_checkOut {
  top: 50%;
  opacity: 0.8;
  background-color: var(--white);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font_family_Semibold);
  font-weight: var(--font_weight_2);
  font-size: 20px;
}
.addressWrapper {
  border-radius: 10px !important;
  @media (max-width: 1400px) and (min-width: 900px) {
    width: 100% !important;
  }
}