.main_container {
        display: flex;
        flex-direction: column;
        gap: 40px;
        justify-content: space-between;

        .back_arrow {
                position: absolute;
                left: 90px;
                display: flex;
                align-items: center;
                cursor: pointer;
                gap:8px;
                font-family: var(--font_family_Medium) !important;
                font-weight: var(--font_weight_3);
        }

        .heading {
                font-size: 30px;
                font-family: var(--font_family_Medium) !important;
                font-weight: var(--font_weight_5);
        }

        .full_name {
                color: var(--primary_brand_color_dark);
                        border: 1px solid var(--primary_brand_color_dark);
                        padding: 1px 7px;
                        font-size: 20px;
                        border-radius: 2px;
                        background: var(--toggle_button);
                        font-family: var(--font_family_Medium) !important;
                        font-weight: var(--font_weight_5);
        }
}
