.pageTitle {
  font-size: 40px;
  font-family: var(--font_family_Bold);
  color: var(--text_color);
  text-align: center;
  @media (max-width: 600px) {
    font-size: 24px;
  }
}
.subtitle {
  margin: 30px auto 30px auto;
  font-size: 18px;
  font-family: var(--font_family_Bold);
  text-align: center;
  font-weight: var(--font_weight_4);
  color: var(--text_color);
  line-height: 30px;
  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 28px;
  }
}

.backCTA {
  display: flex;
  align-items: center;
  position: absolute;
  left: 2rem;
  top: 15rem;
  cursor: pointer;
  @media (max-width: 600px) {
    top: 27px;
    left: -6px;
  }
}

.selectShipDetails {
  .taxesAndFees {
    display: flex;
    justify-content: space-between;
    padding: 0 15px 15px;
    font-size: 14px;
  }
  .totalAmount {
    position: absolute;
    right: 15px;
    top: 20px;
    font-family: var(--font_family_Bold);
  }
  :global(.MuiSelect-select) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
    font-weight: var(--font_weight_0);
    color: var(--text_color);
    background: var(--checkout_background);
  }
  :global(.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline) {
    border: 1px solid #bababa !important;
  }
}

.shipping_text {
  :global(.MuiCheckbox-root) {
    color: var(--black);
    opacity: 0.54;
  }

  :global(.MuiCheckbox-colorPrimary.Mui-checked) {
    color: var(--primary_color);
    opacity: 1;
  }
}

.shipping_text {
  font-size: 14px;
  font-family: var(--font_family_Medium);
  font-weight: var(--font_weight_0);
  color: var(--text_color_4);
  line-height: 21px;
  color: var(--text_color);
}

.change_add {
  margin: 30px auto;
  border-radius: 10px;
  border: 1px solid var(--navy);
  background: var(--white);
  @media (max-width: 900px) {
    width: 70%;
  }
  @media (max-width: 600px) {
    width: 100%;
    margin: 20px auto;
  }
  .address_text {
    width: 100%;
    @media (max-width: 600px) {
      font-size: 14px;
      font-weight: var(--font_weight_2);
      line-height: 28px;
    }
  }
}

.change_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px auto;
  padding: 20px 10px;
  border-radius: 6px;
  border: 1px solid var(--navy);
  background: var(--white);
  @media (max-width: 900px) {
    width: 70%;
  }
  @media (max-width: 600px) {
    width: 90%;
  }
}
.boxContainer {
  border-radius: 10px;
  // background: var(--white);
  // box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  // width: 35%;
  margin: 0px auto;
  padding: 30px;
  @media (max-width: 600px) {
    width: 90%;
    padding: 25px 0px;
  }
}
.selectCust {
  border-radius: 3px;
  // border: 1px solid var(--navy) !important;
  background: var(--white);
}
.subtitle_main {
  font-size: 40px !important;
  font-weight: var(--font_weight_4);
  line-height: 50px;
}
.changeCTA {
  color: var(--primary_color);
  display: flex;
  justify-content: flex-end;
}
.reviewDetails {
  .reviewBoxContainer {
    border-radius: 8px;
    background: var(--white);
    width: 430px;
    height: auto;
    margin: 0 auto;
    padding: 30px 30px 10px 30px;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
    @media (max-width: 600px) {
      width: 350px;
    }
    .container {
      .oneTimeTxt {
        font-size: 24px;
        font-weight: var(--font_weight_4);
        font-family: var(--font_family_Bold);
        line-height: 30px;
      }
      .charges {
        margin-top: 30px;
        .charge {
          display: flex;
          justify-content: space-between;
          margin-bottom: 30px;
          font-size: 16px;
          font-weight: var(--font_weight_0);
          font-family: var(--font_family_Medium);
          line-height: 28px;
        }
      }
    }
  }
}
