$slider_bg_img: linear-gradient(
  to bottom,
  var(--slider_popper_bg_img1),
  var(--slider_popper_bg_img2)
);

.slider_cust {
  border-radius: 6px !important;

  .MuiSlider-track {
    background-color: var(--slider_bg_color) !important;
    opacity: 1 !important;
    border: none;
  }
  .MuiSlider-rail {
    background-color: var(--slider_bg_color) !important;
    opacity: 1;
  }

  .MuiSlider-thumb {
    width: 16px;
    height: 16px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
    border: solid 1.5px var(--slider_circle_border);
    background-color: var(--white);
  }
  .MuiSlider-valueLabel {
    width: 10px;
    height: 16px;
    border-radius: 6px !important;
    opacity: 0.8;
    background-image: $slider_bg_img;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.35);
  }
  .MuiSlider-valueLabel::before {
    background-image: $slider_bg_img;
  }

  .MuiSlider-mark {
    background-color: var(--slider_bg_color) !important;
    width: 0px;
  }
}
