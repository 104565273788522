.hometp {
    background-color: var(--primary_brand_color);
    .trsut_pilot_content{
        .trsut_pilot_content_title{
            color: var(--white);
            font-family: var(--font_family_Semibold);
            font-weight: var(--font_weight_0);
            font-size: 26px;
        }
        .trsut_pilot_line{
            width: 80px;
            height: 5px;
            border-radius: 2.5px;
            background-color: var(--white);
        }
    }
}