.content_wrapper {
  @media (min-width: 900px) and (max-width: 1024px) {
    flex-direction: column !important;
  }
  .discover_left {
    .discover_header {
      font-family: var(--font_family_Demibold);
      font-size: 30px;
      font-weight: var(--font_weight_2);
      line-height: 45px;
    }
    .discover_subtitle {
      font-family: var(--font_family_Medium);
      font-size: 18px;
      font-weight: var(--font_weight_2);
      line-height: 24px;
      color: var(--text_color_3);
      margin-bottom: 48px !important;
    }
    .step_content > * {
      font-family: var(--font_family_Medium);
      color: var(--text_color);
    }
    .step_description {
      font-size: 14px;
      font-weight: var(--font_weight_1);
      line-height: 24px;
      a {
        color: var(--primary_color);
      }
    }
    .step_title {
      font-size: 18px;
      line-height: 1.33;
      font-weight: var(--font_weight_2);
      margin-bottom: 0.5rem;
    }

    .step_icon {
      position: relative;
    }

    .step_icon_line {
      border-left: 1px solid rgba(131, 49, 49, 0.25);
      height: 100%;
      position: absolute;
      top: 38px;
      margin-left: 13px;
    }
  }
  .discover_right {
    @media (min-width: 900px) and (max-width: 1024px) {
      justify-content: center !important;
      margin-top: 50px;
    }
  }
}
