.modal_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .head {
    font-size: 22px;
    text-align: center;
    color: var(--text_color);
    margin-bottom: 16px;
    font-family: var(--font_family_Bold);
  }
  .border_bottom{
    width: 75px;
    height: 5px;
    background-color: var(--primary_brand_color);
    border-radius: 25px;
    margin: 0 auto;
  }
  .try_again {
    font-size: 14px;
    color: var(--primary_color);
    cursor: pointer;
    font-family: var(--font_family_Bold);
    padding: 0px 0px 15px;
  }
  .sub_head{
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 5px;
    padding: 0 10px;
    color: var(--text_color);
    font-family: var(--font_family_Medium);
}
}
