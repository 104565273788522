@mixin common_txt1 {
  font-family: var(--font_family_Semibold);
  color: var(--text_color_3);
}

.activation_paynow_card {
  border-radius: 10px;
  // box-shadow: 0 6px 30px 0 rgb(0 0 0 / 6%);
  border: 1px solid var(--white_1);
  background-color: var(--white);
  .main_container {
    font-size: 16px;
    .heading {
      color: var(--title-color_1);
      font-family: var(--font_family_Bold);
      font-size: 24px;
      // font-weight: var(--font_weight_2);
    }
    .sub_title {
      font-size: 16px;
      // font-weight: var(--font_weight_2);
      line-height: 1;
      text-align: center;
      color: var(--text_color);
      font-family: var(--font_family_Regular) !important;
      padding-top: 10px;
      // margin-bottom: 20px;
    }
    .row {
      font-weight: var(--font_weight_2);
      background: var(--lightgrey_6);
      .row_text {
        @include common_txt1;
      }
      .row_value {
        font-family: var(--font_family_Semibold);
        color: var(--text_color_6);
      }
    }
    .promo_coupon {
      border: 1px dashed var(--text_color);
      border-radius: 6px;
      padding: 10px 15px;
      color: var(--primary_brand_color);
      font-family: var(--font_family_Semibold);
      font-size: 14px;
      font-weight: var(--font_weight_2);
    }
    .text1 {
      font-family: var(--font_family_Regular);
      color: var(--text_color);
      padding: 20px;
      @media (max-width: 600px) {
        font-size: 12px;
      }
    }
    .image_text_container {
      @media (max-width: 600px) {
        margin: 0 6px 0 6px;
      }
      .text2 {
        font-family: var(--font_family_Regular);
        font-size: 12px;
        // color: var(--text_color_3);
      }
      .text3 {
        font-family: var(--font_family_Bold);
        font-size: 14px;
        font-weight: var(--font_weight_4);
        color: var(--primary_color);
        cursor: pointer;
      }
    }
  }
  .loaderCheck {
    display: flex;
    display: -webkit-box;
    :global(
        .MuiCircularProgress-root.MuiCircularProgress-colorPrimary.MuiCircularProgress-indeterminate
      ) {
      height: 30px !important;
      width: 30px !important;
    }
    svg {
      color: var(--primary_color);
    }
  }
  .note_txt {
    @include common_txt1;
    color: var(--primary_brand_color);
    text-align: left;
  }
  .note_pt_txt {
    @include common_txt1;
    text-align: left;
  }
}
