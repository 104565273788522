.service_main {
  border-radius: 18px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  font-family: var(--font_family_Medium) !important;
  text-align: center;
  font-weight: var(--font_weight_1);
  padding: 20px 10px 30px 10px;
  width: 363px;
  height: 183px;
  background-color: var(--white);
  .service_header_text {
    font-family: var(--font_family_Bold) !important;
    font-size: 18px;
    font-weight: var(--font_weight_4);
    color: var(--text_color);
    margin-bottom: 30px;
    .service_Desc_text {
      font-family: var(--font_family_Semibold) !important;
      font-weight: var(--font_weight_1);
      font-size: 14px;
    }
  }
}
