.aboutus_partners_block {
    display: flex;
    padding: 2rem;
    .image_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: inherit;
        @media (max-width: 600px) {
            width: 100%;
        }
        .MuiGrid-item:nth-child(3n) {
            justify-content: flex-start;
            padding-left: 1rem;
        }
        .MuiGrid-item:nth-child(3n+2) {
            justify-content: flex-end;
            padding-right: 1rem;
        }
    }
    @media (min-width: 600px) {
        .brand_imnage_la {
            justify-content: flex-start;
            padding-left: 1rem;
        }
        .brand_imnage_ra {
            justify-content: flex-end;
            padding-right: 1rem;
        }
    }
}