.lead_generation {
  background: var(--lead_generation_bg_color);
  .lead_generation_title {
    color: var(--text_color_3);
    font-family: var(--font_family_Medium);
    font-weight: var(--font_weight_1);
    line-height: 1.67;
    text-align: center;
  }
  .lead_generation_subtitle {
    color: var(--subheader-color_1);
    font-family: var(--font_family_Medium);
    font-weight: var(--font_weight_1);
    line-height: 1.67;
    margin: 20px 32px 29px 33px;
    text-align: center;
  }
  .lead_generation_line {
    width: 80px;
    height: 5px;
    border-radius: 2.5px;
    background-color: var(--divider_color);
    margin-top: 10px;
  }
  .email_success {
    .email_success_text {
      font-family: var(--font_family_Semibold);
      font-weight: var(--font_weight_2);
    }
  }
  :global(.MuiFormHelperText-root){
    margin-left: 14px;
  }
  .keep_in_touch_text {
    font-weight: var(--font_weight_2);
    font-family: var(--font_family_Semibold);
  }
  .lead_generate_t1{
    font-weight: var(--font_weight_2);
    font-family: var(--font_family_Semibold) !important;
    min-width:100px !important;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px
 
    }
}
