.arrow_icon {
  cursor: pointer;
  width: 18px;
  height: 18px;
  z-index: 100;
  left: 0;
  position: absolute;
}

@mixin card_item_styles {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white_1);
  border-radius: 4px;
  padding: 20px;
}

@mixin card_heading_styles($marginBtm) {
  font-weight: var(--font_weight_2);
  font-family: var(--font_family_Medium);
  margin-bottom: $marginBtm;
  font-size: 14px;
}

@mixin card_sub_heading_styles {
  font-family: var(--font_family_Medium);
  font-weight: var(--font_weight_1);
  font-size: 14px;
  text-align: center;
}

@mixin credit_amount_styles {
  font-family: var(--font_family_Bold);
  font-weight: var(--font_weight_4);
  font-size: 26px;
  margin-top: 15px;
  margin-bottom: 25px;
}

@mixin view_more_styles {
  font-family: var(--font_family_Bold);
  font-weight: var(--font_weight_4);
  font-size: 14px;
  margin-top: 20px;
  display: flex;
  color: var(--dusk);
  text-align: center;
  align-items: center;
  cursor: pointer;
}

.cards_container {
  color: var(--black);

  .first_row {
    display: flex;
    justify-content: center;

    .card_item {
      @include card_item_styles();
      justify-content: center;

      .card_heading {
        @include card_heading_styles(5px);
      }

      .card_sub_heading {
        @include card_sub_heading_styles();
      }

      .credit_amount {
        @include credit_amount_styles();
      }
    }
  }

  .second_row {
    display: flex;
    justify-content: center;

    .card_item {
      @include card_item_styles();
      justify-content: flex-start;

      .card_heading {
        @include card_heading_styles(25px);
      }

      .activated_referrals_list_item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 5px;
        padding: 0 10px;

        .avatar {
          height: 30px;
          width: 30px;
          background-color: #619da1;
        }

        .user_name {
          text-align: left;
          width: 220px;
          font-family: var(--font_family_Medium);
          font-size: 14px;
          color: var(--text_color);
        }

        .referral_date {
          font-size: 14px;
          font-family: var(--font_family_Medium);
          color: var(--grey_shade);
          text-align: center;

          span {
            font-weight: var(--font_weight_2);
            color: var(--black);
            margin-left: 10px;
          }
        }
      }

      .view_more {
        @include view_more_styles();
      }
    }
  }
}

.card_heading {
  justify-content: center;
  @include card_heading_styles(5px);
}

.activated_referrals_list_item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  .avatar {
    height: 30px;
    width: 30px;
    background-color: #619da1;
  }

  .user_name {
    text-align: left;
    width: 220px;
    font-family: var(--font_family_Medium);
    font-size: 14px;
    color: var(--text_color);
  }

  .referral_date {
    font-size: 14px;
    font-family: var(--font_family_Medium);
    color: var(--grey_shade);
    text-align: center;
  }
  .activated_referral_amount {
    font-weight: var(--font_weight_2);
    font-family: var(--font_family_Bold);
    color: var(--black);
    margin-left: 10px;
    text-align: center;
  }
}

.view_more {
  @include view_more_styles();
}

.pending_credit_list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;

  .reminder_link {
    text-decoration: none;
    cursor: pointer;
    color: var(--primary_color);
    font-weight: var(--font_weight_2);
    font-size: 14px;
  }
}
